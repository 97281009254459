import { useTranslations } from '@vocab/react';
import { Heading } from 'braid-design-system';

import translations from 'src/views/pages/RegistrationPage/.vocab';
import { useRegistrationPageContext } from 'src/views/pages/RegistrationPage/RegistrationPageContext';

const RegistrationHeader = () => {
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  if (context.loading) {
    return;
  }

  const { numberOfAccounts } = context;

  const hasAnotherAccount = numberOfAccounts > 0;

  return (
    <Heading level="2">
      {hasAnotherAccount
        ? t('Create new employer account')
        : t('Your employer account')}
    </Heading>
  );
};

export default RegistrationHeader;

import { PageBlock, Stack } from 'braid-design-system';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

import type { BasePageLayoutProps } from 'src/types';
import SeoMeta from 'src/views/shared/SeoMeta/SeoMeta';

interface Props extends BasePageLayoutProps {
  bannerComponent?: ReactNode;
  heading: ReactNode;
  width?: ComponentPropsWithoutRef<typeof PageBlock>['width'];
}

export const PageLayout = ({
  bannerComponent,
  children,
  heading,
  seoMeta,
  width = 'medium',
}: Props) => (
  <Stack space="xlarge">
    <SeoMeta
      title={seoMeta.title}
      description={seoMeta.description ?? ''}
      url={seoMeta.url}
      additionalMeta={seoMeta.addtionalMeta}
    />
    {bannerComponent ? (
      <PageBlock width={width}>{bannerComponent}</PageBlock>
    ) : null}
    <PageBlock width={width}>{heading}</PageBlock>
    {children}
  </Stack>
);

import {
  URL_ACCOUNT_HIRER_REGISTRATION,
  URL_ACCOUNT_SELECT,
} from '@seek/adv-constants';
import { useTrackLink } from '@seek/cmsu-analytics';
import { getLanguageFromLocale } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonLink,
  Column,
  Columns,
  Heading,
  Stack,
  PageBlock,
  useToast,
} from 'braid-design-system';
import { type UIEvent, useState } from 'react';

import { analyticsEvents } from 'src/constants/analytics';
import {
  RESEND_ACTIVATION_EMAIL_PRESSED,
  SELECT_ACCOUNT_PAGE_VIEW,
} from 'src/constants/pageViewUniqueKeys';
import {
  AccessRightStatus,
  type AccountSelectionEdge,
  AdvertiserStatus,
  useGetAccountSelectionQuery,
  useResendActivationEmailMutation,
  Role,
  type accountPartsFragment,
} from 'src/graphql/generated';
import { useConfig } from 'src/hooks/context';
import { useAuthenticatedViewTracking } from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import getRedirectUrl from 'src/modules/ApiClient/hirerGraph/queries/gql_getRedirectUrl';
import { accountEdgeSort } from 'src/utils/accountSorter';
import { getQueryParams } from 'src/utils/queryParams';
import { PageLayout } from 'src/views/layouts/PageLayout/PageLayout';

import translations from './.vocab';
import AccountSegment from './components/AccountSegment/AccountSegment';
import AccountDataErrorBanner from './components/AccountSegment/components/AccountDataErrorBanner/AccountDataErrorBanner';
import AccountPendingBanner from './components/AccountSegment/components/AccountPendingBanner/AccountPendingBanner';

const stripOrigin = (url: string) => {
  if (url?.indexOf(window.location.origin) === 0) {
    return url.substring(window.location.origin.length);
  }
  return url;
};

export interface PendingAccountEmailStatus {
  success?: boolean;
}
export type PendingAccountEmailStatuses = Record<
  string,
  PendingAccountEmailStatus
>;

const SelectAccountPage = () => {
  const [pendingAccountEmailStatuses, setPendingAccountEmailStatuses] =
    useState<PendingAccountEmailStatuses>({});
  const { absoluteEmployerUrlResolver, urlResolver, locale } = useConfig();
  const [userInfo] = useAuthenticatedViewTracking({
    eventName: SELECT_ACCOUNT_PAGE_VIEW,
  });
  const { t } = useTranslations(translations);
  const fullUrl = absoluteEmployerUrlResolver(URL_ACCOUNT_SELECT);
  const showToast = useToast();

  const trackResendAccountActivationEmail = useTrackLink(
    RESEND_ACTIVATION_EMAIL_PRESSED,
    {
      siteSection: 'pending activation',
      entryPoint: 'account selection - pending activation',
      ...userInfo,
    },
  );

  const onClickLink = useTrackLink(SELECT_ACCOUNT_PAGE_VIEW, {
    eventName: analyticsEvents.TRACK_CREATE_NEW_ACCOUNT,
    entryPoint: 'account selection - create new account',
    ...userInfo,
  });

  const {
    loading,
    error: accountSelectionQueryError,
    data,
  } = useGetAccountSelectionQuery();

  const [resendActivationEmailMutation] = useResendActivationEmailMutation();

  if (
    accountSelectionQueryError ||
    data?.accountSelectionV2.__typename === 'ResponseError'
  ) {
    return (
      <Box paddingTop="xlarge">
        <PageBlock width="medium">
          <AccountDataErrorBanner />
        </PageBlock>
      </Box>
    );
  }

  if (loading || !data) {
    return null;
  }

  const unsortedActiveAccounts = data.accountSelectionV2.edges.filter(
    ({ node }) =>
      node.hirerAccountStatus === AdvertiserStatus.ACTIVE &&
      node.accessRightStatus === AccessRightStatus.ACTIVE,
  );

  const activeAccounts = accountEdgeSort(unsortedActiveAccounts);

  const unsortedPendingAccounts = data.accountSelectionV2.edges.filter(
    ({ node }) =>
      node.hirerAccountStatus === AdvertiserStatus.PENDING_ACTIVATION &&
      node.role === Role.ADMINISTRATOR,
  ) as AccountSelectionEdge[];
  const defaultAccount = data.accountSelectionV2.defaultAccount;

  const pendingAccounts = accountEdgeSort(unsortedPendingAccounts);

  const getReturnUri = () => {
    const { returnUrl: returnUri } = getQueryParams();
    const language = getLanguageFromLocale(locale);

    switch (language) {
      case 'en':
        return returnUri;
      default:
        if (returnUri) {
          return returnUri.match(/(th|id)/)
            ? returnUri
            : `/${language}${returnUri}`;
        }

        return `/${language}`;
    }
  };

  if (defaultAccount) {
    const redirectToDefaultAccount = async () => {
      const hirerAccountId = defaultAccount.seekId;
      const redirectUrl = await getRedirectUrl({
        returnUri: getReturnUri(),
        hirerAccountId,
        language: locale,
      });
      window.location.assign(stripOrigin(redirectUrl));
    };
    redirectToDefaultAccount();
  }

  if (data.accountSelectionV2.totalCount === 0) {
    window.location.assign(
      urlResolver({ path: URL_ACCOUNT_HIRER_REGISTRATION }),
    );
  }

  const hasPendingAccounts = pendingAccounts.length > 0;
  const hasActiveAccounts = activeAccounts.length > 0;
  // render nothing until data has been fetched and number of accounts is not 1.
  // otherwise we will redirect the user directly to dashboard.
  // This logic aims to avoid content flickers.
  const renderContent =
    !defaultAccount && (hasPendingAccounts || hasActiveAccounts);

  if (!renderContent) {
    return null;
  }

  const handleSelectAccount = async (
    event: UIEvent,
    account: accountPartsFragment,
  ) => {
    event.preventDefault();

    const seekId = account.seekId;

    try {
      const redirectUrl = await getRedirectUrl({
        returnUri: getReturnUri(),
        hirerAccountId: seekId,
        language: locale,
      });
      if (!redirectUrl) {
        throw new Error('Redirect url is undefined');
      }
      window.location.assign(stripOrigin(redirectUrl));
    } catch (error) {
      throw error;
    }
  };

  const handleActivateAccount = async (
    event: UIEvent,
    account: accountPartsFragment,
  ) => {
    event.preventDefault();

    const hirerAccountId = account.seekId;

    try {
      await resendActivationEmailMutation({
        variables: {
          input: {
            hirerAccountId,
          },
        },
      });

      setPendingAccountEmailStatuses({
        ...pendingAccountEmailStatuses,
        [hirerAccountId]: {
          success: true,
        },
      });

      showToast({
        key: hirerAccountId,
        tone: 'positive',
        message: t('Activate account email sent'),
      });

      if (trackResendAccountActivationEmail) {
        trackResendAccountActivationEmail();
      }
    } catch {
      setPendingAccountEmailStatuses({
        ...pendingAccountEmailStatuses,
        [hirerAccountId]: {
          success: false,
        },
      });

      showToast({
        key: hirerAccountId,
        tone: 'critical',
        message: t('Could not send email'),
      });
    }
  };

  return (
    <PageLayout
      seoMeta={{
        title: t('Browser tab title'),
        description: '',
        url: fullUrl,
      }}
      bannerComponent={
        <AccountPendingBanner
          pendingAccountsLength={pendingAccounts?.length || 0}
          userInfo={userInfo}
        />
      }
      heading={
        <Columns space="medium" collapseBelow="tablet" alignY="center">
          <Column>
            <Heading level="2">{t('Select an account')}</Heading>
          </Column>
          <Column width="content">
            <Box display="inlineBlock">
              <ButtonLink
                href={urlResolver({ path: URL_ACCOUNT_HIRER_REGISTRATION })}
                onClick={() => (onClickLink ? onClickLink() : undefined)}
                tone="formAccent"
              >
                {t('Create new account')}
              </ButtonLink>
            </Box>
          </Column>
        </Columns>
      }
    >
      <Stack space="xlarge">
        {hasActiveAccounts && (
          <AccountSegment
            heading={t('Active accounts')}
            subheading={t('Select an account to post')}
            accounts={activeAccounts}
            handleSelectAccount={handleSelectAccount}
          />
        )}
        {hasPendingAccounts && (
          <Box id="pending-accounts">
            <AccountSegment
              heading={t('Pending accounts')}
              subheading={t('Activate these accounts')}
              accounts={pendingAccounts}
              handleActivateAccount={handleActivateAccount}
              pendingAccountEmailStatuses={pendingAccountEmailStatuses}
            />
          </Box>
        )}
      </Stack>
    </PageLayout>
  );
};

export default SelectAccountPage;


      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguYnguK3guIfguIHguLLguKPguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0/IOC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC5hOC4lOC5ieC4l+C4teC5iCIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyDguJXguLTguJTguJXguYjguK08TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+4LiC4Lit4LiH4LmA4Lij4LiyIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguYnguK3guIfguIHguLLguKPguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0/IOC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC5hOC4lOC5ieC4l+C4teC5iCIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyDguJXguLTguJTguJXguYjguK08TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+4LiC4Lit4LiH4LmA4Lij4LiyIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguYnguK3guIfguIHguLLguKPguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0/IOC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC5hOC4lOC5ieC4l+C4teC5iCIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyDguJXguLTguJTguJXguYjguK08TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+4LiC4Lit4LiH4LmA4Lij4LiyIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiLguJXguYnguK3guIfguIHguLLguKPguITguKfguLLguKHguIrguYjguKfguKLguYDguKvguKXguLfguK0/IOC4leC4tOC4lOC4leC5iOC4reC5geC4nOC4meC4geC4peC4ueC4geC4hOC5ieC4suC4quC4seC4oeC4nuC4seC4meC4mOC5jOC5hOC4lOC5ieC4l+C4teC5iCIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoi4LiV4LmJ4Lit4LiH4LiB4Liy4Lij4LiE4Lin4Liy4Lih4LiK4LmI4Lin4Lii4LmA4Lir4Lil4Li34LitPyDguJXguLTguJTguJXguYjguK08TGluaz7guYHguJzguJnguIHguKXguLnguIHguITguYnguLLguKrguLHguKHguJ7guLHguJnguJjguYw8L0xpbms+4LiC4Lit4LiH4LmA4Lij4LiyIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIHRpbSBsYXlhbmFuIHBlbGFuZ2dhbiBrYW1pIGRpIiwiQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJCdXR1aCBiYW50dWFuPyBIdWJ1bmdpIDxMaW5rPnRpbSBsYXlhbmFuIHBlbGFuZ2dhbjwvTGluaz4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJOZWVkIGhlbHA/IENvbnRhY3Qgb3VyIGN1c3RvbWVyIHNlcnZpY2UgdGVhbSBvbiIsIkNvbnRhY3QgY3VzdG9tZXIgc2VydmljZSBtZXNzYWdlIjoiTmVlZCBoZWxwPyBDb250YWN0IG91ciA8TGluaz5jdXN0b21lciBzZXJ2aWNlIHRlYW08L0xpbms+In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw5Hhur3hur3hur3hur3hur3hur3GjCDhuKnhur3hur3hur3GmsalPyDDh8O2w7bDtuC4geC4teC5ieG5r8SDxIPEg8On4bmvIMO2w7bDtseax5rHmsWZIMOnx5rHmseaxaHhua/DtsO2w7ZtzILhur3hur3hur3FmSDFoeG6veG6veG6vcWZ4bm9w6zDrMOsw6fhur3hur3hur0g4bmv4bq94bq94bq9xIPEg8SDbcyCIMO2w7bDtuC4geC4teC5iV0iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IlvDkeG6veG6veG6veG6veG6veG6vcaMIOG4qeG6veG6veG6vcaaxqU/IMOHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw7bDtsO2x5rHmseaxZkgPExpbms+w6fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWh4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhua/hur3hur3hur3Eg8SDxINtzII8L0xpbms+XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBTlogQ29udGFjdCBjdXN0b21lciBzZXJ2aWNlIG1lc3NhZ2UiOiJbw5Hhur3hur3hur3hur3hur3hur3GjCDhuKnhur3hur3hur3GmsalPyDDh8O2w7bDtuC4geC4teC5ieG5r8SDxIPEg8On4bmvIMO2w7bDtseax5rHmsWZIMOnx5rHmseaxaHhua/DtsO2w7ZtzILhur3hur3hur3FmSDFoeG6veG6veG6vcWZ4bm9w6zDrMOsw6fhur3hur3hur0g4bmv4bq94bq94bq9xIPEg8SDbcyCIMO2w7bDtuC4geC4teC5iV0iLCJDb250YWN0IGN1c3RvbWVyIHNlcnZpY2UgbWVzc2FnZSI6IlvDkeG6veG6veG6veG6veG6veG6vcaMIOG4qeG6veG6veG6vcaaxqU/IMOHw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw7bDtsO2x5rHmseaxZkgPExpbms+w6fHmseax5rFoeG5r8O2w7bDtm3MguG6veG6veG6vcWZIMWh4bq94bq94bq9xZnhub3DrMOsw6zDp+G6veG6veG6vSDhua/hur3hur3hur3Eg8SDxINtzII8L0xpbms+XSJ9!"
        )
      )
      });
  
      export { translations as default };
    
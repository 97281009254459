import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type { RedirectUrlQueryInput } from 'src/modules/ApiClient/types';

export const redirectUrlQuery = gql`
  query GetRedirectUrl($input: RedirectUrlQueryInput!) {
    redirectUrl(input: $input)
  }
`;

export type GetRedirectUrl = (input: RedirectUrlQueryInput) => Promise<string>;

const getRedirectUrl: GetRedirectUrl = async (
  input: RedirectUrlQueryInput,
): Promise<string> => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<{
    redirectUrl: string;
  }>({
    query: redirectUrlQuery,
    variables: { input },
  });

  return result.data.redirectUrl;
};

export default getRedirectUrl;

import {
  URL_ACCOUNT_ADDITIONAL_ADVERTISER_REGISTRATION_COMPLETE,
  URL_CREATE_NEW_JOB,
} from '@seek/adv-constants';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Text, Stack, ButtonLink, Actions } from 'braid-design-system';

import { META_TITLE_REGISTRATION_COMPLETE } from 'src/constants/pageMetaData';
import {
  ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK,
  ADDITIONAL_ADVERTISER_PAGE_VIEW,
} from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import useAuthenticatedTracking from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { ContactDetails } from 'src/views/shared/ContactDetails/ContactDetails';

import translations from './.vocab';

const AdditionalAdvertiserRegistrationCompletePage = () => {
  const { absoluteEmployerUrlResolver, urlResolver } = useConfig();
  const { t } = useTranslations(translations);

  const [userInfo] = useAuthenticatedTracking({
    eventName: ADDITIONAL_ADVERTISER_PAGE_VIEW,
  });

  const fullUrl = absoluteEmployerUrlResolver(
    URL_ACCOUNT_ADDITIONAL_ADVERTISER_REGISTRATION_COMPLETE,
  );

  const trackLinkFunction = useTrackLink(
    ADDITIONAL_ADVERTISER_PAGE_CLICK_CAJA_LINK,
    {
      advertiserCommitmentType: 'casual',
      advertiserContractBalance: '0',
      advertiserContractValue: '0',
      ...userInfo,
    },
  );

  return (
    <EmptyPageLayout
      seoMeta={{
        title: META_TITLE_REGISTRATION_COMPLETE,
        description: '',
        url: fullUrl,
      }}
      heading={t('Account been activated')}
    >
      <Stack space="large" align="center">
        <Text>{t('Start job ad message')} </Text>
        <Actions>
          <ButtonLink
            href={urlResolver({ path: URL_CREATE_NEW_JOB })}
            onClick={() =>
              trackLinkFunction ? trackLinkFunction() : undefined
            }
            tone="brandAccent"
          >
            {t('Post ad link')}
          </ButtonLink>
        </Actions>
        <ContactDetails />
      </Stack>
    </EmptyPageLayout>
  );
};

export default AdditionalAdvertiserRegistrationCompletePage;

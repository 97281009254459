
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJMhIOC4ouC4t+C4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC5gOC4o+C4teC4ouC4muC4o+C5ieC4reC4ouC5geC4peC5ieC4py4iLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4leC5iOC4rSDguJvguLTguJTguYHguJfguYfguJrguJnguLXguYnguYHguKXguLDguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK3guYPguJnguYHguJfguYfguJrguILguK3guIfguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguK3guLfguYjguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJMhIOC4ouC4t+C4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC5gOC4o+C4teC4ouC4muC4o+C5ieC4reC4ouC5geC4peC5ieC4py4iLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4leC5iOC4rSDguJvguLTguJTguYHguJfguYfguJrguJnguLXguYnguYHguKXguLDguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK3guYPguJnguYHguJfguYfguJrguILguK3guIfguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguK3guLfguYjguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWghIEVtYWlsIEFuZGEgdGVsYWggZGlrb25maXJtYXNpLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiTGFuanV0a2FuIG1lbmVyYml0a2FuIGlrbGFuIGxvd29uZ2FuIGtlcmphIEFuZGEgZGVuZ2FuIG1lbnV0dXAgdGFiIGluaSBkYW4gbWVsYW5qdXRrYW4gZGkgdGFiIGJyb3dzZXIgYmFydS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWghIEVtYWlsIEFuZGEgdGVsYWggZGlrb25maXJtYXNpLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiTGFuanV0a2FuIG1lbmVyYml0a2FuIGlrbGFuIGxvd29uZ2FuIGtlcmphIEFuZGEgZGVuZ2FuIG1lbnV0dXAgdGFiIGluaSBkYW4gbWVsYW5qdXRrYW4gZGkgdGFiIGJyb3dzZXIgYmFydS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJMhIOC4ouC4t+C4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC5gOC4o+C4teC4ouC4muC4o+C5ieC4reC4ouC5geC4peC5ieC4py4iLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4leC5iOC4rSDguJvguLTguJTguYHguJfguYfguJrguJnguLXguYnguYHguKXguLDguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK3guYPguJnguYHguJfguYfguJrguILguK3guIfguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguK3guLfguYjguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiLguILguK3guJrguITguLjguJMhIOC4ouC4t+C4meC4ouC4seC4meC4reC4teC5gOC4oeC4peC5gOC4o+C4teC4ouC4muC4o+C5ieC4reC4ouC5geC4peC5ieC4py4iLCJSZXN1bWUgcG9zdGluZyBqb2IgYWQgbWVzc2FnZSI6IuC5gOC4nuC4t+C5iOC4reC4peC4h+C4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4leC5iOC4rSDguJvguLTguJTguYHguJfguYfguJrguJnguLXguYnguYHguKXguLDguJTguLPguYDguJnguLTguJnguIHguLLguKPguJXguYjguK3guYPguJnguYHguJfguYfguJrguILguK3guIfguYDguJrguKPguLLguKfguYzguYDguIvguK3guKPguYzguK3guLfguYjguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWghIEVtYWlsIEFuZGEgdGVsYWggZGlrb25maXJtYXNpLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiTGFuanV0a2FuIG1lbmVyYml0a2FuIGlrbGFuIGxvd29uZ2FuIGtlcmphIEFuZGEgZGVuZ2FuIG1lbnV0dXAgdGFiIGluaSBkYW4gbWVsYW5qdXRrYW4gZGkgdGFiIGJyb3dzZXIgYmFydS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUZXJpbWEga2FzaWghIEVtYWlsIEFuZGEgdGVsYWggZGlrb25maXJtYXNpLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiTGFuanV0a2FuIG1lbmVyYml0a2FuIGlrbGFuIGxvd29uZ2FuIGtlcmphIEFuZGEgZGVuZ2FuIG1lbnV0dXAgdGFiIGluaSBkYW4gbWVsYW5qdXRrYW4gZGkgdGFiIGJyb3dzZXIgYmFydS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJUaGFua3MhIFlvdXIgZW1haWwgaXMgY29uZmlybWVkLiIsIlJlc3VtZSBwb3N0aW5nIGpvYiBhZCBtZXNzYWdlIjoiUmVzdW1lIHBvc3RpbmcgeW91ciBqb2IgYWQgYnkgY2xvc2luZyB0aGlzIHRhYiBhbmQgY29udGludWluZyBpbiB0aGUgb3RoZXIgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSEgw53DtsO2w7bHmseax5rFmSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDDrMOsw6zFoSDDp8O2w7bDtuC4geC4teC5icaSw6zDrMOsxZltzILhur3hur3hur3GjC5dIiwiUmVzdW1lIHBvc3Rpbmcgam9iIGFkIG1lc3NhZ2UiOiJbxZjhur3hur3hur3Foceax5rHmm3MguG6veG6veG6vSDGpcO2w7bDtsWh4bmvw6zDrMOs4LiB4Li14LmJxKMgw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyDEg8SDxIPGjCDDn8O9w73DvSDDp8aaw7bDtsO2xaHDrMOsw6zguIHguLXguYnEoyDhua/huKnDrMOsw6zFoSDhua/Eg8SDxIPDnyDEg8SDxIPguIHguLXguYnGjCDDp8O2w7bDtuC4geC4teC5ieG5r8Osw6zDrOC4geC4teC5iceax5rHmsOsw6zDrOC4geC4teC5icSjIMOsw6zDrOC4geC4teC5iSDhua/huKnhur3hur3hur0gw7bDtsO24bmv4bip4bq94bq94bq9xZkgw5/FmcO2w7bDtsW1xaHhur3hur3hur3FmSDhua/Eg8SDxIPDny5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJFbWFpbCBpcyBjb25maXJtZWQgdGl0bGUiOiJb4bmu4bipxIPEg8SD4LiB4Li14LmJxLfFoSEgw53DtsO2w7bHmseax5rFmSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDDrMOsw6zFoSDDp8O2w7bDtuC4geC4teC5icaSw6zDrMOsxZltzILhur3hur3hur3GjC5dIiwiUmVzdW1lIHBvc3Rpbmcgam9iIGFkIG1lc3NhZ2UiOiJbxZjhur3hur3hur3Foceax5rHmm3MguG6veG6veG6vSDGpcO2w7bDtsWh4bmvw6zDrMOs4LiB4Li14LmJxKMgw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyDEg8SDxIPGjCDDn8O9w73DvSDDp8aaw7bDtsO2xaHDrMOsw6zguIHguLXguYnEoyDhua/huKnDrMOsw6zFoSDhua/Eg8SDxIPDnyDEg8SDxIPguIHguLXguYnGjCDDp8O2w7bDtuC4geC4teC5ieG5r8Osw6zDrOC4geC4teC5iceax5rHmsOsw6zDrOC4geC4teC5icSjIMOsw6zDrOC4geC4teC5iSDhua/huKnhur3hur3hur0gw7bDtsO24bmv4bip4bq94bq94bq9xZkgw5/FmcO2w7bDtsW1xaHhur3hur3hur3FmSDhua/Eg8SDxIPDny5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    
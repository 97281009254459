import { useTranslations } from '@vocab/react';
import {
  Box,
  FieldLabel,
  TextField,
  Columns,
  Column,
  Dropdown,
  Stack,
  FieldMessage,
} from 'braid-design-system';
import {
  type CountryCode,
  getExampleNumber,
  validatePhoneNumberLength,
  isPossibleNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { type FormEvent, useEffect, useState } from 'react';

import { useConfig } from 'src/hooks/context';
import type { Maybe } from 'src/types';
import translations from 'src/views/shared/forms/RegistrationForm/.vocab';

import { allCountryCodes, seekCountryCodes } from './areaCodeData';

interface Props {
  id: string;
  label?: string;
  description?: string;
  phoneNumber: Maybe<string>;
  onPhoneNumberChange: (value: Maybe<string>) => void;
  message?: string;
}

export const PhoneNumber = ({
  id,
  label = 'Phone Number',
  description,
  phoneNumber,
  onPhoneNumberChange,
  message,
}: Props) => {
  const [callingCode, setCallingCode] = useState('');
  const [nationalNumber, setNationalNumber] = useState('');
  const { t } = useTranslations(translations);
  const { locale } = useConfig();

  const formattedList = [
    { label: t('SEEK countries'), data: seekCountryCodes },
    { label: t('All countries'), data: allCountryCodes },
  ];

  const countryCode = allCountryCodes.find(
    (item) => item.callingCode === callingCode,
  )?.countryCode as CountryCode;

  const getPlaceholder = () => {
    if (countryCode) {
      return (
        getExampleNumber(countryCode, examples)
          ?.formatInternational()
          ?.replace(`+${callingCode} `, '')
          ?.replace(/\s+/g, '') || ''
      );
    }
    return t('Enter phone number');
  };

  useEffect(() => {
    setCallingCode(
      allCountryCodes.find((item) => item.countryCode === locale)
        ?.callingCode || '',
    );
  }, [locale]);

  useEffect(() => {
    if (phoneNumber && isPossibleNumber(`+${phoneNumber}`)) {
      const parsedNumber = parsePhoneNumber(`+${phoneNumber}`);
      setCallingCode(parsedNumber.countryCallingCode);
      setNationalNumber(parsedNumber.nationalNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNumberChange = (e: FormEvent<HTMLInputElement>) => {
    const enteredValue = e.currentTarget.value;
    if (!enteredValue) {
      onPhoneNumberChange('');
      setNationalNumber('');
      return;
    }

    if (
      Boolean(enteredValue.match(/\s\s+/g)) ||
      !enteredValue.match(/^[0-9\s]*$/)
    ) {
      // just numbers, no consecutive space
      return;
    }
    const pNumber = `+${callingCode} ${enteredValue}`;

    if (validatePhoneNumberLength(pNumber) === 'NOT_A_NUMBER') {
      return;
    }
    if (validatePhoneNumberLength(pNumber) === 'TOO_LONG') {
      return;
    }

    onPhoneNumberChange(`${callingCode} ${enteredValue}`);
    setNationalNumber(enteredValue);
  };

  const handleCallingCodeChange = (e: FormEvent<HTMLSelectElement>) => {
    const newCallingCode = (e.target as HTMLSelectElement).value;
    setCallingCode(newCallingCode);
    if (nationalNumber !== '') {
      onPhoneNumberChange(`${newCallingCode} ${nationalNumber}`);
    }
  };

  const handleClear = () => {
    onPhoneNumberChange('');
    setNationalNumber('');
  };

  return (
    <Box id={id}>
      <Stack space="xsmall">
        <FieldLabel
          label={label}
          description={description}
          htmlFor="phone-number"
        />
        <Columns
          space={{ mobile: 'small', tablet: 'medium' }}
          collapseBelow="tablet"
        >
          <Column width="2/5">
            <Dropdown
              id="calling-code"
              aria-label="calling-code"
              onChange={(e) => handleCallingCodeChange(e)}
              value={callingCode}
              placeholder={t('Select country code')}
              data={{ testid: 'calling-code' }}
            >
              {formattedList.map((item) => (
                <optgroup label={item.label} key={item.label}>
                  {item.data.map((area, index) => (
                    <option key={index} value={area.callingCode}>
                      {area.countryName} ( +{area.callingCode} )
                    </option>
                  ))}
                </optgroup>
              ))}
            </Dropdown>
          </Column>
          <Column>
            <TextField
              disabled={!callingCode}
              id="phone-number"
              aria-label="phone-number"
              prefix={`+${callingCode}`}
              type="text"
              placeholder={getPlaceholder()}
              data={{ testid: 'phone-number' }}
              value={nationalNumber}
              onChange={(e) => handleNumberChange(e)}
              onClear={() => handleClear()}
              tone={message ? 'critical' : 'neutral'}
            />
          </Column>
        </Columns>
        <FieldMessage id="error-message" message={message} tone="critical" />
      </Stack>
    </Box>
  );
};

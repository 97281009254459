
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiC4Lit4Lia4LiE4Li44LiTISDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYHguKXguYnguKcuIiwiUG9zdCBhZCBsaW5rIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LiZ4LiX4Li1IiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiLguILguLHguYnguJnguJXguK3guJnguJXguYjguK3guYTguJvguITguLfguK3guIHguLLguKPguYDguKPguLTguYjguKHguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiC4Lit4Lia4LiE4Li44LiTISDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYHguKXguYnguKcuIiwiUG9zdCBhZCBsaW5rIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LiZ4LiX4Li1IiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiLguILguLHguYnguJnguJXguK3guJnguJXguYjguK3guYTguJvguITguLfguK3guIHguLLguKPguYDguKPguLTguYjguKHguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGVyaW1hIGthc2loISBBa3VuIEFuZGEgdGVsYWggZGlha3RpZmthbi4iLCJQb3N0IGFkIGxpbmsiOiJQYXNhbmcgaWtsYW4gQW5kYSBzZWthcmFuZyIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoiTGFuZ2thaCBzZWxhbmp1dG55YSBhZGFsYWggbXVsYWkgbWVtYnVhdCBpa2xhbiBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGVyaW1hIGthc2loISBBa3VuIEFuZGEgdGVsYWggZGlha3RpZmthbi4iLCJQb3N0IGFkIGxpbmsiOiJQYXNhbmcgaWtsYW4gQW5kYSBzZWthcmFuZyIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoiTGFuZ2thaCBzZWxhbmp1dG55YSBhZGFsYWggbXVsYWkgbWVtYnVhdCBpa2xhbiBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiC4Lit4Lia4LiE4Li44LiTISDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYHguKXguYnguKcuIiwiUG9zdCBhZCBsaW5rIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LiZ4LiX4Li1IiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiLguILguLHguYnguJnguJXguK3guJnguJXguYjguK3guYTguJvguITguLfguK3guIHguLLguKPguYDguKPguLTguYjguKHguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoi4LiC4Lit4Lia4LiE4Li44LiTISDguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYHguKXguYnguKcuIiwiUG9zdCBhZCBsaW5rIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LiZ4LiX4Li1IiwiU3RhcnQgam9iIGFkIG1lc3NhZ2UiOiLguILguLHguYnguJnguJXguK3guJnguJXguYjguK3guYTguJvguITguLfguK3guIHguLLguKPguYDguKPguLTguYjguKHguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGVyaW1hIGthc2loISBBa3VuIEFuZGEgdGVsYWggZGlha3RpZmthbi4iLCJQb3N0IGFkIGxpbmsiOiJQYXNhbmcgaWtsYW4gQW5kYSBzZWthcmFuZyIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoiTGFuZ2thaCBzZWxhbmp1dG55YSBhZGFsYWggbXVsYWkgbWVtYnVhdCBpa2xhbiBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGVyaW1hIGthc2loISBBa3VuIEFuZGEgdGVsYWggZGlha3RpZmthbi4iLCJQb3N0IGFkIGxpbmsiOiJQYXNhbmcgaWtsYW4gQW5kYSBzZWthcmFuZyIsIlN0YXJ0IGpvYiBhZCBtZXNzYWdlIjoiTGFuZ2thaCBzZWxhbmp1dG55YSBhZGFsYWggbXVsYWkgbWVtYnVhdCBpa2xhbiBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiVGhhbmtzISBZb3VyIGFjY291bnQgaGFzIGJlZW4gYWN0aXZhdGVkLiIsIlBvc3QgYWQgbGluayI6IlBvc3QgeW91ciBhZCBub3ciLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6IlRoZSBuZXh0IHN0ZXAgaXMgdG8gc3RhcnQgeW91ciBqb2IgYWQuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiW+G5ruG4qcSDxIPEg+C4geC4teC5icS3xaEhIMOdw7bDtsO2x5rHmseaxZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDhuKnEg8SDxIPFoSDDn+G6veG6veG6veG6veG6veG6veC4geC4teC5iSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vcaMLl0iLCJQb3N0IGFkIGxpbmsiOiJbxqTDtsO2w7bFoeG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjCDguIHguLXguYnDtsO2w7bFtV0iLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6Ilvhua7huKnhur3hur3hur0g4LiB4Li14LmJ4bq94bq94bq96q2V4bmvIMWh4bmv4bq94bq94bq9xqUgw6zDrMOsxaEg4bmvw7bDtsO2IMWh4bmvxIPEg8SDxZnhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyDEg8SDxIPGjC5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBY2NvdW50IGJlZW4gYWN0aXZhdGVkIjoiW+G5ruG4qcSDxIPEg+C4geC4teC5icS3xaEhIMOdw7bDtsO2x5rHmseaxZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDhuKnEg8SDxIPFoSDDn+G6veG6veG6veG6veG6veG6veC4geC4teC5iSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vcaMLl0iLCJQb3N0IGFkIGxpbmsiOiJbxqTDtsO2w7bFoeG5ryDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjCDguIHguLXguYnDtsO2w7bFtV0iLCJTdGFydCBqb2IgYWQgbWVzc2FnZSI6Ilvhua7huKnhur3hur3hur0g4LiB4Li14LmJ4bq94bq94bq96q2V4bmvIMWh4bmv4bq94bq94bq9xqUgw6zDrMOsxaEg4bmvw7bDtsO2IMWh4bmvxIPEg8SDxZnhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxLXDtsO2w7bDnyDEg8SDxIPGjC5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    
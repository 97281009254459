import type { EmbedReferences } from '@graphcms/rich-text-types';
import { useTrackLink } from '@seek/cmsu-analytics';
import {
  type BannerShopfrontFragment,
  CParagraphMarginBottom,
  CTextAlign,
} from '@seek/cmsu-cms-connect';
import { Heading, Paragraph } from '@seek/cmsu-components';
import { RichText } from '@seek/cmsu-rich-text';
import {
  Box,
  Hidden,
  ContentBlock,
  Stack,
  Text,
  Strong,
  TextLink,
  Alert,
} from 'braid-design-system';

import { useConfig } from 'src/hooks/context';
import EmailCaptureFormContainer from 'src/views/shared/forms/EmailCaptureForm/EmailCaptureFormContainer';

import { bgImage, content, root } from './HeroBanner.css';

const renderDescription = (paragraph: any, isAnz: boolean) =>
  isAnz ? (
    <Paragraph
      content={paragraph.Paragraph_text?.raw}
      marginBottom={CParagraphMarginBottom.None}
      align={paragraph.align}
      tone={paragraph.tone}
      size={paragraph.size}
    />
  ) : (
    <Alert tone="promote">
      <Paragraph
        content={paragraph.Paragraph_text?.raw}
        marginBottom={CParagraphMarginBottom.None}
        align={CTextAlign.Left}
      />
    </Alert>
  );

type Props = Pick<
  BannerShopfrontFragment,
  'heading' | 'image' | 'paragraph'
> & {
  footnotes: BannerShopfrontFragment['BannerShopfront_footnotes'];
};

const BannerImage = ({
  image,
  position,
}: {
  image: BannerShopfrontFragment['image'];
  position: string;
}) => (
  <Box
    className={bgImage}
    style={{
      ...(image && { backgroundImage: `url(${image.url})` }),
      backgroundPosition: position,
    }}
  />
);

export const HeroBanner = ({ footnotes, heading, image, paragraph }: Props) => {
  const { isAnz } = useConfig();

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingX="gutter"
      paddingY={{
        mobile: 'gutter',
        tablet: 'xlarge',
      }}
      className={root}
      data-testid="HeroBannerTestId"
    >
      <Hidden below="tablet">
        <BannerImage image={image} position="center" />
      </Hidden>
      <Box position="relative" zIndex={1} flexGrow={{ mobile: 1 }}>
        <ContentBlock>
          <Box
            className={content}
            background="surface"
            borderRadius="xlarge"
            padding={{ tablet: 'xlarge' }}
          >
            <Stack space="large">
              <Hidden above="mobile">
                <Box position="relative" style={{ minHeight: 300 }}>
                  <BannerImage image={image} position="center right" />
                </Box>
              </Hidden>
              <Stack space={{ mobile: 'medium', tablet: 'large' }}>
                <Box textAlign={{ mobile: 'center', tablet: 'left' }}>
                  <Stack space={{ mobile: 'medium', tablet: 'large' }}>
                    {heading?.text ? <Heading {...heading} /> : null}
                    {renderDescription(paragraph, isAnz)}
                  </Stack>
                </Box>

                <Stack space="none" align="left">
                  <EmailCaptureFormContainer />
                </Stack>
                {footnotes && (
                  <Box textAlign={{ mobile: 'center', tablet: 'left' }}>
                    <Stack space="medium">
                      {footnotes.map((footnote, index) => (
                        <RichText
                          key={`rte-${index}`}
                          content={footnote.richTextEditorWrapper?.content?.raw}
                          references={
                            footnote.richTextEditorWrapper?.content
                              ?.references as EmbedReferences
                          }
                          renderers={{
                            embed: {
                              EmbedSingleLineText: ({ value, bold }) =>
                                bold ? (
                                  <Strong>{value}</Strong>
                                ) : (
                                  <span>{value}</span>
                                ),
                            },
                            link: {
                              EmbedSingleLineText: ({
                                value,
                                children,
                                nodeId,
                              }) => {
                                const onClickLink = useTrackLink(nodeId);
                                return (
                                  <TextLink
                                    onClick={() =>
                                      onClickLink ? onClickLink() : undefined
                                    }
                                    href={value}
                                  >
                                    {children}
                                  </TextLink>
                                );
                              },
                            },
                            p: ({ children }) => <Text>{children}</Text>,
                          }}
                        />
                      ))}
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </ContentBlock>
      </Box>
    </Box>
  );
};

import gql from 'graphql-tag';

import getClient from 'src/modules/ApiClient/hirerGraph/apiClient';
import type { CountrySelectionOptions } from 'src/modules/ApiClient/types';

export const query = gql`
  query GetCountries {
    countrySelectionOptions {
      options {
        name
        countryCode
      }
    }
  }
`;

export type GetCountries = () => Promise<CountrySelectionOptions>;

const getCountries: GetCountries = async () => {
  const graphqlClient = getClient();
  const result = await graphqlClient.query<{
    countrySelectionOptions: CountrySelectionOptions;
  }>({
    query,
  });

  return result.data.countrySelectionOptions;
};

export default getCountries;

import countryCodes from 'country-codes-list';

interface CountryMap {
  countryName: string;
  countryCode: string;
  callingCode: string;
}

const format = (arr: any) =>
  arr
    .map((country: any) => ({
      countryName: country.countryNameEn,
      countryCode: country.countryCode,
      callingCode: country.countryCallingCode,
    }))
    .sort(function (a: CountryMap, b: CountryMap) {
      if (a.countryName < b.countryName) {
        return -1;
      }
      if (a.countryName > b.countryName) {
        return 1;
      }
      return 0;
    });

export const allCountryCodes: CountryMap[] = format(countryCodes.all());

export const seekCountryCodes: CountryMap[] = format(
  countryCodes
    .all()
    .filter(
      (country) =>
        country.countryCode === 'AU' ||
        country.countryCode === 'HK' ||
        country.countryCode === 'ID' ||
        country.countryCode === 'MY' ||
        country.countryCode === 'NZ' ||
        country.countryCode === 'PH' ||
        country.countryCode === 'SG' ||
        country.countryCode === 'TH',
    ),
);

import { Card, Stack, Heading } from 'braid-design-system';
import type { ComponentProps } from 'react';

interface Props {
  icon: JSX.Element;
  heading: string;
  children: ComponentProps<typeof Stack>['children'];
}

export const FormSection = ({ icon, heading, children }: Props) => (
  <Card>
    <Stack space="xlarge">
      <Heading level="3">
        {icon} {heading}
      </Heading>
      <Stack space="medium">{children}</Stack>
    </Stack>
  </Card>
);

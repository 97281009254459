
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4ouC4t+C4meC4ouC4seC4meC4leC4seC4p+C4leC4meC4nOC5iOC4suC4meC4reC4teC5gOC4oeC4pSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguYDguK3guYDguIjguJnguIvguLXguYTguJTguYkiLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoi4Lit4Li14LmA4Lih4Lil4LiC4Lit4LiH4LmA4Lit4LmA4LiI4LiZ4LiL4Li14LiX4Li14LmI4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4LmE4Lin4LmJIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguKrguYjguIfguK3guLXguYDguKHguKXguKrguLPguKvguKPguLHguJrguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYTguJvguKLguLHguIcgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiLguIHguKPguLjguJPguLI8TGluaz7guK3guK3guIHguIjguLLguIHguKPguLDguJrguJo8L0xpbms+IOC4iOC4suC4geC4meC4seC5ieC4meC4m+C4tOC4lOC5geC4l+C5h+C4muC5gOC4muC4o+C4suC4p+C5jOC5gOC4i+C4reC4o+C5jOC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4ouC4t+C4meC4ouC4seC4meC4leC4seC4p+C4leC4meC4nOC5iOC4suC4meC4reC4teC5gOC4oeC4pSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguYDguK3guYDguIjguJnguIvguLXguYTguJTguYkiLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoi4Lit4Li14LmA4Lih4Lil4LiC4Lit4LiH4LmA4Lit4LmA4LiI4LiZ4LiL4Li14LiX4Li14LmI4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4LmE4Lin4LmJIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguKrguYjguIfguK3guLXguYDguKHguKXguKrguLPguKvguKPguLHguJrguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYTguJvguKLguLHguIcgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiLguIHguKPguLjguJPguLI8TGluaz7guK3guK3guIHguIjguLLguIHguKPguLDguJrguJo8L0xpbms+IOC4iOC4suC4geC4meC4seC5ieC4meC4m+C4tOC4lOC5geC4l+C5h+C4muC5gOC4muC4o+C4suC4p+C5jOC5gOC4i+C4reC4o+C5jOC4meC4teC5iSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJIYXJhcCB0aW5kYWtsYW5qdXRpIHRhdXRhbiB2ZXJpZmlrYXNpIGRpIGVtYWlsIGFnYXIgYWt1biBBbmRhIGRhcGF0IGRpYWt0aWZrYW4uIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6ImFsYW1hdCBlbWFpbCBwZW5nZ3VuYSBhZ2Vuc2kgdGVyZGFmdGFyIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJTaWxha2FuIDxMaW5rPmtlbHVhcjwvTGluaz4gZGFuIHR1dHVwIHRhYiBicm93c2VyIGluaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJIYXJhcCB0aW5kYWtsYW5qdXRpIHRhdXRhbiB2ZXJpZmlrYXNpIGRpIGVtYWlsIGFnYXIgYWt1biBBbmRhIGRhcGF0IGRpYWt0aWZrYW4uIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6ImFsYW1hdCBlbWFpbCBwZW5nZ3VuYSBhZ2Vuc2kgdGVyZGFmdGFyIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJTaWxha2FuIDxMaW5rPmtlbHVhcjwvTGluaz4gZGFuIHR1dHVwIHRhYiBicm93c2VyIGluaS4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4ouC4t+C4meC4ouC4seC4meC4leC4seC4p+C4leC4meC4nOC5iOC4suC4meC4reC4teC5gOC4oeC4pSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguYDguK3guYDguIjguJnguIvguLXguYTguJTguYkiLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoi4Lit4Li14LmA4Lih4Lil4LiC4Lit4LiH4LmA4Lit4LmA4LiI4LiZ4LiL4Li14LiX4Li14LmI4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4LmE4Lin4LmJIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguKrguYjguIfguK3guLXguYDguKHguKXguKrguLPguKvguKPguLHguJrguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYTguJvguKLguLHguIcgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiLguIHguKPguLjguJPguLI8TGluaz7guK3guK3guIHguIjguLLguIHguKPguLDguJrguJo8L0xpbms+IOC4iOC4suC4geC4meC4seC5ieC4meC4m+C4tOC4lOC5geC4l+C5h+C4muC5gOC4muC4o+C4suC4p+C5jOC5gOC4i+C4reC4o+C5jOC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IuC5g+C4geC4peC5ieC4quC4s+C5gOC4o+C5h+C4iOC5geC4peC5ieC4pyEiLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IuC4quC5iOC4h+C4peC4tOC4h+C4geC5jOC4ouC4t+C4meC4ouC4seC4meC4leC4seC4p+C4leC4meC4nOC5iOC4suC4meC4reC4teC5gOC4oeC4pSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguYDguK3guYDguIjguJnguIvguLXguYTguJTguYkiLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoi4Lit4Li14LmA4Lih4Lil4LiC4Lit4LiH4LmA4Lit4LmA4LiI4LiZ4LiL4Li14LiX4Li14LmI4Lil4LiH4LiX4Liw4LmA4Lia4Li14Lii4LiZ4LmE4Lin4LmJIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiLguYDguKPguLLguYTguJTguYnguJfguLPguIHguLLguKPguKrguYjguIfguK3guLXguYDguKHguKXguKrguLPguKvguKPguLHguJrguYDguJvguLTguJTguYPguIrguYnguIfguLLguJnguYTguJvguKLguLHguIcgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiLguIHguKPguLjguJPguLI8TGluaz7guK3guK3guIHguIjguLLguIHguKPguLDguJrguJo8L0xpbms+IOC4iOC4suC4geC4meC4seC5ieC4meC4m+C4tOC4lOC5geC4l+C5h+C4muC5gOC4muC4o+C4suC4p+C5jOC5gOC4i+C4reC4o+C5jOC4meC4teC5iSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJIYXJhcCB0aW5kYWtsYW5qdXRpIHRhdXRhbiB2ZXJpZmlrYXNpIGRpIGVtYWlsIGFnYXIgYWt1biBBbmRhIGRhcGF0IGRpYWt0aWZrYW4uIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6ImFsYW1hdCBlbWFpbCBwZW5nZ3VuYSBhZ2Vuc2kgdGVyZGFmdGFyIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJTaWxha2FuIDxMaW5rPmtlbHVhcjwvTGluaz4gZGFuIHR1dHVwIHRhYiBicm93c2VyIGluaS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkhhbXBpciBzZWxlc2FpIiwiTmVlZCB2ZXJpZmljYXRpb24gYWN0aW9uIHRvIGFjdGl2YXRlIG1lc3NhZ2UiOiJIYXJhcCB0aW5kYWtsYW5qdXRpIHRhdXRhbiB2ZXJpZmlrYXNpIGRpIGVtYWlsIGFnYXIgYWt1biBBbmRhIGRhcGF0IGRpYWt0aWZrYW4uIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6ImFsYW1hdCBlbWFpbCBwZW5nZ3VuYSBhZ2Vuc2kgdGVyZGFmdGFyIiwiU2VudCBhY3RpdmF0aW9uIGVtYWlsIG1lc3NhZ2UiOiJLYW1pIGJhcnUgc2FqYSBtZW5naXJpbSBlbWFpbCB0YXV0YW4gYWt0aXZhc2kga2UgIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJTaWxha2FuIDxMaW5rPmtlbHVhcjwvTGluaz4gZGFuIHR1dHVwIHRhYiBicm93c2VyIGluaS4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IkFsbW9zdCBkb25lISIsIk5lZWQgdmVyaWZpY2F0aW9uIGFjdGlvbiB0byBhY3RpdmF0ZSBtZXNzYWdlIjoiQXJyYW5nZSBmb3IgdGhlIHZlcmlmaWNhdGlvbiBsaW5rIGluIHRoZSBlbWFpbCB0byBiZSBhY3Rpb25lZCBzbyB0aGF0IHRoZSBhZ2VuY3kgYWNjb3VudCBpcyBhY3RpdmF0ZWQuIiwiUmVnaXN0ZXIgYWdlbmN5IHVzZXIgZW1haWwgYWRkcmVzcyI6InRoZSByZWdpc3RlcmVkIGFnZW5jeSB1c2VyIGVtYWlsIGFkZHJlc3MiLCJTZW50IGFjdGl2YXRpb24gZW1haWwgbWVzc2FnZSI6IldlIGp1c3Qgc2VudCBhbiBhY3RpdmF0aW9uIGVtYWlsIGxpbmsgdG8gIiwiU2lnbiBvdXQgYW5kIGNsb3NlIGJyb3dzZXIgdGFiIG1lc3NhZ2UiOiJQbGVhc2UgPExpbms+c2lnbiBvdXQ8L0xpbms+IGFuZCB0aGVuIGNsb3NlIHRoaXMgYnJvd3NlciB0YWIuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IlvhuqzGmm3MgsO2w7bDtsWh4bmvIMaMw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IV0iLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IlvhuqzFmcWZxIPEg8SD4LiB4Li14LmJxKPhur3hur3hur0gxpLDtsO2w7bFmSDhua/huKnhur3hur3hur0g4bm94bq94bq94bq9xZnDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOs4LiB4Li14LmJxLcgw6zDrMOs4LiB4Li14LmJIOG5r+G4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDhua/DtsO2w7Ygw5/hur3hur3hur0gxIPEg8SDw6fhua/DrMOsw6zDtsO2w7bguIHguLXguYnhur3hur3hur3GjCDFocO2w7bDtiDhua/huKnEg8SDxIPhua8g4bmv4bip4bq94bq94bq9IMSDxIPEg8Sj4bq94bq94bq94LiB4Li14LmJw6fDvcO9w70gxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDrMOsw6zFoSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vcaMLl0iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiW+G5r+G4qeG6veG6veG6vSDFmeG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmeG6veG6veG6vcaMIMSDxIPEg8Sj4bq94bq94bq94LiB4Li14LmJw6fDvcO9w70gx5rHmseaxaHhur3hur3hur3FmSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhXSIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBtZXNzYWdlIjoiW8W04bq94bq94bq9IMS1x5rHmseaxaHhua8gxaHhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIMaaw6zDrMOs4LiB4Li14LmJxLcg4bmvw7bDtsO2IF0iLCJTaWduIG91dCBhbmQgY2xvc2UgYnJvd3NlciB0YWIgbWVzc2FnZSI6IlvGpMaa4bq94bq9xIPEg8Wh4bq94bq9IDxMaW5rPsWhw6zDrMOsxKPguIHguLXguYkgw7bDtsO2x5rHmsea4bmvPC9MaW5rPiDEg8SDxIPguIHguLXguYnGjCDhua/huKnhur3hur3hur3guIHguLXguYkgw6fGmsO2w7bDtsWh4bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMOfxZnDtsO2w7bFtcWh4bq94bq94bq9xZkg4bmvxIPEg8SDw58uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbG1vc3QgZG9uZSI6IlvhuqzGmm3MgsO2w7bDtsWh4bmvIMaMw7bDtsO24LiB4Li14LmJ4bq94bq94bq9IV0iLCJOZWVkIHZlcmlmaWNhdGlvbiBhY3Rpb24gdG8gYWN0aXZhdGUgbWVzc2FnZSI6IlvhuqzFmcWZxIPEg8SD4LiB4Li14LmJxKPhur3hur3hur0gxpLDtsO2w7bFmSDhua/huKnhur3hur3hur0g4bm94bq94bq94bq9xZnDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOs4LiB4Li14LmJxLcgw6zDrMOs4LiB4Li14LmJIOG5r+G4qeG6veG6veG6vSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDhua/DtsO2w7Ygw5/hur3hur3hur0gxIPEg8SDw6fhua/DrMOsw6zDtsO2w7bguIHguLXguYnhur3hur3hur3GjCDFocO2w7bDtiDhua/huKnEg8SDxIPhua8g4bmv4bip4bq94bq94bq9IMSDxIPEg8Sj4bq94bq94bq94LiB4Li14LmJw6fDvcO9w70gxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5ryDDrMOsw6zFoSDEg8SDxIPDp+G5r8Osw6zDrOG5vcSDxIPEg+G5r+G6veG6veG6vcaMLl0iLCJSZWdpc3RlciBhZ2VuY3kgdXNlciBlbWFpbCBhZGRyZXNzIjoiW+G5r+G4qeG6veG6veG6vSDFmeG6veG6veG6vcSjw6zDrMOsxaHhua/hur3hur3hur3FmeG6veG6veG6vcaMIMSDxIPEg8Sj4bq94bq94bq94LiB4Li14LmJw6fDvcO9w70gx5rHmseaxaHhur3hur3hur3FmSDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhXSIsIlNlbnQgYWN0aXZhdGlvbiBlbWFpbCBtZXNzYWdlIjoiW8W04bq94bq94bq9IMS1x5rHmseaxaHhua8gxaHhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJIMSDxIPEg8On4bmvw6zDrMOs4bm9xIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIOG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIMaaw6zDrMOs4LiB4Li14LmJxLcg4bmvw7bDtsO2IF0iLCJTaWduIG91dCBhbmQgY2xvc2UgYnJvd3NlciB0YWIgbWVzc2FnZSI6IlvGpMaa4bq94bq9xIPEg8Wh4bq94bq9IDxMaW5rPsWhw6zDrMOsxKPguIHguLXguYkgw7bDtsO2x5rHmsea4bmvPC9MaW5rPiDEg8SDxIPguIHguLXguYnGjCDhua/huKnhur3hur3hur3guIHguLXguYkgw6fGmsO2w7bDtsWh4bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMOfxZnDtsO2w7bFtcWh4bq94bq94bq9xZkg4bmvxIPEg8SDw58uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    
import {
  URL_REGISTER_ADVERTISER_COMPLETE,
  URL_ROOT,
} from '@seek/adv-constants';
import { useTrackView } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Text, Heading, Strong, Stack } from 'braid-design-system';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { META_TITLE_REGISTRATION_COMPLETE } from 'src/constants/pageMetaData';
import { REGISTER_COMPLETE_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { ContactDetails } from 'src/views/shared/ContactDetails/ContactDetails';

import translations from './.vocab';

const RegistrationCompletePage = () => {
  const { absoluteEmployerUrlResolver } = useConfig();
  const location = useLocation();
  const { t } = useTranslations(translations);

  const fullUrl = absoluteEmployerUrlResolver(URL_REGISTER_ADVERTISER_COMPLETE);

  const trackFunction = useTrackView(REGISTER_COMPLETE_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!location.state) {
    return <Navigate to={URL_ROOT} />;
  }

  const { email } = location.state.registeredUserDetails;

  return (
    <EmptyPageLayout
      seoMeta={{
        title: META_TITLE_REGISTRATION_COMPLETE,
        description: '',
        url: fullUrl,
      }}
      heading={t('Registration complete page title')}
    >
      <Stack space="xlarge">
        <Stack space="medium">
          <Text component="p">
            {t('Sent activation email link message')}{' '}
            <Strong data={{ 'dd-privacy': 'mask', 'hj-masked': true }}>
              {email}
            </Strong>
            .
          </Text>
          <Text component="p">
            {t('Follow email link instruction message')}
          </Text>
        </Stack>

        <Stack space="medium" align="center">
          <Heading level="3">{t('Did not receive the email title')}</Heading>
          <Stack space="small">
            <Text>1. {t('Check email is correct message')}</Text>
            <Text>2. {t('Check your spam folder')}</Text>
          </Stack>
        </Stack>
        <ContactDetails />
      </Stack>
    </EmptyPageLayout>
  );
};

export default RegistrationCompletePage;

// All page meta data in one maintainable place.
// ===================================================

import { siteNames } from '@seek/melways-sites';

import type { EmployerSiteName } from 'src/types';
import { isServerRendering } from 'src/utils/environment';

const META_TITLE_ROOT = 'SEEK Employer';

// Home
// TODO: Remove "Home" consts once HyGraph home page content is live
export const META_TITLE_HOME = `${META_TITLE_ROOT}: Login & Find Talent`;
export const META_DESCRIPTION_HOME = {
  [siteNames.employer.seek.au]:
    'Find your next hire on Australia’s largest employment marketplace! With 96 roles filled hourly, we place more people in more jobs. Login & start finding talent.',
  [siteNames.employer.seek.nz]:
    'Find your next hire on New Zealand’s largest employment marketplace! With 96 roles filled hourly, we place more people in more jobs. Login & start finding talent.',
};

// Registration
// TODO: Add descriptions for Asia sites or remove?
export const META_DESCRIPTION_REGISTER = {
  [siteNames.employer.seek.au]:
    'Register as an Employer with Australia’s no.1 Job Site, SEEK - Place your ad today!',
  [siteNames.employer.seek.nz]:
    'Register as an Employer with New Zealand’s no.1 Job Site, SEEK - Place your ad today!',
};

export const META_TITLE_REGISTRATION_COMPLETE = `${META_TITLE_ROOT}: Verification email sent`;

export const getMetaDataBySite = (
  site: EmployerSiteName,
  mapping: Partial<Record<EmployerSiteName, string>>,
) => {
  const description = mapping[site];
  // Do we need to log an error here? Line 45 suggests description can be optional
  if (!description && !isServerRendering()) {
    // eslint-disable-next-line no-console
    console.error(`Metadata description does not exist for site: '${site}'`);
    return 'Metadata needed';
  }
  return description ?? '';
};

import { URL_SIGNIN } from '@seek/adv-constants';
import { useTrackView } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Text, Stack } from 'braid-design-system';
import { useEffect } from 'react';

import { URL_USER_VERIFICATION_COMPLETE } from 'src/constants/page-urls';
import { META_TITLE_REGISTRATION_COMPLETE } from 'src/constants/pageMetaData';
import { USER_CONFIRM_PAGE_VIEW } from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import { EmptyPageLayout } from 'src/views/layouts/EmptyPageLayout/EmptyPageLayout';
import { ContactDetails } from 'src/views/shared/ContactDetails/ContactDetails';

import translations from './.vocab';

const UserVerificationCompletePage = () => {
  const { absoluteEmployerUrlResolver } = useConfig();
  const { t } = useTranslations(translations);

  const trackFunction = useTrackView(USER_CONFIRM_PAGE_VIEW);

  useEffect(() => {
    if (trackFunction) {
      trackFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.assign(URL_SIGNIN);
    }, 3000);
  }, []);

  const fullUrl = absoluteEmployerUrlResolver(URL_USER_VERIFICATION_COMPLETE);

  return (
    <EmptyPageLayout
      seoMeta={{
        title: META_TITLE_REGISTRATION_COMPLETE,
        description: '',
        url: fullUrl,
      }}
      heading={t('Email is confirmed title')}
    >
      <Stack space="large">
        <Text>{t('Resume posting job ad message')}</Text>
        <ContactDetails />
      </Stack>
    </EmptyPageLayout>
  );
};

export default UserVerificationCompletePage;

import { URL_CREATE_NEW_JOB } from '@seek/adv-constants';
import { getZoneFromSite } from '@seek/melways-sites';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import type {
  AccountSelectionV2Response,
  Identity,
} from 'src/graphql/generated';
import { useConfig } from 'src/hooks/context';
import registerAdditionalAdvertiser from 'src/modules/ApiClient/hirerGraph/mutations/gql_registerAdditionalAdvertiser';
import registerNewUser from 'src/modules/ApiClient/hirerGraph/mutations/gql_registerNewUser';
import checkRegistrationStatus from 'src/modules/ApiClient/hirerGraph/queries/gql_checkRegistrationStatus';
import getAccountSelection from 'src/modules/ApiClient/hirerGraph/queries/gql_getAccountSelection';
import getCountries from 'src/modules/ApiClient/hirerGraph/queries/gql_getCountries';
import getIdentity from 'src/modules/ApiClient/hirerGraph/queries/gql_getIdentity';
import getRegistrationOptions from 'src/modules/ApiClient/hirerGraph/queries/gql_getRegistrationOptions';
import type {
  CountrySelectionOptions,
  NewAdvertiser,
  NewUser,
  RegistrationOptions,
} from 'src/modules/ApiClient/types';
import type { FormValues } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';

export type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      accountSelectionOptions: AccountSelectionV2Response;
      userExists: boolean;
      identity: Identity;
      countries: CountrySelectionOptions;
      submit: (formValues: FormValues) => Promise<string>;
      registrationOptions: RegistrationOptions;
      numberOfAccounts: number;
    };

interface ProviderProps {
  children: ReactNode;
}

const defaultValue: State = { loading: true };
const RegistrationPageContext = createContext<State | undefined>(defaultValue);

export const useRegistrationPageContext = () => {
  const context = useContext(RegistrationPageContext);

  if (!context) {
    throw new Error(
      'RegistrationPageState must be used within a RegistrationPageProvider',
    );
  }

  return context;
};

export const RegistrationPageProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState<State>({
    loading: true,
  });
  const { site, urlResolver, locale } = useConfig();

  useEffect(() => {
    const fetchGql = async () => {
      const [
        registrationStatus,
        accountSelectionOptions,
        identity,
        countries,
        registrationOptions,
      ] = await Promise.all([
        checkRegistrationStatus(),
        getAccountSelection(),
        getIdentity(),
        getCountries(),
        getRegistrationOptions(),
      ]);

      const submit = async (formValues: FormValues): Promise<string> => {
        const originZone = getZoneFromSite(site);
        const advertiser: NewAdvertiser = {
          businessName: formValues.businessName,
          businessPhoneNumber: formValues.phoneNumber,
          country: formValues.country,
          isAgency: formValues.isAgency,
          originZone,
          taxIdentificationNumber: formValues.taxIdentificationNumber,
          businessIdentifier: formValues.businessIdentifier,
        };

        if (registrationStatus.userExists) {
          return registerAdditionalAdvertiser({
            advertiser,
          });
        }
        const user: NewUser = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          originZone,
        };
        const returnUri = urlResolver({ path: URL_CREATE_NEW_JOB });

        const registerPayload = await registerNewUser({
          advertiser,
          user,
          returnUri,
          language: locale,
        });
        return registerPayload.redirectUrl;
      };

      const numberOfAccounts = accountSelectionOptions?.totalCount ?? 0;

      setState({
        loading: false,
        ...registrationStatus,
        accountSelectionOptions,
        identity,
        countries,
        registrationOptions,
        submit,
        numberOfAccounts,
      });
    };

    fetchGql().catch((_err) => {});
  }, [site, urlResolver, locale]);

  return (
    <RegistrationPageContext.Provider value={{ ...state }}>
      {children}
    </RegistrationPageContext.Provider>
  );
};

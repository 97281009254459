import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  LanguageCodeIso: { input: any; output: any };
  SafeInt: { input: any; output: any };
}

export interface AccessCvInput {
  advertiserEmail: Scalars['String']['input'];
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int']['input'];
}

export interface AccessEmailReceiverInput {
  /** User ID only used for logging/audit purposes */
  auditUserId: Scalars['String']['input'];
  /** Email of the user */
  email: Scalars['String']['input'];
  /** First name of the user */
  firstName: Scalars['String']['input'];
}

export enum AccessRightFilter {
  ACTIVE = 'ACTIVE',
  ADMIN_ROLE = 'ADMIN_ROLE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  USER_ROLE = 'USER_ROLE',
}

export enum AccessRightStatus {
  /** User association with advertiser is active. */
  ACTIVE = 'ACTIVE',
  /** User association with advertiser has been deactivated (equivalent to deleted). */
  DEACTIVATED = 'DEACTIVATED',
  /** Awaiting email verification. */
  PENDING = 'PENDING',
}

export enum AccountActivationStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export interface AccountBillingAddressInput {
  /** Address line of where the Advertiser would like billings to go to */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser would like their billing to go to. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser would like billings to go to */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser would like billings to go to */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser would like billings to go to */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser would like billings to go to.
   * Can be NULL if their billing address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser would like billings to go to */
  suburb: Scalars['String']['input'];
}

export interface AccountBillingEmailAddressInput {
  /** Email address to send invoices, outstanding balance notifications etc. */
  billingEmail: Scalars['String']['input'];
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID used in Hirer Accounts API.
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountDetailsUpdateResponse {
  __typename?: 'AccountDetailsUpdateResponse';
  /** Response message we receive from calling the account/details API */
  message?: Maybe<Scalars['String']['output']>;
}

export interface AccountHierarchyOption {
  __typename?: 'AccountHierarchyOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['String']['output'];
}

export interface AccountPostalAddressInput {
  /** Address line of where the Advertiser resides */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address line of where the advertiser resides. Can be left blank if it is not needed */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Address lines of where the Advertiser resides */
  addressLines?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Country code of where the Advertiser resides */
  countryCodeAlpha2: Scalars['String']['input'];
  /** Postcode in which the Advertiser resides */
  postCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
  /**
   * State in which the Advertiser resides
   * Can be NULL if their postal address is a country outside of Australia
   */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Suburb in which the Advertiser resides */
  suburb: Scalars['String']['input'];
  /** Boolean to signify if the user would the postal address to be the same as the billing address */
  useForBillingAddress: Scalars['Boolean']['input'];
}

export interface AccountPrimaryContactInput {
  /** Email of the person we are assigning to be the primary contact */
  email: Scalars['String']['input'];
  /** First name of the person we are assigning to be the primary contact */
  firstName: Scalars['String']['input'];
  /** Last name of the person we are assigning to be the primary contact */
  lastName: Scalars['String']['input'];
  /** Phone number of the person we are assigning to be the primary contact */
  phone: Scalars['String']['input'];
  /**
   * Seek billing account ID used by CRM
   * @deprecated Internally resolved via auth
   */
  seekBillingAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Seek ID
   * @deprecated Internally resolved via auth
   */
  seekId?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountSelectionEdge {
  __typename?: 'AccountSelectionEdge';
  node: AdvertiserAccountOption;
}

export interface AccountSelectionOptions {
  __typename?: 'AccountSelectionOptions';
  /** List of advertiser accounts to which a user has access. */
  accounts?: Maybe<AdvertiserAccountOption[]>;
  /** Default account for automatic sign-in, bypassing account selection. */
  defaultAccount?: Maybe<AdvertiserAccountOption>;
}

export interface AccountSelectionQueryInput {
  /** Return uri for successful account switching. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export interface AccountSelectionResults {
  __typename?: 'AccountSelectionResults';
  defaultAccount?: Maybe<AdvertiserAccountOption>;
  edges: AccountSelectionEdge[];
  totalCount: Scalars['Int']['output'];
}

export type AccountSelectionV2Response =
  | AccountSelectionResults
  | ResponseError;

export interface ActionLink {
  __typename?: 'ActionLink';
  viewProfile?: Maybe<Scalars['String']['output']>;
}

export interface Actor {
  __typename?: 'Actor';
  /** Email address of the actor. */
  email: Scalars['String']['output'];
  /** The Auth0 user id of the actor, eg 'samlp|seek-okta-global|tkilvington@seek.com.au'. */
  id: Scalars['String']['output'];
}

export interface ActorContext {
  __typename?: 'ActorContext';
  /** Url to toggle customer service mode. */
  csToggleHref: Scalars['String']['output'];
  /**
   * Text of CS toggle button.
   * @deprecated Text defined in client for translations purposes. Can use isCsView.
   */
  csToggleText?: Maybe<Scalars['String']['output']>;
  /** Whether the actor is in Customer Service View. */
  isCsView: Scalars['Boolean']['output'];
  /** Email address of the CS user. */
  operatorEmail: Scalars['String']['output'];
}

export type Ad = Product & {
  __typename?: 'Ad';
  adType: AdType;
  /** To show or hide the price */
  hidePrice: Scalars['Boolean']['output'];
  metadata: AdMetaData;
  name: Scalars['String']['output'];
  price?: Maybe<Price>;
  productType: ProductType;
};

export type AdBudget = Product & {
  __typename?: 'AdBudget';
  ads: AdBudgetType;
  id: Scalars['ID']['output'];
  metadata: AdBudgetMetadata;
  name: Scalars['String']['output'];
  productType: ProductType;
  value: Money;
};

export interface AdBudgetAttributes {
  /** Id of selected Ad Budget. */
  budgetId: Scalars['ID']['input'];
}

export interface AdBudgetMetadata {
  __typename?: 'AdBudgetMetadata';
  /** The ad posting frequency of budgets */
  adPostingFrequency: Scalars['String']['output'];
  /** Length of the contract in months */
  discountDurationMonths: Scalars['Int']['output'];
  /** Title used when displaying this product as a line item of a purchase */
  displayItemTitle: Scalars['String']['output'];
  /** Set to true when a discount is offered on standOut ads */
  hasStandOutDiscount: Scalars['Boolean']['output'];
  /** Approximate amount to convey the savings on ads as a percentage */
  percentageSavings: Scalars['Float']['output'];
}

export type AdBudgetProduct = CatalogueProduct & {
  __typename?: 'AdBudgetProduct';
  /** Display label for Ad posting frequency. Eg Occasional, Regular, Frequent. */
  adPostingFrequency: Scalars['String']['output'];
  /** Display label for Ad posting range. */
  adRange: Range;
  /** Features of the adbudget product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Unique ID of the adbudget product */
  id: Scalars['ID']['output'];
  /** name of the adbudget product */
  name: Scalars['String']['output'];
  /** Price of the adbudget product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** List of short labels describing products that can be purchased from the budget */
  purchasableProducts: PurchasableProducts;
};

export interface AdBudgetProductList {
  __typename?: 'AdBudgetProductList';
  /** A list of ad budget products */
  items: AdBudgetProduct[];
}

export interface AdBudgetProductsForUpsellInput {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** List of product ids */
  productIds: Array<Scalars['ID']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
}

export type AdBudgetProductsForUpsellResponse =
  | AdBudgetProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export enum AdBudgetType {
  BRANDED_ONWARDS = 'BRANDED_ONWARDS',
  CLASSIC_ONWARDS = 'CLASSIC_ONWARDS',
  PREMIUM_ONLY = 'PREMIUM_ONLY',
}

export interface AdBudgetsByAdvertiserInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * ISO Alpha-2 country code based on the advertiser's billing address.
   * This is useful to ensure we surface the budgets matching the hirer's location
   * and we may not have their billing address in the system yet. Eg: when new NZ hirers
   * who register and create a Job at the same time on AU website. The NZ budgets will be
   * surfaced on AU site as upsell only if this param is populated by client.
   */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
}

export interface AdMetaData {
  __typename?: 'AdMetaData';
  description?: Maybe<Scalars['String']['output']>;
  extendsAdType?: Maybe<AdType>;
  features?: Maybe<Array<Maybe<ProductFeature>>>;
  highlightText?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<SubTitle>;
  theme?: Maybe<AdTypeTheme>;
  title: Scalars['String']['output'];
}

export interface AdPerformanceReportResponse {
  __typename?: 'AdPerformanceReportResponse';
  hirerId: Scalars['Int']['output'];
  hirerOriginZone: Scalars['String']['output'];
}

export interface AdPerformanceReportWidgetInput {
  jobId: Scalars['String']['input'];
}

export type AdProduct = CatalogueProduct & {
  __typename?: 'AdProduct';
  /** Product information to display to customers */
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  /** @deprecated Avoid use of this field and consider displayMetadata.name to display for customers, or use 'features' to make decisions instead */
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  pricing?: Maybe<Pricing>;
  /** Upgrade paths available for the product */
  upgradePaths: UpgradePath[];
};

export interface AdProductexpectationForDraftArgs {
  draftId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductexpectationForJobArgs {
  jobId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductDisplayMetadata {
  __typename?: 'AdProductDisplayMetadata';
  /** Image to help describe an upgrade product */
  image?: Maybe<Image>;
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']['output']>;
  /** Description of upgrade product price */
  priceDescription?: Maybe<Scalars['String']['output']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']['output']>;
  /** Url to redirect to the product booking form */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** Selling points or features of a product */
  sellingPoints?: Maybe<SellingPoint[]>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']['output']>;
}

export type AdProductSetResponse =
  | DisplayMetadataError
  | ProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export type AdProductUpgrade = CatalogueProduct & {
  __typename?: 'AdProductUpgrade';
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
  /** Expectations for a draft */
  expectationForDraft?: Maybe<Expectation>;
  /** Expectations for a job */
  expectationForJob?: Maybe<Expectation>;
  features: Array<Maybe<CatalogueProductFeature>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: CatalogueProductPrice;
  /** Upgrade paths available for the product */
  upgradePaths: UpgradePath[];
};

export interface AdProductUpgradeexpectationForDraftArgs {
  draftId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductUpgradeexpectationForJobArgs {
  jobId?: InputMaybe<Scalars['String']['input']>;
}

export interface AdProductsForAdPostingInput {
  /**
   * Id of the advertiser.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
}

export interface AdRatingLabels {
  __typename?: 'AdRatingLabels';
  groupLabel?: Maybe<Scalars['String']['output']>;
  locationLabel?: Maybe<Scalars['String']['output']>;
}

export interface AdRatingResponse {
  __typename?: 'AdRatingResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  adRatingScore?: Maybe<AnalyticsAdRatingScore>;
  applications: Scalars['Int']['output'];
  applicationsBenchmarkHigh?: Maybe<Scalars['Int']['output']>;
  applicationsBenchmarkLow?: Maybe<Scalars['Int']['output']>;
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  incompleteApplications: Scalars['Int']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  lastUpdatedTimestampUTC: Scalars['DateTime']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export enum AdRatingType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  TOO_SOON_TO_RATE = 'TOO_SOON_TO_RATE',
  UNABLE_TO_RATE = 'UNABLE_TO_RATE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface AdRatingTypeOption {
  __typename?: 'AdRatingTypeOption';
  count: Scalars['Int']['output'];
  key: FiltersAdRatingType;
}

export interface AdRatingWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export enum AdRepost {
  NOT_REPOST = 'NOT_REPOST',
  REPOST = 'REPOST',
}

/** AdBudgetProduct feature to indicate ad spend allowance */
export type AdSpendFeature = CatalogueProductFeature & {
  __typename?: 'AdSpendFeature';
  /** Money allowance to purchase ad products */
  allowance: Money;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface AdStatusOption {
  __typename?: 'AdStatusOption';
  count: Scalars['Int']['output'];
  key: AdUsageAdStatus;
}

export interface AdTipsOption {
  __typename?: 'AdTipsOption';
  count: Scalars['Int']['output'];
  key: AdTipsType;
}

export enum AdTipsType {
  ADD_QUESTIONS = 'ADD_QUESTIONS',
  ADD_SPECIFIC_LOCATION = 'ADD_SPECIFIC_LOCATION',
  INCREASE_SALARY = 'INCREASE_SALARY',
  MAXIMISE_BRANDING = 'MAXIMISE_BRANDING',
  NO_TIPS = 'NO_TIPS',
  REVEAL_SALARY = 'REVEAL_SALARY',
  REVIEW_AD_DETAILS = 'REVIEW_AD_DETAILS',
  REVIEW_AD_SUMMARY = 'REVIEW_AD_SUMMARY',
}

export enum AdType {
  BRANDED = 'BRANDED',
  BRANDED_UPGRADE = 'BRANDED_UPGRADE',
  CLASSIC = 'CLASSIC',
  CONCIERGE = 'CONCIERGE',
  GUARANTEED = 'GUARANTEED',
  PREMIUM = 'PREMIUM',
}

export interface AdTypeAverageCost {
  __typename?: 'AdTypeAverageCost';
  cost: Money;
  type: Scalars['String']['output'];
}

/** Discount for Ad Product by legacy AdType */
export interface AdTypeDiscount {
  __typename?: 'AdTypeDiscount';
  /** Type of the ad. Eg CLASSIC, BRANDED, PREMIUM */
  adType: AdType;
  /** Discount in absolute money amount or percentage */
  discount: Discount;
}

/** AdBudgetProduct feature to determine discounts for Ad products. */
export type AdTypeDiscountsFeature = CatalogueProductFeature & {
  __typename?: 'AdTypeDiscountsFeature';
  /** Discounts applicable to the different Ad Products */
  discounts: AdTypeDiscount[];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface AdTypeOption {
  __typename?: 'AdTypeOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface AdTypePostCount {
  __typename?: 'AdTypePostCount';
  adsCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
}

export enum AdTypeTheme {
  BASIC = 'BASIC',
  PRIMARY = 'PRIMARY',
}

export enum AdUsageAdStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum AdUsageAdType {
  BASIC = 'BASIC',
  BRANDED = 'BRANDED',
  CLASSIC = 'CLASSIC',
  PREMIUM = 'PREMIUM',
  STANDOUT = 'STANDOUT',
}

export interface AdUsageAdTypeTrendInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}

export interface AdUsageAdTypeTrendResponse {
  __typename?: 'AdUsageAdTypeTrendResponse';
  daily: AdUsageTrendDaily[];
  endDate: Scalars['String']['output'];
  monthly: AdUsageTrendMonthly[];
  productDisplayNameDaily: DailyTrendsProductDisplayName[];
  productDisplayNameMonthly: MonthlyTrendsProductDisplayName[];
  startDate: Scalars['String']['output'];
  totalAdTypes?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
  totalProductDisplayNames?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
}

export interface AdUsageConversionResponse {
  __typename?: 'AdUsageConversionResponse';
  adClicks: ConversionRateData;
  adsWithoutRatingPercentage?: Maybe<Scalars['Float']['output']>;
  applicationsCompleted: ConversionRateData;
  applicationsStarted: ConversionRateData;
  jobSearchViews: ConversionRateData;
  linkOutAds: Scalars['Float']['output'];
}

export interface AdUsageConversionWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface AdUsageFiltersResponse {
  __typename?: 'AdUsageFiltersResponse';
  accountHierarchies: Array<Maybe<AccountHierarchyOption>>;
  adIds: Array<Scalars['String']['output']>;
  adRatings: Array<Maybe<AdRatingTypeOption>>;
  adStatus: Array<Maybe<AdStatusOption>>;
  adTypes: Array<Maybe<AdTypeOption>>;
  budgets: Array<Maybe<BudgetOption>>;
  classifications: Array<Maybe<ClassificationOption>>;
  isChildAccount: Scalars['Boolean']['output'];
  jobTitles: Array<Maybe<JobTitleOption>>;
  locations: Array<Maybe<LocationOption>>;
  productDisplayNames: Array<Maybe<ProductDisplayNameOption>>;
  reposts: Array<Maybe<RepostOption>>;
  tips: Array<Maybe<AdTipsOption>>;
  users: Array<Maybe<UserOption>>;
}

export interface AdUsageFiltersWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface AdUsageQueryFilters {
  accountIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adIds: Array<InputMaybe<Scalars['Int']['input']>>;
  adRatings: Array<InputMaybe<FiltersAdRatingType>>;
  adStatuses: Array<InputMaybe<AdUsageAdStatus>>;
  adTips: Array<InputMaybe<AdTipsType>>;
  adTypes: Array<InputMaybe<Scalars['String']['input']>>;
  budgetIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTitles: Array<InputMaybe<Scalars['String']['input']>>;
  locationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  postingDates: AdUsageQueryFiltersDates;
  repostTypes: Array<InputMaybe<AdRepost>>;
  subClassificationIds: Array<InputMaybe<Scalars['Int']['input']>>;
  userHashes: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface AdUsageQueryFiltersDates {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
}

export interface AdUsageSpendResponse {
  __typename?: 'AdUsageSpendResponse';
  averageApplications?: Maybe<Scalars['Int']['output']>;
  averageCostByAdTypes?: Maybe<Array<Maybe<AdTypeAverageCost>>>;
  averageCostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNameAverageCost>>
  >;
  averageCostPerAd?: Maybe<Money>;
  averageCostPerApplication?: Maybe<Money>;
  hidePrice: Scalars['Boolean']['output'];
  otherCurrencyAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsCount?: Maybe<Scalars['Int']['output']>;
  repostedAdsSpend?: Maybe<Money>;
  totalAds: Scalars['Int']['output'];
  totalApplications?: Maybe<Scalars['Int']['output']>;
  totalPostByAdTypes?: Maybe<Array<Maybe<AdTypePostCount>>>;
  totalPostByProductDisplayNames?: Maybe<
    Array<Maybe<ProductDisplayNamePostCount>>
  >;
  totalSpend?: Maybe<Money>;
}

export interface AdUsageSpendWidgetInput {
  filters: AdUsageQueryFilters;
  isAdBudgetFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  siteCurrency?: InputMaybe<Scalars['String']['input']>;
}

export interface AdUsageTrendClassification {
  __typename?: 'AdUsageTrendClassification';
  classificationName: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
}

export interface AdUsageTrendClassificationsAndUsersInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface AdUsageTrendClassificationsAndUsersResponse {
  __typename?: 'AdUsageTrendClassificationsAndUsersResponse';
  classifications: AdUsageTrendClassification[];
  users: AdUsageTrendUser[];
}

export interface AdUsageTrendDaily {
  __typename?: 'AdUsageTrendDaily';
  day: Scalars['String']['output'];
  items?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
}

export interface AdUsageTrendMonthly {
  __typename?: 'AdUsageTrendMonthly';
  items?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
  month: Scalars['String']['output'];
}

export interface AdUsageTrendUser {
  __typename?: 'AdUsageTrendUser';
  percentage: Scalars['Float']['output'];
  totalAds: Scalars['Int']['output'];
  userFullname?: Maybe<Scalars['String']['output']>;
}

export type AddQuestions = TipToImproveAds & {
  __typename?: 'AddQuestions';
  adCount: Scalars['Int']['output'];
  questions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: TipsToImproveAdsType;
};

export type AddSpecificLocation = TipToImproveAds & {
  __typename?: 'AddSpecificLocation';
  adCount: Scalars['Int']['output'];
  nonSpecificLocation: Scalars['String']['output'];
  specificLocation: Scalars['String']['output'];
  type: TipsToImproveAdsType;
};

export interface AddUserToAccountInput {
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['input'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['input'];
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone: Scalars['String']['input'];
}

export interface AddUserToAccountResponse {
  __typename?: 'AddUserToAccountResponse';
  /** The create date of the newly created user */
  createdDateUtc: Scalars['String']['output'];
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String']['output'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String']['output'];
  lastDailyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String']['output'];
  lastWeeklyCandidateMailDateUtc?: Maybe<Scalars['String']['output']>;
  links: Link[];
  /** Current status ID of the newly created user */
  statusId: Scalars['String']['output'];
  /** User ID of the newly created user */
  userId: Scalars['String']['output'];
}

export interface AdvertiserAccountOption {
  __typename?: 'AdvertiserAccountOption';
  /** Status of access right connecting the user to the advertiser. */
  accessRightStatus: AccessRightStatus;
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID']['output'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /** Child accounts, applicable to agencies. Will be visually nested during account selection. */
  childAccounts: AdvertiserAccountOption[];
  /** The Hirer Account Status */
  hirerAccountStatus: AdvertiserStatus;
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** The role of the user on the account - Either "USER" (standard) or "ADMINISTRATOR" */
  role: Role;
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID']['output'];
}

export interface AdvertiserHeaderContext {
  __typename?: 'AdvertiserHeaderContext';
  /** Billing ID */
  billingId: Scalars['String']['output'];
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the user has multiple accounts. Should really be part of the user payload :thinking-face: */
  hasMultipleAccounts: Scalars['Boolean']['output'];
  /** Indicates whether the user's activation is pending for this account. */
  isActivationPending: Scalars['Boolean']['output'];
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Permission[]>;
  /** Indicates whether the account is on credit hold. */
  showOnCreditHoldMessage: Scalars['Boolean']['output'];
  /** Url to switch accounts. Will be null if user has a single account. */
  switchAccountsHref?: Maybe<Scalars['String']['output']>;
}

export enum AdvertiserStatus {
  /** An active advertiser. */
  ACTIVE = 'ACTIVE',
  /** A deactivated advertiser. */
  DEACTIVATED = 'DEACTIVATED',
  /** A freshly migrated/created advertiser. */
  NEW = 'NEW',
  /** Awaiting primary contact registration. */
  PENDING = 'PENDING',
  /** Awaiting advertiser account security link activation. */
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export interface AhpraRegistration {
  __typename?: 'AhpraRegistration';
  profession: Scalars['String']['output'];
  specialty: Scalars['String']['output'];
  subType: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface AnalyticsAdRating {
  __typename?: 'AnalyticsAdRating';
  score?: Maybe<AnalyticsAdRatingScore>;
  status: AnalyticsAdRatingStatus;
}

export enum AnalyticsAdRatingConfidence {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum AnalyticsAdRatingScore {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
}

export enum AnalyticsAdRatingStatus {
  ARCHIVED = 'ARCHIVED',
  AVAILABLE_CANNOT_RATE = 'AVAILABLE_CANNOT_RATE',
  AVAILABLE_PENDING_RATING = 'AVAILABLE_PENDING_RATING',
  AVAILABLE_WITH_RATING = 'AVAILABLE_WITH_RATING',
  ERRORED = 'ERRORED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  PENDING = 'PENDING',
  UNAVAILABLE = 'UNAVAILABLE',
  WITHHELD = 'WITHHELD',
}

export enum AnalyticsAdType {
  AUTO_INCLUDE = 'AUTO_INCLUDE',
  BASIC = 'BASIC',
  BRANDED = 'BRANDED',
  CLASSIC = 'CLASSIC',
  PREMIUM = 'PREMIUM',
  STANDOUT = 'STANDOUT',
}

export enum AnalyticsBenchmarkScenario {
  COUNTRY = 'COUNTRY',
  EXACT = 'EXACT',
  EXACT_COUNTRY = 'EXACT_COUNTRY',
  EXACT_REGIONAL = 'EXACT_REGIONAL',
  IDEAL = 'IDEAL',
  IDEAL_COUNTRY = 'IDEAL_COUNTRY',
  IDEAL_REGIONAL = 'IDEAL_REGIONAL',
  SUBCLASS_COUNTRY = 'SUBCLASS_COUNTRY',
  SUBCLASS_LOCATION = 'SUBCLASS_LOCATION',
  SUBCLASS_REGIONAL = 'SUBCLASS_REGIONAL',
}

export interface AnalyticsLatestJobs {
  __typename?: 'AnalyticsLatestJobs';
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  userFullName?: Maybe<Scalars['String']['output']>;
}

export enum AnalyticsPostTypeCategory {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
}

export enum AnalyticsSalaryType {
  ANNUAL_COMMISSION = 'ANNUAL_COMMISSION',
  ANNUAL_PACKAGE = 'ANNUAL_PACKAGE',
  COMMISSION_ONLY = 'COMMISSION_ONLY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
}

export interface AnalyticsTalentSearchUsageAccount {
  __typename?: 'AnalyticsTalentSearchUsageAccount';
  count: Scalars['Int']['output'];
  data: AnalyticsTalentSearchUsageAccountData;
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
}

export interface AnalyticsTalentSearchUsageAccountData {
  __typename?: 'AnalyticsTalentSearchUsageAccountData';
  displayId: Scalars['ID']['output'];
}

export interface AnalyticsTalentSearchUsageAccountTrendsItem {
  __typename?: 'AnalyticsTalentSearchUsageAccountTrendsItem';
  advertiserName: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageConversionInput {
  filters: AnalyticsTalentSearchUsageFilters;
}

export interface AnalyticsTalentSearchUsageConversionResponse {
  __typename?: 'AnalyticsTalentSearchUsageConversionResponse';
  cvDownloadCount: Scalars['Int']['output'];
  cvPreviewCount: Scalars['Int']['output'];
  openJobCount: Scalars['Int']['output'];
  openJobPercentage: Scalars['Int']['output'];
  openMessageCount: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageDateRangeInput {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageFilters {
  accountIds: Array<InputMaybe<Scalars['ID']['input']>>;
  usageDates: AnalyticsTalentSearchUsageDateRangeInput;
  userHashes: Array<InputMaybe<Scalars['ID']['input']>>;
}

export interface AnalyticsTalentSearchUsageFiltersInput {
  filters: AnalyticsTalentSearchUsageFilters;
  locale: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageFiltersResponse {
  __typename?: 'AnalyticsTalentSearchUsageFiltersResponse';
  accounts: Array<Maybe<AnalyticsTalentSearchUsageAccount>>;
  users: Array<Maybe<AnalyticsTalentSearchUsageUser>>;
}

export interface AnalyticsTalentSearchUsageLastUpdatedResponse {
  __typename?: 'AnalyticsTalentSearchUsageLastUpdatedResponse';
  lastUpdated: Scalars['DateTime']['output'];
}

export interface AnalyticsTalentSearchUsageOverviewInput {
  filters: AnalyticsTalentSearchUsageFilters;
  siteCurrency: Scalars['String']['input'];
}

export interface AnalyticsTalentSearchUsageOverviewResponse {
  __typename?: 'AnalyticsTalentSearchUsageOverviewResponse';
  cvDownloadCount: Scalars['Int']['output'];
  cvDownloadCredits?: Maybe<Scalars['Int']['output']>;
  cvDownloadPercentage: Scalars['Int']['output'];
  cvDownloadSpendPer: Money;
  otherCurrencyCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendJobPercentage: Scalars['Int']['output'];
  sendJobSpendPer: Money;
  sendMessageCount: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
  sendMessagePercentage: Scalars['Int']['output'];
  sendMessageSpendPer: Money;
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  totalSpend: Money;
  userCount: Scalars['Int']['output'];
  userSpendAverage: Money;
}

export interface AnalyticsTalentSearchUsageTimeTrendsItem {
  __typename?: 'AnalyticsTalentSearchUsageTimeTrendsItem';
  cvDownloadCredits: Scalars['Int']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageTimeTrendsItemByInterval {
  __typename?: 'AnalyticsTalentSearchUsageTimeTrendsItemByInterval';
  cvDownloadCredits: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  sendJobCredits: Scalars['Int']['output'];
  sendMessageCredits: Scalars['Int']['output'];
}

export interface AnalyticsTalentSearchUsageTrendsByAccountInput {
  filters: AnalyticsTalentSearchUsageFilters;
}

export interface AnalyticsTalentSearchUsageTrendsByAccountResponse {
  __typename?: 'AnalyticsTalentSearchUsageTrendsByAccountResponse';
  accounts: AnalyticsTalentSearchUsageAccountTrendsItem[];
}

export interface AnalyticsTalentSearchUsageTrendsByTimeInput {
  filters: AnalyticsTalentSearchUsageFilters;
  interval: AnalyticsTalentSearchUsageTrendsByTimeInterval;
  timezone: Scalars['String']['input'];
}

export enum AnalyticsTalentSearchUsageTrendsByTimeInterval {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export interface AnalyticsTalentSearchUsageTrendsByTimeResponse {
  __typename?: 'AnalyticsTalentSearchUsageTrendsByTimeResponse';
  data: AnalyticsTalentSearchUsageTimeTrendsItemByInterval[];
  total: AnalyticsTalentSearchUsageTimeTrendsItem;
}

export interface AnalyticsTalentSearchUsageUser {
  __typename?: 'AnalyticsTalentSearchUsageUser';
  count: Scalars['Int']['output'];
  key: Scalars['ID']['output'];
  value: Scalars['String']['output'];
}

export interface AnalyticsTalentSearchUsageUserUsage {
  __typename?: 'AnalyticsTalentSearchUsageUserUsage';
  cvDownloadCount: Scalars['Int']['output'];
  openJobPercentage: Scalars['Int']['output'];
  openMessagePercentage: Scalars['Int']['output'];
  profileSearchCount: Scalars['Int']['output'];
  profileViewCount: Scalars['Int']['output'];
  sendJobCount: Scalars['Int']['output'];
  sendMessageCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalCredits: Scalars['Int']['output'];
  userFullName: Scalars['String']['output'];
  userHash: Scalars['ID']['output'];
}

export interface AnalyticsTalentSearchUsageUsersListInput {
  filters: AnalyticsTalentSearchUsageFilters;
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
}

export interface AnalyticsTalentSearchUsageUsersListResponse {
  __typename?: 'AnalyticsTalentSearchUsageUsersListResponse';
  list: AnalyticsTalentSearchUsageUserUsage[];
  userCount: Scalars['Int']['output'];
}

export enum AnalyticsWorkType {
  CASUAL = 'CASUAL',
  CONTRACT = 'CONTRACT',
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
}

export interface ApplicationAttachment {
  __typename?: 'ApplicationAttachment';
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPdf: Scalars['Boolean']['output'];
  type: Scalars['Int']['output'];
  typeName: Scalars['String']['output'];
  uploadedDate?: Maybe<Scalars['DateTime']['output']>;
  uploadedUser?: Maybe<Scalars['String']['output']>;
}

export interface ApplicationAttachmentInput {
  prospectId: Scalars['ID']['input'];
}

export interface ApplicationAttachments {
  __typename?: 'ApplicationAttachments';
  nodes: ApplicationAttachment[];
}

export enum ApplicationBucket {
  ACCEPT = 'ACCEPT',
  INBOX = 'INBOX',
  INTERVIEW = 'INTERVIEW',
  NOT_SUITABLE = 'NOT_SUITABLE',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  SHORTLIST = 'SHORTLIST',
}

export interface ApplicationData {
  /** The position the prospect appeared in the candidate list starting from 1. Null if sent from CandidateDetail. */
  positionInList?: InputMaybe<Scalars['Int']['input']>;
  prospectId: Scalars['ID']['input'];
}

export interface ApplicationEmail {
  __typename?: 'ApplicationEmail';
  adcentreCandidateId: Scalars['ID']['output'];
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  prospectId: Scalars['ID']['output'];
  sentDate: Scalars['DateTime']['output'];
  subject: Scalars['String']['output'];
  to: Scalars['String']['output'];
}

export interface ApplicationEmailEdge {
  __typename?: 'ApplicationEmailEdge';
  node: ApplicationEmail;
}

export interface ApplicationEmails {
  __typename?: 'ApplicationEmails';
  edges: ApplicationEmailEdge[];
}

export interface ApplicationEmailsInput {
  adcentreCandidateId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface ApplicationNote {
  __typename?: 'ApplicationNote';
  content?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  createdUser?: Maybe<Scalars['String']['output']>;
  createdUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
}

export interface ApplicationNotes {
  __typename?: 'ApplicationNotes';
  nodes: ApplicationNote[];
}

export interface ApplicationPerformanceResponse {
  __typename?: 'ApplicationPerformanceResponse';
  adRatingConfidence: AnalyticsAdRatingConfidence;
  applications: Scalars['Int']['output'];
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  similarAdsInPercentage?: Maybe<Scalars['Float']['output']>;
}

export interface ApplicationPerformanceWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface ApplicationRating {
  __typename?: 'ApplicationRating';
  rating: Scalars['Int']['output'];
}

export enum ApplicationStatusFolder {
  ACCEPT = 'ACCEPT',
  INBOX = 'INBOX',
  INTERVIEW = 'INTERVIEW',
  NOT_SUITABLE = 'NOT_SUITABLE',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  SHORTLIST = 'SHORTLIST',
}

export interface ApplicationsFacetsInput {
  filters?: InputMaybe<ApplicationsFiltersInput>;
  jobId: Scalars['ID']['input'];
}

export type ApplicationsFacetsResponse =
  | ApplicationsFacetsResponseSuccess
  | ResponseError;

export interface ApplicationsFacetsResponseSuccess {
  __typename?: 'ApplicationsFacetsResponseSuccess';
  result: ApplicationsFacetsResult;
}

export interface ApplicationsFacetsResult {
  __typename?: 'ApplicationsFacetsResult';
  homeLocationFacets: ApplicationsFacetsResultHomeLocationFacet[];
}

export interface ApplicationsFacetsResultHomeLocationFacet {
  __typename?: 'ApplicationsFacetsResultHomeLocationFacet';
  children: ApplicationsFacetsResultHomeLocationFacet[];
  count: Scalars['Int']['output'];
  homeLocation: Location;
}

export interface ApplicationsFiltersInput {
  homeLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionnaireFilters?: InputMaybe<QuestionnaireFilter[]>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statusFolders?: InputMaybe<ApplicationStatusFolder[]>;
}

export interface ApprovedIntegrationRelationship {
  __typename?: 'ApprovedIntegrationRelationship';
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  relationship: IntegrationRelationshipType;
}

export interface AssociatedAction {
  __typename?: 'AssociatedAction';
  code: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  job?: Maybe<InteractedJob>;
  subject?: Maybe<Scalars['String']['output']>;
}

export interface AssociatedParty {
  __typename?: 'AssociatedParty';
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
}

export interface AuditSuccessfulLoginPayload {
  __typename?: 'AuditSuccessfulLoginPayload';
  success: Scalars['Boolean']['output'];
}

/**
 * Country ID - ['bca', 'bni', 'bri', 'cimb', 'mandiri']
 * Country TH - ['kbank', 'scb', 'bbl', 'ktb', 'bay', 'tmb', 'uob']
 * Country PH - ['bpi', 'unionbank']
 */
export enum Bank2C2P {
  bay = 'bay',
  bbl = 'bbl',
  bca = 'bca',
  bni = 'bni',
  bpi = 'bpi',
  bri = 'bri',
  cimb = 'cimb',
  kbank = 'kbank',
  ktb = 'ktb',
  mandiri = 'mandiri',
  scb = 'scb',
  tmb = 'tmb',
  unionbank = 'unionbank',
  uob = 'uob',
}

export interface BillableApplicantsData {
  __typename?: 'BillableApplicantsData';
  count: Scalars['Int']['output'];
}

export type BillableApplicantsResponse =
  | BillableApplicantsResponseSuccess
  | ResponseError;

export interface BillableApplicantsResponseSuccess {
  __typename?: 'BillableApplicantsResponseSuccess';
  data?: Maybe<BillableApplicantsData>;
}

export interface BillingContact {
  __typename?: 'BillingContact';
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
}

export enum BillingStatus {
  OK = 'OK',
  ON_CREDIT_HOLD = 'ON_CREDIT_HOLD',
}

export interface Brand {
  __typename?: 'Brand';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  /** @deprecated Please use Brand.owner instead. */
  ownerId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<BrandState>;
}

export type BrandAssetStrategies = CoverStrategies | LogoStrategies;

export interface BrandAssetType {
  __typename?: 'BrandAssetType';
  id: Scalars['String']['output'];
  strategies: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
}

export enum BrandAssetTypeEnum {
  cover = 'cover',
  logo = 'logo',
}

export interface BrandAssetTypeInput {
  id: Scalars['String']['input'];
  strategies: Scalars['JSON']['input'];
  url: Scalars['String']['input'];
}

export interface BrandAssets {
  __typename?: 'BrandAssets';
  cover?: Maybe<BrandAssetType>;
  logo: BrandAssetType;
}

export interface BrandAssetsInput {
  cover?: InputMaybe<BrandAssetTypeInput>;
  logo: BrandAssetTypeInput;
}

export interface BrandOwner {
  __typename?: 'BrandOwner';
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BrandOwnerType>;
}

export interface BrandOwnerInput {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrandOwnerType>;
}

export enum BrandOwnerType {
  advertiserId = 'advertiserId',
  seekId = 'seekId',
}

export enum BrandState {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export interface BrandedAd {
  __typename?: 'BrandedAd';
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: RecommendationType;
}

/**
 * AdBudgetProduct feature to indicate budget is allowed to purchase ads of branded tier and onwards.
 * Also known as 'BrandedOnwards' in legacy terms.
 */
export type BrandedFeaturesFeature = CatalogueProductFeature & {
  __typename?: 'BrandedFeaturesFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface BrandingAsset {
  __typename?: 'BrandingAsset';
  /** @deprecated Non translated string. Will return english only. */
  deleteWarning?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']['output']>;
  isOnDefaultBrand?: Maybe<Scalars['Boolean']['output']>;
  numberOfBrands?: Maybe<Scalars['Int']['output']>;
  numberOfLiveAds?: Maybe<Scalars['Int']['output']>;
  strategies: BrandAssetStrategies;
  url: Scalars['String']['output'];
}

/** AdProductUpgrade feature that indicates branding properties available for ad product */
export type BrandingFeature = CatalogueProductFeature & {
  __typename?: 'BrandingFeature';
  /** @deprecated borderForSearchListing is deprecated. Use StyleEnhancementFeature instead. */
  borderForSearchListing: Scalars['Boolean']['output'];
  /**
   * Number of bullet points available on job ad. Only positive numbers allowed.
   * @deprecated bulletPoints is deprecated. Use BulletPointsFeature instead.
   */
  bulletPoints: Scalars['Int']['output'];
  /** Indicates job ad can have cover image */
  hasCoverImage: Scalars['Boolean']['output'];
  /** Indicates job ad can have a logo */
  hasLogo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mask: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface BrandingInput {
  brandId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}

export interface BudgetOption {
  __typename?: 'BudgetOption';
  count: Scalars['Int']['output'];
  data: BudgetOptionInfo;
  key: Scalars['String']['output'];
}

export interface BudgetOptionInfo {
  __typename?: 'BudgetOptionInfo';
  expiryTimestampUTC?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

/** Product feature that indicates bullet points for an existing ad. */
export type BulletPointsFeature = CatalogueProductFeature & {
  __typename?: 'BulletPointsFeature';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum CandidateAnswerType {
  FreeText = 'FreeText',
  SingleSelect = 'SingleSelect',
}

export interface CandidateDemandResponse {
  __typename?: 'CandidateDemandResponse';
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateDemand: Scalars['Boolean']['output'];
}

export interface CandidateDemandWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export enum CandidateLabel {
  APPLICATION_STARTS = 'APPLICATION_STARTS',
  CANDIDATES = 'CANDIDATES',
}

export interface CandidateSupplyResponse {
  __typename?: 'CandidateSupplyResponse';
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateSupply: Scalars['Boolean']['output'];
}

export interface CandidateSupplyWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface CappedUnitPrice {
  __typename?: 'CappedUnitPrice';
  cappedPrice: CatalogueProductPrice;
  maxBillableUnits: Scalars['Int']['output'];
  metadata: CappedUnitPriceMetadata;
  type: Scalars['String']['output'];
  unitPrice: CatalogueProductPrice;
}

export interface CappedUnitPriceMetadata {
  __typename?: 'CappedUnitPriceMetadata';
  unitType: Scalars['String']['output'];
}

export interface CatalogueProduct {
  /** Features of the catalogue product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Unique ID of the catalogue product */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product */
  name: Scalars['String']['output'];
  /** Price of the catalogue product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
}

export interface CatalogueProductAttributes {
  jobId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  productSetId: Scalars['String']['input'];
  /** TODO: what if the set doesn't contain any JobAds? it is optional */
  revisionId?: InputMaybe<Scalars['String']['input']>;
}

export interface CatalogueProductFeature {
  /** Unique ID of the catalogue product feature */
  id: Scalars['ID']['output'];
  /** Name of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  name: Scalars['String']['output'];
  /** Type of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  type: Scalars['String']['output'];
}

export type CatalogueProductPrice = HiddenPrice | Price;

export interface CategoriesInput {
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
}

export interface Category {
  __typename?: 'Category';
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  subCategoryId: Scalars['Int']['output'];
  subCategoryName: Scalars['String']['output'];
}

export interface CategorySuggestion {
  __typename?: 'CategorySuggestion';
  category: Category;
  probability: Scalars['Float']['output'];
}

export enum ChannelCode {
  ALIPAYHK = 'ALIPAYHK',
  BILLEASE = 'BILLEASE',
  DANA = 'DANA',
  GCASH = 'GCASH',
  GRAB = 'GRAB',
  HKFPSQR = 'HKFPSQR',
  HOMECREDIT = 'HOMECREDIT',
  LINKAJA = 'LINKAJA',
  OVO = 'OVO',
  PAYMAYA = 'PAYMAYA',
  PAYPAL = 'PAYPAL',
  SHPQR = 'SHPQR',
}

export interface ChildAccountBannerResponse {
  __typename?: 'ChildAccountBannerResponse';
  isChild: Scalars['Boolean']['output'];
}

export interface Claim {
  __typename?: 'Claim';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface ClassificationOption {
  __typename?: 'ClassificationOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  subClassifications?: Maybe<ClassificationOption[]>;
  value: Scalars['String']['output'];
}

export interface ClicksOnYourAdResponse {
  __typename?: 'ClicksOnYourAdResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  appConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  isLinkOutAd: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileClicksInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  similarAdsInPercentage?: Maybe<Scalars['Float']['output']>;
  totalClicks: Scalars['Int']['output'];
}

export interface ClicksOnYourAdWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface CompanyAddress {
  __typename?: 'CompanyAddress';
  country?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfile {
  __typename?: 'CompanyProfile';
  awards?: Maybe<CompanyProfileAward[]>;
  branding?: Maybe<CompanyProfileBranding>;
  cultureAndValues?: Maybe<CompanyProfileCultureAndValues>;
  customStatement?: Maybe<CompanyProfileCustomStatement>;
  featured?: Maybe<CompanyProfileFeatured>;
  gallery?: Maybe<CompanyProfilePhoto[]>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<CompanyProfileMetadata>;
  missionStatement?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  overview?: Maybe<CompanyProfileOverview>;
  perksAndBenefits?: Maybe<CompanyProfilePerksAndBenefits[]>;
  profilePublic?: Maybe<Scalars['Boolean']['output']>;
  reviews: Reviews;
}

export interface CompanyProfilereviewsArgs {
  page?: Scalars['Int']['input'];
  perPage?: Scalars['Int']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileAward {
  __typename?: 'CompanyProfileAward';
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
}

export interface CompanyProfileAwardInput {
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}

export interface CompanyProfileBranding {
  __typename?: 'CompanyProfileBranding';
  brandId?: Maybe<Scalars['ID']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
}

export interface CompanyProfileBrandingInput {
  brandId?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  logo: Scalars['String']['input'];
}

export interface CompanyProfileCultureAndValues {
  __typename?: 'CompanyProfileCultureAndValues';
  description: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  values?: Maybe<CompanyProfileCultureValue[]>;
}

export interface CompanyProfileCultureAndValuesInput {
  description: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  values?: InputMaybe<CompanyProfileCultureValueInput[]>;
}

export interface CompanyProfileCultureValue {
  __typename?: 'CompanyProfileCultureValue';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
}

export interface CompanyProfileCultureValueInput {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export interface CompanyProfileCustomStatement {
  __typename?: 'CompanyProfileCustomStatement';
  description: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface CompanyProfileCustomStatementInput {
  description: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface CompanyProfileFeatured {
  __typename?: 'CompanyProfileFeatured';
  description?: Maybe<Array<Scalars['String']['output']>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfileFeaturedInput {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileIds {
  __typename?: 'CompanyProfileIds';
  companyIds?: Maybe<Array<Scalars['ID']['output']>>;
}

export interface CompanyProfileInput {
  awards?: InputMaybe<CompanyProfileAwardInput[]>;
  branding?: InputMaybe<CompanyProfileBrandingInput>;
  cultureAndValues?: InputMaybe<CompanyProfileCultureAndValuesInput>;
  customStatement?: InputMaybe<CompanyProfileCustomStatementInput>;
  featured?: InputMaybe<CompanyProfileFeaturedInput>;
  gallery?: InputMaybe<CompanyProfilePhotoInput[]>;
  id: Scalars['ID']['input'];
  missionStatement?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  overview?: InputMaybe<CompanyProfileOverviewInput>;
  perksAndBenefits?: InputMaybe<CompanyProfilePerksAndBenefitsInput[]>;
}

export interface CompanyProfileMetadata {
  __typename?: 'CompanyProfileMetadata';
  profilePublic: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface CompanyProfileOverview {
  __typename?: 'CompanyProfileOverview';
  description?: Maybe<Array<Scalars['String']['output']>>;
  industry?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  specialities?: Maybe<Array<Scalars['String']['output']>>;
  website?: Maybe<CompanyProfileWebsite>;
}

export interface CompanyProfileOverviewInput {
  description?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  primaryLocation?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  specialities?: InputMaybe<Array<Scalars['String']['input']>>;
  website?: InputMaybe<CompanyProfileWebsiteInput>;
}

export interface CompanyProfilePerksAndBenefits {
  __typename?: 'CompanyProfilePerksAndBenefits';
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface CompanyProfilePerksAndBenefitsInput {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
}

export interface CompanyProfilePhoto {
  __typename?: 'CompanyProfilePhoto';
  date?: Maybe<Scalars['Int']['output']>;
  link: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface CompanyProfilePhotoInput {
  date?: InputMaybe<Scalars['Int']['input']>;
  link: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface CompanyProfileWebsite {
  __typename?: 'CompanyProfileWebsite';
  displayName?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface CompanyProfileWebsiteInput {
  displayName?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
}

export interface ConfirmInvoiceOrderInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  billToAgent: Scalars['Boolean']['input'];
  idempotencyKey: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
}

export type ConfirmInvoiceOrderPayload =
  | InvoiceOrderNotFoundPayload
  | InvoiceOrderSuccessPayload
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError;

export interface ConnectionStatusPayload {
  __typename?: 'ConnectionStatusPayload';
  connectionStatus: TalentSearchConnectionStatus;
  creditsRedeemedDateUtc?: Maybe<Scalars['String']['output']>;
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
}

export interface Contract {
  __typename?: 'Contract';
  /** Info on discounts that are available for Ad products, only available for SEEK_CONTRACT types */
  adDiscounts?: Maybe<Array<Maybe<ContractAdDiscount>>>;
  /** The date when contract was created eg. '2019-10-09T11:00:00.000Z' */
  createdDate: Scalars['String']['output'];
  current: Scalars['Boolean']['output'];
  expiry: Expiry;
  /** The date from when contract is consumable eg. '2019-11-01T11:00:00.000Z' */
  validFromDate: Scalars['String']['output'];
  value: ContractValue;
}

export interface ContractAdDiscount {
  __typename?: 'ContractAdDiscount';
  adType: AdType;
  /** Minimum possible discount for an AdType according to the contract and the current variable ad prices */
  minimumPercentageSavings?: Maybe<Scalars['Float']['output']>;
}

export interface ContractBalance {
  __typename?: 'ContractBalance';
  adType: AdType;
  adTypeDescription?: Maybe<Scalars['String']['output']>;
  contractType: ContractType;
  contracts?: Maybe<Contract[]>;
  dollarBased: Scalars['Boolean']['output'];
  /** To show or hide the balance */
  hideBalance: Scalars['Boolean']['output'];
  lowBalance: Scalars['Boolean']['output'];
  recommendUpSell: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  totalValue: ContractValue;
}

export interface ContractDollarValue {
  __typename?: 'ContractDollarValue';
  original: Money;
  remaining: Money;
}

export enum ContractType {
  AD_PACK = 'AD_PACK',
  MASTER_AGREEMENT = 'MASTER_AGREEMENT',
  PRICING_GROUP = 'PRICING_GROUP',
  SEEK_CONTRACT = 'SEEK_CONTRACT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TALENT_SEARCH_CONTRACT = 'TALENT_SEARCH_CONTRACT',
}

export interface ContractUnitValue {
  __typename?: 'ContractUnitValue';
  original: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
}

export interface ContractValue {
  __typename?: 'ContractValue';
  dollars: ContractDollarValue;
  units?: Maybe<ContractUnitValue>;
}

export interface ConversionRateData {
  __typename?: 'ConversionRateData';
  adConversionRate?: Maybe<Scalars['Float']['output']>;
  mobileRate: Scalars['Float']['output'];
  similarAdAverage: Scalars['Float']['output'];
  similarAdConversionRate?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
}

export interface CountriesInput {
  /** Used to generate the 'contextualName', this should usually represent the country the hire is located in. If it isn't provided it will fallback to the hirer's nation */
  contextual?: InputMaybe<Scalars['String']['input']>;
  /** Restricts the location suggestion search to only include these countries in the results. */
  only?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Controls the order of results so that you can specify for one countries results to appear above other(s). If it isn't provided it will use the order of 'only' as fallback. If 'only' isn't provided then it will fallback to 'contextual' as first preference. */
  preferenceOrder?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface Country {
  __typename?: 'Country';
  /** 2 digit code in ISO 3166-1 alpha-2 format eg GB */
  countryCode: Scalars['String']['output'];
  /** Full name of the country eg United Kingdom */
  name: Scalars['String']['output'];
}

export interface CountrySelectionOptions {
  __typename?: 'CountrySelectionOptions';
  /** List of countries to populate a drop-down list or autocomplete. */
  options: Country[];
}

export interface CountryType {
  __typename?: 'CountryType';
  /** Full name of the country */
  defaultName: Scalars['String']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
}

export interface CoverStrategies {
  __typename?: 'CoverStrategies';
  jdpCover: Scalars['String']['output'];
  jdpCoverThumbnail: Scalars['String']['output'];
}

export interface Create2C2PPaymentIntentForInvoiceInput {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** Nation of the advertiser. */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /** The currency of the payment. */
  paymentCurrency?: InputMaybe<Currency>;
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface Create2C2PPaymentIntentInput {
  /** The phone number of payer when OVO, BILLEASE or HOMECREDIT channelcode is used. */
  accountNo?: InputMaybe<Scalars['String']['input']>;
  /** Nation of the advertiser. */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** bank used by 2c2p to identify the bank */
  bank?: InputMaybe<Bank2C2P>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: InputMaybe<ChannelCode>;
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: InputMaybe<Scalars['String']['input']>;
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
}

export type Create2c2pPaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentIntent2C2PPayload
  | PaymentIntentChargeError
  | PaymentMethodError
  | PromotionInvalidError;

export interface CreateApplicationNoteInput {
  content: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface CreateBrandInput {
  assets: BrandAssetsInput;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export type CreateBrandPayload =
  | CreateBrandSuccessPayload
  | InvalidInputPayload;

export interface CreateBrandSuccessPayload {
  __typename?: 'CreateBrandSuccessPayload';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
}

export interface CreateCustomQuestionErrorResult {
  __typename?: 'CreateCustomQuestionErrorResult';
  reason: Scalars['String']['output'];
}

export interface CreateCustomQuestionInput {
  languageId: Scalars['String']['input'];
  question: CustomQuestion;
}

export type CreateCustomQuestionOutputUnion =
  | CreateCustomQuestionErrorResult
  | CreateCustomQuestionResult;

export interface CreateCustomQuestionResult {
  __typename?: 'CreateCustomQuestionResult';
  question: OutputCustomQuestion;
  validationFailures: CustomQuestionValidationFailure[];
}

export interface CreateDraftJobInput {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<DraftJobCategoryInput[]>;
  /** CreatedAt date will only update when used in migration */
  createdAt?: InputMaybe<Scalars['String']['input']>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: InputMaybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<DraftJobGuidedDetailsInput[]>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<DraftJobLocationInput[]>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status?: InputMaybe<DraftStatus>;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<DraftJobRoleTitleInput[]>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<WorkType[]>;
}

export interface CreateDraftJobPayload {
  __typename?: 'CreateDraftJobPayload';
  /**
   * The successfully created draft job id. Deprecated "Int" version.
   * @deprecated Will be removed once no clients are calling it
   */
  draftId?: Maybe<Scalars['Int']['output']>;
  /** The successfully created draft job id */
  id?: Maybe<Scalars['String']['output']>;
}

export interface CreateJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
}

export interface CreateJobResponse {
  __typename?: 'CreateJobResponse';
  /** The key used to create the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
}

export interface CreateJobRevisionInput {
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  /** The workflow context that the Job Revision is being created in. e.g. upgrade-purchase */
  workflowContext?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateJobRevisionPayload {
  __typename?: 'CreateJobRevisionPayload';
  revisionId: Scalars['String']['output'];
}

export interface CreateOrderInput {
  /**
   * Advertiser's Id.
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** Advertiser's nation. */
  advertiserNation: Scalars['String']['input'];
  /** Selected line items. Contains input attributes associated to selected product. */
  lineItems: OrderRequestInputItem[];
  /** Idempotency key for create order request */
  orderCreationId?: InputMaybe<Scalars['String']['input']>;
  /** Promotion code. */
  promoCode?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateOrderPayload {
  __typename?: 'CreateOrderPayload';
  order?: Maybe<Order>;
}

export interface CreateOrderSuccessPayload {
  __typename?: 'CreateOrderSuccessPayload';
  order?: Maybe<Order>;
  promotion?: Maybe<Promotion>;
}

export type CreateOrderWithPromotionPayload =
  | CreateOrderSuccessPayload
  | PromotionInvalidError;

export interface CreateProductSetForAdEditingInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId: Scalars['String']['input'];
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  jobId: Scalars['ID']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateProductSetForAdEditingPayload {
  __typename?: 'CreateProductSetForAdEditingPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdPostingInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
}

export interface CreateProductSetForAdPostingPayload {
  __typename?: 'CreateProductSetForAdPostingPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdRefreshInput {
  /** The draft ID associated to the job refresh flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The job ID associated to the job refresh flow */
  jobId: Scalars['ID']['input'];
  /** The user intent */
  userIntent?: InputMaybe<UserIntentType>;
}

export interface CreateProductSetForAdRefreshPayload {
  __typename?: 'CreateProductSetForAdRefreshPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdUpgradePurchaseInput {
  /** The ID of the Job that is to be updated */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /**
   * The unique identifier linking tracking events associated with a user's interaction within a specific UI flow that can
   * result in a purchase. It enables the aggregation of data related to actions such as posting a job ad, paying for it,
   * upgrading the ad, and other relevant activities occurring within the same user session.
   */
  opportunityId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateProductSetForAdUpgradePurchasePayload {
  __typename?: 'CreateProductSetForAdUpgradePurchasePayload';
  /** The product IDs that are purchasable */
  productIds: Array<Scalars['ID']['output']>;
  /** The created product set */
  productSet: ProductSet;
  /** Products to display */
  products: AdProductUpgrade[];
}

export interface CreateProductSetForBudgetsPurchaseForHirerPayload {
  __typename?: 'CreateProductSetForBudgetsPurchaseForHirerPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForBudgetsPurchaseForVisitorInput {
  /** The randomly generated UUIDv4 of the logged out user */
  visitorId: Scalars['ID']['input'];
}

export interface CreateProductSetForBudgetsPurchaseForVisitorPayload {
  __typename?: 'CreateProductSetForBudgetsPurchaseForVisitorPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForMultiLocationInput {
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  /** The draft ID associated to the job posting flow */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  /** ID of the original job being posted to multiple locations */
  jobId: Scalars['String']['input'];
  /** The locale to create product set */
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateProductSetForMultiLocationPayload {
  __typename?: 'CreateProductSetForMultiLocationPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForPriceCheckerPreviewInput {
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  jobAdDetails: JobAdDetails;
}

export interface CreateProductSetForPriceCheckerPreviewPayload {
  __typename?: 'CreateProductSetForPriceCheckerPreviewPayload';
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateQuestionnaireInput {
  adCentreAdvertiserId: Scalars['String']['input'];
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** This is the country code for the website domain being used to post job and create questionnaire. e.g values au and nz as strings */
  domainCountryCode: Scalars['String']['input'];
  language: Scalars['String']['input'];
  sourceQuestionnaireId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateQuestionnaireResponse {
  __typename?: 'CreateQuestionnaireResponse';
  questionnaireId?: Maybe<Scalars['String']['output']>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
}

export interface CreateSavedSearchInput {
  createdDate: Scalars['String']['input'];
  criteria: TalentSearchCriteriaInput;
  name: Scalars['String']['input'];
}

export interface CreateSavedSearchPayload {
  __typename?: 'CreateSavedSearchPayload';
  savedSearch?: Maybe<TalentSearchSavedSearch>;
}

export interface CreateStripePaymentIntentForInvoiceInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: InputMaybe<Currency>;
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface CreateStripePaymentIntentInput {
  /** Nation of the advertiser. */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: InputMaybe<Scalars['String']['input']>;
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
}

export type CreateStripePaymentIntentPayload =
  | InvoiceError
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PaymentMethodError
  | PromotionInvalidError
  | StripePaymentIntentPayload;

export interface CreditCard {
  __typename?: 'CreditCard';
  /** The brand of credit card processor such as 'Visa' or 'MasterCard' */
  brand: Scalars['String']['output'];
  /** The expiry month of the credit from 1 to 12, such as '02' for February or '12' for December. */
  expiryMonth: Scalars['String']['output'];
  /** A four digit number representing the expiry year, e.g. '2022' */
  expiryYear: Scalars['String']['output'];
  /** The last (typically 4) digits of a credit card, e.g. '4234' */
  lastDigits: Scalars['String']['output'];
  /** A token that uniquely represents the card, e.g. 'card_1HPZvMAtb48kSZAqvNZabcde' */
  token: Scalars['String']['output'];
}

export enum CreditCardBrandInput {
  AMEX = 'AMEX',
  MASTER_CARD = 'MASTER_CARD',
  VISA = 'VISA',
}

export enum Currency {
  AUD = 'AUD',
  HKD = 'HKD',
  IDR = 'IDR',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
}

export interface CurrentAdvertiserContext {
  __typename?: 'CurrentAdvertiserContext';
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID']['output'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']['output']>;
  /**
   * Can Manage Company Profile
   * @deprecated Use permissionOptions.canManageCompanyProfile instead.
   */
  canManageCompanyProfile?: Maybe<Scalars['Boolean']['output']>;
  /** User claims relevant to the specified advertiser */
  claims?: Maybe<Array<Maybe<Claim>>>;
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean']['output'];
  /** Name of the advertiser account. */
  name: Scalars['String']['output'];
  /** Key/value pairs of permissions for a user on behalf of the advertiser. */
  permissionOptions: PermissionOptions;
  /**
   * Array of permissions for a user on behalf of the advertiser.
   * @deprecated Use permissionOptions instead.
   */
  permissions?: Maybe<Permission[]>;
  /** Status of Profile Properties related to the account of the user currently signed in */
  profileProperties?: Maybe<ProfileProperties>;
  /** Role of user relevant to the specified advertiser. Observed to be null for migrated or impersonated customers. */
  role?: Maybe<Scalars['String']['output']>;
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID']['output'];
  /** The advertiser's status. */
  status: AdvertiserStatus;
  /** Effective Talent Search access for this user against this advertiser */
  talentSearchAccess?: Maybe<TalentSearchAccess>;
}

export type CurrentAdvertiserResponse =
  | CurrentAdvertiserContext
  | MissingAdvertiserIdClaimError;

export interface CurrentUserContext {
  __typename?: 'CurrentUserContext';
  /** Email of the user currently signed in */
  email: Scalars['String']['output'];
  /** First name of the user. */
  firstName: Scalars['String']['output'];
  /** Adcentre user id. */
  id: Scalars['String']['output'];
  /** Last name of the user. */
  lastName: Scalars['String']['output'];
  /** First and last names of the user. */
  name: Scalars['String']['output'];
  /** Verification status of the user. */
  status: Scalars['String']['output'];
}

export type CurrentUserResponse = CurrentUserContext | MissingUserIdClaimError;

export interface CustomQuestion {
  /** Options */
  options: Option[];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['input'];
}

export interface CustomQuestionValidationFailure {
  __typename?: 'CustomQuestionValidationFailure';
  /** Custom Question Validation Failure Message */
  message: Scalars['String']['output'];
}

export interface DailyTrendsProductDisplayName {
  __typename?: 'DailyTrendsProductDisplayName';
  day: Scalars['String']['output'];
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
}

export interface DateAxisLabel {
  __typename?: 'DateAxisLabel';
  date: Scalars['Date']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface DeleteApplicationAttachmentInput {
  attachmentId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface DeleteApplicationAttachmentPayload {
  __typename?: 'DeleteApplicationAttachmentPayload';
  success: Scalars['Boolean']['output'];
}

export interface DeleteBrandingAssetInput {
  assetId: Scalars['ID']['input'];
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface DeleteBrandingAssetPayload {
  __typename?: 'DeleteBrandingAssetPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
  successMessage?: Maybe<Scalars['String']['output']>;
}

export interface DeleteDraftJobInput {
  /** The id of the draft job to be deleted. */
  id: Scalars['String']['input'];
}

export interface DeleteDraftJobPayload {
  __typename?: 'DeleteDraftJobPayload';
  /** Indicates if deleting the draft job was successful or not. */
  successful: Scalars['Boolean']['output'];
}

export interface DeleteSavedSearchPayload {
  __typename?: 'DeleteSavedSearchPayload';
  deletedCriteriaHash?: Maybe<Scalars['String']['output']>;
}

export interface DeleteStripeSavedCreditCardInput {
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
}

export interface DeleteStripeSavedCreditCardPayload {
  __typename?: 'DeleteStripeSavedCreditCardPayload';
  /** Status of the credit card deletion */
  successful?: Maybe<Scalars['Boolean']['output']>;
}

export interface DisableMessageType {
  __typename?: 'DisableMessageType';
  en: Scalars['String']['output'];
}

export interface Discount {
  __typename?: 'Discount';
  discountAmount: Money;
  discountPercentage: Scalars['Float']['output'];
}

export type DisplayMetadataError = Error & {
  __typename?: 'DisplayMetadataError';
  message: Scalars['String']['output'];
};

export enum DisplayRecommendation {
  exclusive = 'exclusive',
  inclusive = 'inclusive',
}

export interface DraftJob {
  __typename?: 'DraftJob';
  /**
   * Adcentre adType that is old way of representing "products"
   * @deprecated Will be removed once deprecated express api
   */
  adType?: Maybe<Scalars['String']['output']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBranding>;
  /** Selected category and sub-category */
  categories?: Maybe<DraftJobCategory[]>;
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']['output']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']['output']>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<Maybe<DraftJobGuidedDetails>>>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']['output']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The job location info */
  locations?: Maybe<Array<Maybe<DraftJobLocation>>>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** The phone number for the job */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Selected products info */
  products?: Maybe<DraftJobProducts>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']['output']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetails>;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']['output']>;
  /** The job title(s) */
  titles?: Maybe<Array<Maybe<DraftJobRoleTitle>>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideo>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<Maybe<WorkType>>>;
}

export interface DraftJobBranding {
  __typename?: 'DraftJobBranding';
  id?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
}

export interface DraftJobBrandingInput {
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
}

export interface DraftJobCategory {
  __typename?: 'DraftJobCategory';
  adcentreCategoryId?: Maybe<Scalars['String']['output']>;
  adcentreSubCategoryId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  subCategoryId?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobCategoryInput {
  adcentreCategoryId?: InputMaybe<Scalars['String']['input']>;
  adcentreSubCategoryId?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  subCategoryId: Scalars['String']['input'];
}

export interface DraftJobEdge {
  __typename?: 'DraftJobEdge';
  /** A `DraftJob` from the pagination query. */
  node?: Maybe<DraftJobNode>;
}

export enum DraftJobFlowType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  MULTILOCATION = 'MULTILOCATION',
}

export interface DraftJobGuidedDetails {
  __typename?: 'DraftJobGuidedDetails';
  type: GuidedDetailsType;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface DraftJobGuidedDetailsInput {
  type: GuidedDetailsType;
  values: Array<Scalars['String']['input']>;
}

export interface DraftJobLocation {
  __typename?: 'DraftJobLocation';
  seekId: Scalars['String']['output'];
}

export interface DraftJobLocationInput {
  seekId: Scalars['String']['input'];
}

export interface DraftJobNode {
  __typename?: 'DraftJobNode';
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  /** A reference provided by the agent to facilitate searching in draft job lists. */
  agencyExternalReference?: Maybe<Scalars['String']['output']>;
  /** The full name of the user who created the draft job. */
  createdBy: Scalars['String']['output'];
  /** Date/time in UTC (ISO 8601 format) the draft job was created. */
  createdDate: Scalars['String']['output'];
  /** The draft job ID. */
  id: Scalars['String']['output'];
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   * @deprecated Use locations array instead to get more details
   */
  locationDisplayNames?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   */
  locations: Location[];
  /** Draft job status */
  status: DraftStatus;
  /** Draft job title appearing on search results, job lists and candidate management pages once the draft is posted. */
  title?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobNotFoundPayload {
  __typename?: 'DraftJobNotFoundPayload';
  reason: Scalars['String']['output'];
}

export type DraftJobPayload = DraftJobNotFoundPayload | DraftJobSuccessPayload;

export enum DraftJobPaymentOption {
  CONTRACT = 'CONTRACT',
  CREDIT_CARD = 'CREDIT_CARD',
  INVOICE = 'INVOICE',
  SAVED_CREDIT_CARD = 'SAVED_CREDIT_CARD',
}

export interface DraftJobProducts {
  __typename?: 'DraftJobProducts';
  productIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  productSetId?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobProductsInput {
  /** List of product id */
  productIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Unique ID of the product set */
  productSetId?: InputMaybe<Scalars['String']['input']>;
}

export enum DraftJobProgress {
  CLASSIFY = 'CLASSIFY',
  MANAGE = 'MANAGE',
  PAY_AND_POST = 'PAY_AND_POST',
  SELECT_AD_TYPE = 'SELECT_AD_TYPE',
  WRITE = 'WRITE',
}

export interface DraftJobRoleTitle {
  __typename?: 'DraftJobRoleTitle';
  language: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface DraftJobRoleTitleInput {
  language: Scalars['String']['input'];
  title: Scalars['String']['input'];
}

export enum DraftJobSalaryCurrency {
  AUD = 'AUD',
  BDT = 'BDT',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  USD = 'USD',
  VND = 'VND',
}

export interface DraftJobSalaryDetails {
  __typename?: 'DraftJobSalaryDetails';
  currency?: Maybe<DraftJobSalaryCurrency>;
  displayValue?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  maximum?: Maybe<Scalars['SafeInt']['output']>;
  minimum?: Maybe<Scalars['SafeInt']['output']>;
  type?: Maybe<SalaryType>;
}

export interface DraftJobSalaryDetailsInput {
  /** The currency for the salary */
  currency?: InputMaybe<DraftJobSalaryCurrency>;
  /** The text to be displayed to show salary to candidates */
  displayValue?: InputMaybe<Scalars['String']['input']>;
  /** Should the salary text be displayed or not */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** The max salary value */
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The min salary value */
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
  /** The pay type (e.g. annual package, hourly rate) */
  type?: InputMaybe<SalaryType>;
}

export interface DraftJobSuccessPayload {
  __typename?: 'DraftJobSuccessPayload';
  draft: DraftJob;
  flowType?: Maybe<DraftJobFlowType>;
  status: DraftStatus;
}

export interface DraftJobVideo {
  __typename?: 'DraftJobVideo';
  url: Scalars['String']['output'];
}

export interface DraftJobVideoInput {
  url: Scalars['String']['input'];
}

export enum DraftJobWritingMode {
  GUIDED = 'GUIDED',
  STANDARD = 'STANDARD',
}

export interface DraftJobsInput {
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** The draft job search term. */
  term?: InputMaybe<Scalars['String']['input']>;
}

export interface DraftJobsPayload {
  __typename?: 'DraftJobsPayload';
  /** The list of `DraftJob` edges for the current page. */
  edges: DraftJobEdge[];
  /** The total number of draft jobs across all pages. */
  totalCount: Scalars['Int']['output'];
  /** The total number of pages. */
  totalPageCount: Scalars['Int']['output'];
}

export enum DraftStatus {
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export interface DrawdownContractDetails {
  __typename?: 'DrawdownContractDetails';
  adType: AdType;
  /** This the ID of the contract being used for drawdown. */
  contractId: Scalars['String']['output'];
  /** A UI consumable name of the contract for consistent naming. */
  contractName?: Maybe<Scalars['String']['output']>;
  contractType: ContractType;
  /**
   * The current balance of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  currentValue?: Maybe<DrawdownContractValue>;
  /**
   * The contract is flexible and not unit based.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  dollarBased?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The expiry date of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The remaining contract balance after paying for the current purchase.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  remainingValue?: Maybe<DrawdownContractValue>;
}

export interface DrawdownContractValue {
  __typename?: 'DrawdownContractValue';
  dollars?: Maybe<Money>;
}

export interface DrawdownItem {
  __typename?: 'DrawdownItem';
  amount: Scalars['SafeInt']['output'];
  drawdownContractDetails: DrawdownContractDetails;
}

export enum DrawdownType {
  BALANCE = 'BALANCE',
  PACK = 'PACK',
  PAYABLE = 'PAYABLE',
}

export interface DriversLicence {
  __typename?: 'DriversLicence';
  verified: Scalars['Boolean']['output'];
}

/** Feature to determine duration of a catalogue product is active. Eg AdBudgetProduct active for 12 months. */
export type DurationFeature = CatalogueProductFeature & {
  __typename?: 'DurationFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** Unit of the duration. Eg YEAR, MONTH, DAY */
  unit: DurationUnit;
  /** Size of duration. Eg 60, 12 */
  value: Scalars['Int']['output'];
};

export enum DurationUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export interface EditJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<BrandingInput>;
  draftId: Scalars['String']['input'];
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface EditJobResponse {
  __typename?: 'EditJobResponse';
  revisionId?: Maybe<Scalars['String']['output']>;
}

/** Base error interface. */
export interface Error {
  message: Scalars['String']['output'];
}

export interface Expectation {
  __typename?: 'Expectation';
  /** Expected completed applications for the AdProduct */
  applicationsCompleted?: Maybe<Scalars['Int']['output']>;
  /** Correlation ID for the expectations */
  correlationId?: Maybe<Scalars['String']['output']>;
}

export interface ExpectedSalaryV2 {
  __typename?: 'ExpectedSalaryV2';
  amount: SalaryAmountV2[];
  currency: Scalars['String']['output'];
}

export interface ExpireJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** When the expiryTime is set the job will be updated to expire at that time. This is only available during impersonation sessions */
  expiryTime?: InputMaybe<Scalars['DateTime']['input']>;
  jobId: Scalars['String']['input'];
  /** The reason for expiry - defaults to MANUAL */
  reason?: InputMaybe<ExpireReason>;
}

export interface ExpireJobResponse {
  __typename?: 'ExpireJobResponse';
  jobId: Scalars['String']['output'];
}

export enum ExpireReason {
  FRAUDULENT = 'FRAUDULENT',
  MANUAL = 'MANUAL',
  NATURAL = 'NATURAL',
  NONCOMPLIANT = 'NONCOMPLIANT',
}

export interface Expiry {
  __typename?: 'Expiry';
  daysRemaining: Scalars['Int']['output'];
  expiresSoon: Scalars['Boolean']['output'];
  expiryDate: Scalars['String']['output'];
}

export interface ExportProfileInput {
  connectionType: TalentSearchConnectionType;
  externalReferenceId?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['Int']['input'];
}

export enum FiltersAdRatingType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  NO_RATING = 'NO_RATING',
}

export enum Frequency {
  DAILY = 'DAILY',
  EVERY_APPLICATION = 'EVERY_APPLICATION',
  NEVER = 'NEVER',
  WEEKLY = 'WEEKLY',
}

export interface GenerateJobContentInput {
  categoryName: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  draftId: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  locationDescription: Scalars['String']['input'];
  subCategoryName: Scalars['String']['input'];
  workType: Scalars['String']['input'];
}

export type GenerateJobContentResponsePayload =
  JobContentGenerationResponseSuccessPayload;

export interface GetBrandingAssetsInput {
  assetType: BrandAssetTypeEnum;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface GetProductSetInput {
  /** The locale to get product set */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID']['input'];
}

export type GetProductSetResponse =
  | ProductSet
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface GetSurchargeAmountInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: InputMaybe<CreditCardBrandInput>;
  /** The currency of the payment. */
  paymentCurrency: Currency;
  /** The total amount to be paid for the payment intent. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface GetSurchargeAmountPayload {
  __typename?: 'GetSurchargeAmountPayload';
  /** The surcharge amount for payment method */
  surchargeAmount: Scalars['SafeInt']['output'];
  /** Determine if the surcharge is applicable for payment method */
  surchargeApplicable: Scalars['Boolean']['output'];
}

export interface GranularLocation {
  __typename?: 'GranularLocation';
  nonSpecificLocation?: Maybe<Scalars['String']['output']>;
  specificLocation?: Maybe<Scalars['String']['output']>;
  type: RecommendationType;
}

export enum GuidedDetailsType {
  ABOUT_BUSINESS = 'ABOUT_BUSINESS',
  BENEFITS = 'BENEFITS',
  QUALIFICATIONS = 'QUALIFICATIONS',
  RESPONSIBILITIES = 'RESPONSIBILITIES',
}

export interface HeaderFooterContext {
  __typename?: 'HeaderFooterContext';
  /** Advertiser-specific information. This will be null until account selection is complete. */
  advertiser?: Maybe<AdvertiserHeaderContext>;
  /** Context supplied by Identity Provider (IdP). */
  identityContext?: Maybe<IdentityHeaderContext>;
  /** Profile information about the current user. */
  user?: Maybe<UserHeaderContext>;
}

export interface HeaderFooterContextInput {
  /** Location object. Optional. Supplied by client in order to support customer service view toggling. */
  location?: InputMaybe<WindowLocation>;
}

export interface HelpTip {
  __typename?: 'HelpTip';
  /** Content of a help tip */
  content?: Maybe<Scalars['String']['output']>;
  /** Image to help describe a product */
  image?: Maybe<HelpTipImage>;
}

export interface HelpTipImage {
  __typename?: 'HelpTipImage';
  /** Alternative text used in the UI */
  altText: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
}

export interface HiddenPrice {
  __typename?: 'HiddenPrice';
  /** price access level (priceVisibility) if hidePrice is enabled for the hirer */
  priceVisibility: PriceVisibility;
}

export interface HirerAccount {
  __typename?: 'HirerAccount';
  /** Hirer account activation status */
  activationStatus: AccountActivationStatus;
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  billingStatus?: Maybe<BillingStatus>;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business name that gets collected on the registration form */
  businessName: Scalars['String']['output'];
  /** Indicates if the account is managed by an agency */
  hasAgent?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the LinkOut feature is enabled for the hirer. */
  hasLinkOutEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide all pricing from hirer */
  hidePrice?: Maybe<Scalars['Boolean']['output']>;
  hirerGroup?: Maybe<HirerGroup>;
  /** Indicates which type hirer belongs to */
  hirerType?: Maybe<HirerType>;
  id: Scalars['String']['output'];
  /** Partner integrations details */
  integrations: Integration[];
  /** Indicates whether the advertiser is yet to be approved in Salesforce/SAP. */
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is archived and in deactivated status. */
  isArchived: Scalars['Boolean']['output'];
  /** Indicates whether the Mirroring/Hyperlinks permission is enabled in CRM. */
  isEsmAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is managed by a Sales Consultant. */
  isManagedAccount?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser would prefer to hide their company name in their job ads. Null if the hirer has not set their preference yet. */
  isPrivateAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the account is approved for Talent Search access. New accounts have to wait 24 hours from when the account is approved before access to Talent Search access is granted. */
  isTalentSearchApproved?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether the advertiser is marked as a test account in CRM. */
  isTestAccount?: Maybe<Scalars['Boolean']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency?: Maybe<ManagingAgency>;
  nation?: Maybe<Scalars['String']['output']>;
  /** Indicates the zone the account was originally created in. */
  originZone?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Parent>;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** To show or hide the different price states */
  priceVisibility?: Maybe<PriceVisibility>;
  /** Indicates if a hirer has a pricing group contract available */
  pricingGroupContractExists?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates which pricing model hirer belongs to, either Fixed or Horizon */
  pricingModel?: Maybe<PricingModel>;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** @deprecated This field is no longer neccessary due to FAA */
  rules?: Maybe<Array<Maybe<Rule>>>;
  /**
   * Hirer (Advertiser) account status
   * @deprecated Use activationStatus instead
   */
  status: HirerAccountStatus;
  /** The list of users associated with the account */
  users: User[];
}

export interface HirerAccountDetails {
  __typename?: 'HirerAccountDetails';
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']['output']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The billing contact of the account */
  billingContact: BillingContact;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']['output']>;
  /** Business name that gets collected on the registration form */
  businessName?: Maybe<Scalars['String']['output']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency: ManagingAgency;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** Hirer (Advertiser) account status */
  status: HirerAccountStatus;
}

export interface HirerAccountDetailsQueryInput {
  /**
   * The ID of Advertiser in AdCentre
   * @deprecated Internally resolved via auth
   */
  adCentreAdvertiserId?: InputMaybe<Scalars['ID']['input']>;
}

export interface HirerAccountEdge {
  __typename?: 'HirerAccountEdge';
  membershipStatus: Scalars['String']['output'];
  node: HirerAccount;
  role: Scalars['String']['output'];
}

export interface HirerAccountLoginUrlInput {
  /** Hirer Account ID for the login request. The user will require authorization to view the account. */
  hirerAccountId: Scalars['String']['input'];
  /** Language param to apply additional localisation to auth related pages. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Return uri for successful account switching. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export enum HirerAccountStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export interface HirerAccountUserQueryInput {
  /** Request to get user permissions to account. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** User ID of the user we want to find */
  userId: Scalars['String']['input'];
}

export interface HirerAccountsResult {
  __typename?: 'HirerAccountsResult';
  edges: HirerAccountEdge[];
  totalCount: Scalars['Int']['output'];
}

export interface HirerActivitiesByJobInput {
  /**
   * The SEEK ID of the advertiser
   * @deprecated Prefer advertiser ID claim from bearer token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
}

export interface HirerActivity {
  __typename?: 'HirerActivity';
  /** A list of extra data related the activity */
  activityData: HirerActivityData[];
  /** The time when the activity occurred */
  activityTime: Scalars['DateTime']['output'];
  /** The type of the activity */
  activityType: HirerActivityType;
  id: Scalars['ID']['output'];
  /** Partial list of the  job applications related to the activity, max 3 job applications. */
  partialJobApplicationList: HirerActivityJobApplication[];
  /** First name of the account user who triggers the activity. */
  userFirstName: Scalars['String']['output'];
  /** Last name of the account user who triggers the activity. */
  userLastName: Scalars['String']['output'];
}

export interface HirerActivityConnection {
  __typename?: 'HirerActivityConnection';
  edges: HirerActivityEdge[];
  pageInfo: PageInfo;
}

export interface HirerActivityData {
  __typename?: 'HirerActivityData';
  /** The name of data. eg: noteContent */
  name: Scalars['String']['output'];
  /** The value of data. eg: content of the note */
  value?: Maybe<Scalars['String']['output']>;
}

export interface HirerActivityEdge {
  __typename?: 'HirerActivityEdge';
  /** String based cursor for the edge node */
  cursor: Scalars['String']['output'];
  /** Node representing hirer activity */
  node: HirerActivity;
}

export interface HirerActivityJobApplication {
  __typename?: 'HirerActivityJobApplication';
  /** Prospect id of job application in Adcentre */
  adcentreProspectId: Scalars['Int']['output'];
  /** First name of the candidate. */
  candidateFirstName: Scalars['String']['output'];
  /** Last name of the candidate. */
  candidateLastName: Scalars['String']['output'];
}

export enum HirerActivityType {
  NOTE_ADDED = 'NOTE_ADDED',
  NOTE_DELETED = 'NOTE_DELETED',
}

export interface HirerDashboardArticle {
  __typename?: 'HirerDashboardArticle';
  /** Title of the image */
  imageTitle: Scalars['String']['output'];
  /** Image URL */
  imageUrl: Scalars['String']['output'];
  /** Minutes to read article */
  timeToRead: Scalars['Int']['output'];
  /** Title of the article */
  title: Scalars['String']['output'];
  /** Article URL */
  url: Scalars['String']['output'];
}

export interface HirerDashboardBudgetUpsellInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  dataSource?: InputMaybe<Scalars['String']['input']>;
}

export interface HirerDashboardBudgetUpsellResponse {
  __typename?: 'HirerDashboardBudgetUpsellResponse';
  /** Max contract discount rate used for display on the hirer dashboard */
  maxContractDiscountRate?: Maybe<Scalars['Float']['output']>;
  /** Set to true when a hirer is eligible for ad budget upsell on the hirer dashboard */
  upsellEligible: Scalars['Boolean']['output'];
}

export interface HirerDashboardHiringAdviceList {
  __typename?: 'HirerDashboardHiringAdviceList';
  /** Articles to display on the dashboard */
  articles?: Maybe<Array<Maybe<HirerDashboardArticle>>>;
  count: Scalars['Int']['output'];
}

export interface HirerDashboardJob {
  __typename?: 'HirerDashboardJob';
  /** Type of ad */
  adType?: Maybe<Scalars['String']['output']>;
  /** Does the ad fit the criteria to allow it to be refreshed (Ad is direct posted, is Classic or Standout, has status of Active, has less than 29 days remaining) */
  canBeRefreshed?: Maybe<Scalars['Boolean']['output']>;
  /** Does the ad fit the criteria to allow it to be upgraded (Ad is direct posted, has a status of Active, is not a Draft, has upgrade product code from upgrade api response) */
  canBeUpgraded?: Maybe<Scalars['Boolean']['output']>;
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']['output']>;
  /** The ID of the draft from the AdCentre database */
  draftId?: Maybe<Scalars['String']['output']>;
  /** The day job listing ends */
  endDateUtc?: Maybe<Scalars['String']['output']>;
  /** The ID of the job from the SeekWeb database */
  jobId?: Maybe<Scalars['String']['output']>;
  /** The source of job as direct job, indirect job (posted via an uploader), or draft */
  kind: Scalars['String']['output'];
  /** Position of the item in the list */
  listPosition: Scalars['Int']['output'];
  /** The ID of the listing from the AdCentre database */
  listingId?: Maybe<Scalars['String']['output']>;
  /** Location details resolved from the unifiedLocationId */
  location?: Maybe<Location>;
  /** Number of candidates */
  numberOfCandidates: Scalars['Int']['output'];
  /** Number of new candidates */
  numberOfNewCandidates: Scalars['Int']['output'];
  /**
   * Indicates the performance rating of the job. Includes UNAVAILABLE|WITHHELD to
   * cover more scenario with introduction of new markets
   */
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** The ID of the position from the AdCentre database */
  positionId?: Maybe<Scalars['String']['output']>;
  /** The day job listing has started */
  startDateUtc?: Maybe<Scalars['String']['output']>;
  /** Status of the job */
  status?: Maybe<Scalars['String']['output']>;
  /** Impersonation-aware url for the job on talent search. */
  talentSearchJobHref?: Maybe<Scalars['String']['output']>;
  /** The title of the position/job/draft */
  title: Scalars['String']['output'];
  /** Job location ID */
  unifiedLocationId?: Maybe<Scalars['Int']['output']>;
}

export interface HirerDashboardJobCounts {
  __typename?: 'HirerDashboardJobCounts';
  /** Count of drafts.  For indirect listings, this count will always be zero. */
  draft?: Maybe<Scalars['Int']['output']>;
  /**
   * Count of expired or disabled listings.
   * For direct listings, the underlying datasource caps this at 20, and the true count may be higher.
   */
  expired?: Maybe<Scalars['Int']['output']>;
  /**
   * Count of listings that have been posted; they will either have successfully posted and are Active, or they are in a transitional state of Saved, Pending or Unsuccessful.
   * For direct listings, the underlying datasource caps this at 20, and the true count may be higher.
   */
  posted?: Maybe<Scalars['Int']['output']>;
}

export interface HirerDashboardJobs {
  __typename?: 'HirerDashboardJobs';
  /** Directly posted job counts by status */
  directCounts?: Maybe<HirerDashboardJobCounts>;
  /** Indirectly posted job counts by status */
  indirectCounts?: Maybe<HirerDashboardJobCounts>;
  /** Jobs to display on the dashboard */
  jobs?: Maybe<Array<Maybe<HirerDashboardJob>>>;
  /** Last Draft this user has worked with */
  lastDraft?: Maybe<HirerDashboardJob>;
}

export interface HirerDashboardJobsQueryInput {
  /**
   * The ID of Advertiser in AdCentre
   * @deprecated Prefer advertiser ID claim from bearer token
   */
  adCentreAdvertiserId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Maximum number of jobs to be returned.
   * Will return all jobs received from resource API if this parameter is not provided, or the value is 0.
   */
  maxResults?: InputMaybe<Scalars['Int']['input']>;
  /**
   * SEEK Advertiser Id
   * @deprecated Prefer advertiser ID claim from bearer token
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
}

export interface HirerDashboardTalentSearch {
  __typename?: 'HirerDashboardTalentSearch';
  premiumTalentSearchHref?: Maybe<Scalars['String']['output']>;
}

export interface HirerDashboardUserDetails {
  __typename?: 'HirerDashboardUserDetails';
  /** Email of the user */
  email: Scalars['String']['output'];
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
}

export interface HirerDashboardWelcome {
  __typename?: 'HirerDashboardWelcome';
  /** Welcome message for the user */
  message: Scalars['String']['output'];
  /** Timestamp when user logged in (ISO 8601) */
  timestamp: Scalars['String']['output'];
}

export interface HirerExperience {
  __typename?: 'HirerExperience';
  /**
   * The hirer control type of this question - one of 'Default', 'Range', 'RangeUpTo', 'RangeAtLeast', 'AdvancedAvailability',
   * this will be null when the question source is custom
   */
  controlType?: Maybe<Scalars['String']['output']>;
  /** Null if the question is not mandatory, defined if the question is mandataory */
  mandatory?: Maybe<MandatoryData>;
  /** Describes if simplified answers were selected - either "simplifiedAnswers" or "answers" */
  selectionType: Scalars['String']['output'];
  /** The simplified answers for the question - present only if selectionType is "simplifiedAnswers" */
  simplifiedAnswers?: Maybe<SimplifiedAnswers[]>;
}

export enum HirerGroup {
  ADVERTISER = 'ADVERTISER',
  AGENT = 'AGENT',
  RECRUITMENT_SOFTWARE_PROVIDER = 'RECRUITMENT_SOFTWARE_PROVIDER',
}

export interface HirerJob {
  __typename?: 'HirerJob';
  /** The AdCentre listing ID. */
  adCentreListingId?: Maybe<Scalars['String']['output']>;
  /** The AdCentre position ID. */
  adCentrePositionId: Scalars['String']['output'];
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  /** A reference provided by the agent to facilitate searching in job lists. */
  agencyExternalReference?: Maybe<Scalars['String']['output']>;
  /** Indicates whether a job can be upgraded or not, e.g. Lite to Basic */
  canBeUpgraded: Scalars['Boolean']['output'];
  /** Indicates if the job ad can be extended or not. */
  canExtendAd: Scalars['Boolean']['output'];
  /**
   * Indicates if the job ad can be upgraded to a Premium job. (Used by CS)
   * @deprecated No longer used
   */
  canUpgradeToPremium?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The full name of the user who created the job.
   * May be `null` if the `postChannel` is `INDIRECT`.
   */
  createdBy?: Maybe<Scalars['String']['output']>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String']['output'];
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']['output']>;
  /**
   * The job ID.
   * The value may be `null` if the `status` is `PENDING`.
   */
  id?: Maybe<Scalars['String']['output']>;
  /** Indicates if the job ad is promoted (is a premium listing) or not. */
  isPromoted: Scalars['Boolean']['output'];
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']['output']>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   * @deprecated Use locations array instead to get more details
   */
  locationDisplayNames?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   */
  locations: Location[];
  /** Number of candidates. */
  numberOfCandidates: Scalars['Int']['output'];
  /** Number of new candidates. */
  numberOfNewCandidates: Scalars['Int']['output'];
  /**
   * Indicates the performance rating of the job. Includes UNAVAILABLE|WITHHELD to
   * cover more scenario with introduction of new markets
   */
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** The channel the job was posted from. This does not refer to which underlying system used to post the job. */
  postChannel: HirerJobPostChannel;
  /**
   * An indication on if the job is open or closed.
   * For more specific details on the wrapped job status, see the `job.status` field.
   */
  status: HirerJobStatus;
  /** Impersonation-aware url for the job on talent search. */
  talentSearchJobHref?: Maybe<Scalars['String']['output']>;
  /** Job title appearing on search results, job lists and candidate management pages. */
  title: Scalars['String']['output'];
}

export interface HirerJobConnection {
  __typename?: 'HirerJobConnection';
  /** The list of `HirerJob` edges for the current page. */
  edges: HirerJobEdge[];
  /**
   * The total number of hirer jobs across all pages.
   * The UI can use the `totalCount` and input `limit` to compute the total page count.
   */
  totalCount: Scalars['Int']['output'];
}

export interface HirerJobEdge {
  __typename?: 'HirerJobEdge';
  /** A `HirerJob` from the pagination query. */
  node?: Maybe<HirerJob>;
}

export enum HirerJobPostChannel {
  /** Jobs posted via the SEEK Employer Website. */
  DIRECT = 'DIRECT',
  /** Jobs posted using a third party system via the SEEK API. */
  INDIRECT = 'INDIRECT',
}

export enum HirerJobStatus {
  /** The job has been posted but stopped from going live (e.g. duplicate) */
  BLOCKED = 'BLOCKED',
  /** The job is no longer visible to candidates typically due to being expired. */
  CLOSED = 'CLOSED',
  /** The job has been posted but may or may not be visible to candidates due to the `JobStatus`. */
  OPEN = 'OPEN',
  /** The job has been posted but is not searchable or visible to candidates yet. */
  PENDING = 'PENDING',
}

export enum HirerJobStatusFilter {
  /** The job is no longer visible to candidates typically due to being expired. */
  CLOSED = 'CLOSED',
  /** The job has been posted but may or may not be visible to candidates due to the `JobStatus`. */
  OPEN = 'OPEN',
}

export interface HirerJobsInput {
  /**
   * The advertiser that owns the jobs.
   * @deprecated Use value from token.
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The advertiser country */
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  /** The language SEEK site is using */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** The maximum number of results per page. */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The channel the job was posted from.
   * When `null`, jobs for all relevant channel types are returned.
   */
  postChannel?: InputMaybe<HirerJobPostChannel>;
  /**
   * Fetch only open or closed jobs.
   * When `null`, open and closed jobs are returned.
   */
  status?: InputMaybe<HirerJobStatusFilter>;
  /** The job search term. */
  term?: InputMaybe<Scalars['String']['input']>;
}

export interface HirerPrimaryContact {
  __typename?: 'HirerPrimaryContact';
  /** Email address of the user */
  email: Scalars['String']['output'];
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
  /** Phone number of the contact */
  phone?: Maybe<Scalars['String']['output']>;
}

export enum HirerQuestionType {
  FreeText = 'FreeText',
  SingleSelect = 'SingleSelect',
}

export enum HirerType {
  BPO = 'BPO',
  CORPORATE = 'CORPORATE',
  GOVERNMENT = 'GOVERNMENT',
  JORA_LOCAL = 'JORA_LOCAL',
  RECRUITER = 'RECRUITER',
  SME = 'SME',
  SUBSCRIPTION_PARTNER = 'SUBSCRIPTION_PARTNER',
}

export interface HirerUserDetails {
  __typename?: 'HirerUserDetails';
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** Family name of the hirer user (formerly known as "Last Name" in ANZ systems) */
  familyName: Scalars['String']['output'];
  /** Given name of the hirer user (formerly known as "First Name" in ANZ Systems) */
  givenName: Scalars['String']['output'];
  /** The id of the hirer user (formerly known as "userId") */
  hirerUserId: Scalars['String']['output'];
  /** If the Hirer User is currently in a deactivated  / archived state */
  isArchived: Scalars['Boolean']['output'];
  /** Verification status of the hirer user - I.e. Whether they have "activated" themselves or are yet to click on the verification email */
  isVerified: Scalars['Boolean']['output'];
  /** Permissions of the hirer user on the hirer account */
  permissions: UserPermissions;
}

export interface HirerUserEmailFoundPayloadV2 {
  __typename?: 'HirerUserEmailFoundPayloadV2';
  /** Email address of the hirer user */
  email: Scalars['String']['output'];
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['output'];
  /**
   * If the Hirer User is currently in a deactivated  / archived state
   * Value will be null if the Hirer User does not exist in the database
   */
  isArchived: Scalars['Boolean']['output'];
  /**
   * Verification status of the hirer user - I.e. Whether they have "activated" themselves or are yet to click on the verification email
   * Value will be null if the Hirer User does not exist in the database
   */
  isVerified: Scalars['Boolean']['output'];
}

export interface HirerUserEmailInputV2 {
  /** Email address of the hirer user */
  email: Scalars['String']['input'];
}

export interface HirerUserEmailNotFoundPayloadV2 {
  __typename?: 'HirerUserEmailNotFoundPayloadV2';
  /**
   * message to return when we can't find the email in the Hirer Users system.
   * This is not a failure response as it is entirely possible to search for an
   * email that does not exist
   */
  message: Scalars['String']['output'];
}

export type HirerUserEmailPayloadV2 =
  | HirerUserEmailFoundPayloadV2
  | HirerUserEmailNotFoundPayloadV2
  | ResponseError;

export interface HirerUserQueryInput {
  /** ID used to uniquely identify the hirer user */
  hirerUserId: Scalars['String']['input'];
}

export interface Identity {
  __typename?: 'Identity';
  /** Operator making requests on behalf of user. */
  actor?: Maybe<Actor>;
  /**
   * Indicates that the user is CS operator, regardless of whether they're actively impersonating.
   * Either true or undefined.
   */
  allowImpersonation?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates that the CS operator has enabled administrator mode. Either true or undefined. */
  showCustomerServiceView?: Maybe<Scalars['Boolean']['output']>;
  /** User requesting access to a resource. */
  subject: Subject;
}

export interface IdentityHeaderContext {
  __typename?: 'IdentityHeaderContext';
  /** Details of the cs user making requests on behalf of user. */
  act?: Maybe<ActorContext>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
}

export interface IdentityHeaderContextactArgs {
  input?: InputMaybe<HeaderFooterContextInput>;
}

export interface Image {
  __typename?: 'Image';
  /** Description of an image */
  description: Scalars['String']['output'];
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String']['output'];
}

export interface InitiateConnectionPayload {
  __typename?: 'InitiateConnectionPayload';
  connectionId: Scalars['String']['output'];
}

export interface Integration {
  __typename?: 'Integration';
  partner: IntegrationPartner;
  relationships: IntegrationRelationship[];
}

export interface IntegrationConnection {
  __typename?: 'IntegrationConnection';
  edges: IntegrationEdge[];
  totalCount: Scalars['Int']['output'];
}

export interface IntegrationEdge {
  __typename?: 'IntegrationEdge';
  node: Integration;
}

export interface IntegrationPartner {
  __typename?: 'IntegrationPartner';
  configurableRelationships: IntegrationRelationshipType[];
  description?: Maybe<Scalars['String']['output']>;
  hasApacIntegration?: Maybe<Scalars['Boolean']['output']>;
  hasEnhancedIntegration?: Maybe<Scalars['Boolean']['output']>;
  hasMiddlewareRequirement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  releaseNotes?: Maybe<Scalars['String']['output']>;
  releaseNotesImageUrl?: Maybe<Scalars['String']['output']>;
  releaseNotesStatus?: Maybe<Scalars['String']['output']>;
  siteUrl?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

export interface IntegrationRelationship {
  __typename?: 'IntegrationRelationship';
  id: Scalars['ID']['output'];
  relationshipType: IntegrationRelationshipType;
}

export interface IntegrationRelationshipType {
  __typename?: 'IntegrationRelationshipType';
  name: Scalars['String']['output'];
  typeCode: IntegrationRelationshipTypeEnum;
}

export enum IntegrationRelationshipTypeEnum {
  APPLICATION_EXPORT = 'APPLICATION_EXPORT',
  APPLICATION_PREFILL = 'APPLICATION_PREFILL',
  JOB_POSTING = 'JOB_POSTING',
  PROACTIVE_SOURCING = 'PROACTIVE_SOURCING',
}

export interface InteractedJob {
  __typename?: 'InteractedJob';
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface InteractedJoblocationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface InvalidInputPayload {
  __typename?: 'InvalidInputPayload';
  /** Reason of invalid used input. i.e. 'Validation error: logo is required to create a brand' */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface Invoice {
  /** The ID of the invoice. */
  id: Scalars['ID']['input'];
  /** The status of the invoice. */
  invoiceStatus: InvoiceStatus;
}

export interface InvoiceData {
  __typename?: 'InvoiceData';
  invoices: InvoiceItem[];
  loadMoreCursor?: Maybe<Scalars['String']['output']>;
}

/** Error for invalid, expired, or not found invoices. */
export type InvoiceError = Error & {
  __typename?: 'InvoiceError';
  message: Scalars['String']['output'];
};

export interface InvoiceFiltersInput {
  cursor?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  invoiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<InvoiceStatus>;
}

export interface InvoiceInput {
  amount: Scalars['SafeInt']['input'];
  reference: Scalars['String']['input'];
}

export interface InvoiceItem {
  __typename?: 'InvoiceItem';
  amount: Scalars['Float']['output'];
  amountPayable?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  documentDate: Scalars['String']['output'];
  documentStatus: InvoiceStatus;
  documentType: Scalars['String']['output'];
  dueDate: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceStatus: Scalars['String']['output'];
  migrated: Scalars['Boolean']['output'];
  netAmount: Scalars['Float']['output'];
  netPayable?: Maybe<Scalars['Float']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
  payments: InvoicePayment[];
  taxAmount: Scalars['Float']['output'];
  taxPayable?: Maybe<Scalars['Float']['output']>;
  taxType: Scalars['String']['output'];
}

export interface InvoiceOrderNotFoundPayload {
  __typename?: 'InvoiceOrderNotFoundPayload';
  /** Requested OrderId could not be found. */
  reason?: Maybe<Scalars['String']['output']>;
}

export interface InvoiceOrderSuccessPayload {
  __typename?: 'InvoiceOrderSuccessPayload';
  responseStatus: Scalars['String']['output'];
}

export interface InvoicePayment {
  __typename?: 'InvoicePayment';
  ccLast4Digit: Scalars['String']['output'];
  chequeNo: Scalars['String']['output'];
  eftReference: Scalars['String']['output'];
  paymentAmount: Scalars['Float']['output'];
  paymentCardType: Scalars['String']['output'];
  paymentDate: Scalars['String']['output'];
  paymentDoc: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  paymentReference: Scalars['String']['output'];
}

export enum InvoiceStatus {
  /** The invoice is due. */
  due = 'due',
  /** The invoice is paid. */
  paid = 'paid',
  /** The invoice is pending. */
  pending = 'pending',
}

export interface Job {
  __typename?: 'Job';
  /** @deprecated Use `productDisplayName` instead and if empty fallback to `adType` */
  adType?: Maybe<JobAdType>;
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']['output']>;
  applicationFormUrl?: Maybe<Scalars['String']['output']>;
  billableApplicants: BillableApplicantsResponse;
  branding?: Maybe<Brand>;
  canBeUpgraded: Scalars['Boolean']['output'];
  categoryId?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String']['output'];
  /**
   * Expiry date/time in UTC (ISO 8601 format).
   * This may be null if the job is not live yet.
   */
  expiryDate?: Maybe<Scalars['String']['output']>;
  /**
   * The job ID.
   * This should contain the same values as `jobId` and will eventually replace the `jobId` field.
   */
  id: Scalars['String']['output'];
  /** Indicates whether this is a private ad */
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  jobDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `id` instead */
  jobId: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  /**
   * Location details resolved from the `locationId`
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   */
  locations: Location[];
  maxSalary?: Maybe<Scalars['SafeInt']['output']>;
  minSalary?: Maybe<Scalars['SafeInt']['output']>;
  payType?: Maybe<Scalars['String']['output']>;
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /**
   * The channel the job was posted from. This does not refer to which underlying system used to post the job.
   * Current values could be `DIRECT`, `INDIRECT` or `IMPORT`.
   */
  postChannel: Scalars['String']['output'];
  productDisplayName?: Maybe<Scalars['String']['output']>;
  /** Product features belonging to the job */
  productFeatures?: Maybe<CatalogueProductFeature[]>;
  questionnaireUrl?: Maybe<Scalars['String']['output']>;
  salaryCurrency?: Maybe<JobSalaryCurrency>;
  salaryText?: Maybe<Scalars['String']['output']>;
  status: JobStatus;
  subCategoryId?: Maybe<Scalars['String']['output']>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
  workType?: Maybe<WorkType>;
}

export interface JobAd {
  __typename?: 'JobAd';
  adRating?: Maybe<AdRatingType>;
  adType: Scalars['String']['output'];
  applications: Scalars['Int']['output'];
  groupKey: Scalars['String']['output'];
  hidePrice: Scalars['Boolean']['output'];
  isLatestGroup: Scalars['Boolean']['output'];
  isRefresh: Scalars['Boolean']['output'];
  isRepost: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  locationLabel: Scalars['String']['output'];
  otherBudgetAmount: Money;
  productDisplayName: Scalars['String']['output'];
  status: AdUsageAdStatus;
  totalNetAmount: Money;
  userFullName?: Maybe<Scalars['String']['output']>;
}

export enum JobAdBookingCountryCode {
  AU = 'AU',
  HK = 'HK',
  ID = 'ID',
  MY = 'MY',
  NZ = 'NZ',
  PH = 'PH',
  SG = 'SG',
  TH = 'TH',
}

export interface JobAdDetails {
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  subClassificationId: Scalars['String']['input'];
}

/** AdProductUpgrade feature that indicates the ability for editing the details of an existing ad. */
export type JobAdEditFeature = CatalogueProductFeature & {
  __typename?: 'JobAdEditFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface JobAdListResponse {
  __typename?: 'JobAdListResponse';
  count: Scalars['Int']['output'];
  hasMultipleCurrencies: Scalars['Boolean']['output'];
  jobAds?: Maybe<Array<Maybe<JobAd>>>;
  lastUpdated: Scalars['DateTime']['output'];
}

export interface JobAdListWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  pageNumber: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
}

export interface JobAdRevisionAttributes {
  /** Id of the existing Job Ad. */
  jobId: Scalars['String']['input'];
  /** Revision id of the existing Job Ad. */
  revisionId: Scalars['String']['input'];
}

export enum JobAdType {
  BRANDED = 'BRANDED',
  BRANDED_UPGRADE = 'BRANDED_UPGRADE',
  CLASSIC = 'CLASSIC',
  CONCIERGE = 'CONCIERGE',
  GUARANTEED = 'GUARANTEED',
  PREMIUM = 'PREMIUM',
}

export interface JobContentGenerationResponseSuccessPayload {
  __typename?: 'JobContentGenerationResponseSuccessPayload';
  id: Scalars['String']['output'];
  jobDescription: Scalars['String']['output'];
  jobSummary: Scalars['String']['output'];
}

/**
 * Providing `null` for a field will reset it.
 * This doesn't apply to fields that are required by Job Services such as `categories`, `locations`, and `title`. They will always maintain their original value unless a new value is provided.
 */
export interface JobDetailsInput {
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated branding info */
  branding?: InputMaybe<BrandingInput>;
  /** Updated category and subcategory */
  categories?: InputMaybe<CategoriesInput>;
  /** Indicates whether this is a private ad */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Updated job description */
  jobDescription?: InputMaybe<Scalars['String']['input']>;
  /** Updated key selling points */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Updated job location(s) */
  locations?: InputMaybe<LocationInput[]>;
  /** Updated salary information (incl. currency and range) */
  salary?: InputMaybe<SalaryInput>;
  /** Updated job summary */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Updated job title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Updated video url */
  videoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Updated work types */
  workTypes?: InputMaybe<WorkType[]>;
}

export enum JobSalaryCurrency {
  AUD = 'AUD',
  BDT = 'BDT',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  MYR = 'MYR',
  NZD = 'NZD',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
  USD = 'USD',
  VND = 'VND',
}

export enum JobStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  PENDING_ACCOUNT_APPROVAL = 'PENDING_ACCOUNT_APPROVAL',
  SUSPENDED = 'SUSPENDED',
}

export interface JobTitleOption {
  __typename?: 'JobTitleOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface LastUpdatedResponse {
  __typename?: 'LastUpdatedResponse';
  lastUpdated: Scalars['DateTime']['output'];
}

/** This specific type is used for order summary */
export type LineItemProduct = Product & {
  __typename?: 'LineItemProduct';
  /** Description of the line item */
  description: Scalars['String']['output'];
  /** Name used when displaying this product as a line item of a purchase */
  name: Scalars['String']['output'];
  /** Type of product purchased */
  productType: ProductType;
};

export interface Link {
  __typename?: 'Link';
  href: Scalars['String']['output'];
  rel: Scalars['String']['output'];
}

export interface LocalisedContactInfo {
  __typename?: 'LocalisedContactInfo';
  /** Business hours. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  businessHours?: Maybe<Scalars['String']['output']>;
  /** Contact number. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactNumber?: Maybe<Scalars['String']['output']>;
  /** Contact us link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  contactUsLink?: Maybe<Scalars['String']['output']>;
  /** Help centre link. Stored in hygraph. Falls back to `@seek/shared-constants` if not found. */
  helpCentreLink?: Maybe<Scalars['String']['output']>;
}

export interface LocalisedContactInfoContext {
  __typename?: 'LocalisedContactInfoContext';
  /** Business hours. Stored in hygraph. */
  businessHours?: Maybe<Scalars['String']['output']>;
  /** Contact number. Stored in hygraph. */
  contactNumber?: Maybe<Scalars['String']['output']>;
  /** Contact us link. Stored in hygraph. */
  contactUsLink?: Maybe<Scalars['String']['output']>;
  /** Help centre link. Stored in hygraph. */
  helpCentreLink?: Maybe<Scalars['String']['output']>;
}

export interface LocalisedContactInfoInput {
  locale: Scalars['String']['input'];
  site: Scalars['String']['input'];
}

export interface Location {
  __typename?: 'Location';
  country: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parent?: Maybe<Location>;
  type: Scalars['String']['output'];
}

export interface LocationdescriptionArgs {
  input?: InputMaybe<LocationDescriptionInput>;
}

export enum LocationContext {
  /** Uses JoB Posting Filter logic. This will be the default if context is not specified. */
  JOB_POSTING = 'JOB_POSTING',
  /** Uses Talent Search Filter logic. */
  TALENT_SEARCH = 'TALENT_SEARCH',
}

export interface LocationDescriptionInput {
  displayCountry: Scalars['String']['input'];
  language: Scalars['String']['input'];
}

export interface LocationInput {
  country: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayDescription: Scalars['String']['input'];
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
}

export interface LocationOption {
  __typename?: 'LocationOption';
  count: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
}

export interface LocationSearchResult {
  __typename?: 'LocationSearchResult';
  country: Scalars['String']['output'];
  countryId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated unified location service doesn't return this field */
  matchedSearchString?: Maybe<MatchedSearchString[]>;
  parents: Array<Maybe<LocationSearchResult>>;
  type: Scalars['String']['output'];
}

export interface LogoStrategies {
  __typename?: 'LogoStrategies';
  jdpLogo: Scalars['String']['output'];
  serpLogo: Scalars['String']['output'];
}

export interface LowApply {
  __typename?: 'LowApply';
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
}

export interface LowClick {
  __typename?: 'LowClick';
  averageConversionRate: Scalars['Float']['output'];
  conversionRate: Scalars['Float']['output'];
  type: RecommendationType;
}

export interface LowSalary {
  __typename?: 'LowSalary';
  benchmarkLocationLabel: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  normalisedRoleTitle: Scalars['String']['output'];
  salaryRangeMax: Scalars['Float']['output'];
  salaryRangeMin: Scalars['Float']['output'];
  salaryType: AnalyticsSalaryType;
  suggestedAverageRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedAverageRangeMin?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMax?: Maybe<Scalars['Int']['output']>;
  suggestedRangeMin?: Maybe<Scalars['Int']['output']>;
  type: RecommendationType;
}

export interface Maintenance {
  __typename?: 'Maintenance';
  disableMessage?: Maybe<DisableMessageType>;
  disablePayment?: Maybe<Scalars['Boolean']['output']>;
  paymentMedium?: Maybe<Array<Maybe<PaymentMediumType>>>;
  paymentMethod: Scalars['String']['output'];
  showMaintenance?: Maybe<Scalars['Boolean']['output']>;
}

export interface ManagingAgency {
  __typename?: 'ManagingAgency';
  /** Id of the agency. */
  advertiserId?: Maybe<Scalars['String']['output']>;
}

export interface MandatoryData {
  __typename?: 'MandatoryData';
  /** Tooltip-friendly answer text to display for mandatory questions */
  mandatoryAnswerText: Array<Scalars['String']['output']>;
}

export interface MarkApplicationsReadInput {
  prospectIds: Array<Scalars['ID']['input']>;
}

export interface MarkApplicationsReadPayload {
  __typename?: 'MarkApplicationsReadPayload';
  success: Scalars['Boolean']['output'];
}

export interface MarketInsightMonth {
  __typename?: 'MarketInsightMonth';
  calendarMonth: Scalars['String']['output'];
  count: Scalars['Int']['output'];
}

export interface MarketInsightsTitleResponse {
  __typename?: 'MarketInsightsTitleResponse';
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface MarketInsightsTitleWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface MarketPerformanceResponse {
  __typename?: 'MarketPerformanceResponse';
  highAds: Scalars['Int']['output'];
  lowAds: Scalars['Int']['output'];
  noRatingAds: Scalars['Int']['output'];
  normalAds: Scalars['Int']['output'];
  totalAds: Scalars['Int']['output'];
}

export interface MarketPerformanceWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface MatchedSearchString {
  __typename?: 'MatchedSearchString';
  matches?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']['output']>>>>>;
  text: Scalars['String']['output'];
}

export type MaximiseBranding = TipToImproveAds & {
  __typename?: 'MaximiseBranding';
  adCount: Scalars['Int']['output'];
  bulletPointsUsed: Scalars['Boolean']['output'];
  coverImageUsed: Scalars['Boolean']['output'];
  logoUsed: Scalars['Boolean']['output'];
  type: TipsToImproveAdsType;
};

export type MissingAdvertiserIdClaimError = Error & {
  __typename?: 'MissingAdvertiserIdClaimError';
  message: Scalars['String']['output'];
};

export type MissingUserIdClaimError = Error & {
  __typename?: 'MissingUserIdClaimError';
  message: Scalars['String']['output'];
};

export interface Money {
  __typename?: 'Money';
  currency: Currency;
  value: Scalars['SafeInt']['output'];
}

export interface MonthlyTrendsProductDisplayName {
  __typename?: 'MonthlyTrendsProductDisplayName';
  items?: Maybe<Array<Maybe<ProductDisplayNameOption>>>;
  month: Scalars['String']['output'];
}

export interface MostAdsByPerformanceCategory {
  __typename?: 'MostAdsByPerformanceCategory';
  classification: MostAdsByPerformanceEntity;
  user: MostAdsByPerformanceEntity;
}

export interface MostAdsByPerformanceEntity {
  __typename?: 'MostAdsByPerformanceEntity';
  label?: Maybe<Scalars['String']['output']>;
  number: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
}

export interface MostAdsByPerformanceResponse {
  __typename?: 'MostAdsByPerformanceResponse';
  high: MostAdsByPerformanceCategory;
  low: MostAdsByPerformanceCategory;
}

export interface MostAdsByPerformanceWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
}

export interface Mutation {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addOrRemoveProfileToTalentPools: Scalars['String']['output'];
  addProfileToTalentPool: TalentSearchPoolProfile;
  addUserToAccount?: Maybe<AddUserToAccountResponse>;
  applyCoverToAllBrands?: Maybe<Array<Maybe<Brand>>>;
  auditSuccessfulLogin: AuditSuccessfulLoginPayload;
  clearDefaultBrand?: Maybe<Brand>;
  completeAdditionalAdvertiserRegistration: SecureLinkResponse;
  completeUserRegistration: SecureLinkResponse;
  /** Request to confirm an invoice order. Returns request status. */
  confirmInvoiceOrder?: Maybe<ConfirmInvoiceOrderPayload>;
  /** Creates a Payment Intent and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntent: Create2c2pPaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntentForInvoices: Create2c2pPaymentIntentPayload;
  createApplicationNote: ApplicationNote;
  createBrand?: Maybe<CreateBrandPayload>;
  createCustomQuestion: CreateCustomQuestionOutputUnion;
  createDraftJob: CreateDraftJobPayload;
  createJob?: Maybe<CreateJobResponse>;
  createJobRevision: CreateJobRevisionPayload;
  /** Request to create a order from selected items. Returns Order. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Request to create an order with a promotion code applied. Returns Order. */
  createOrderWithPromotion?: Maybe<CreateOrderWithPromotionPayload>;
  createProductSetForAdEditing: CreateProductSetForAdEditingPayload;
  createProductSetForAdPosting: CreateProductSetForAdPostingPayload;
  createProductSetForAdRefresh: CreateProductSetForAdRefreshPayload;
  createProductSetForAdUpgradePurchase: CreateProductSetForAdUpgradePurchasePayload;
  createProductSetForBudgetsPurchaseForHirer: CreateProductSetForBudgetsPurchaseForHirerPayload;
  createProductSetForBudgetsPurchaseForVisitor: CreateProductSetForBudgetsPurchaseForVisitorPayload;
  /** createProductSet mutation used when posting a job to multiple locations */
  createProductSetForMultiLocation: CreateProductSetForMultiLocationPayload;
  createProductSetForPriceCheckerPreview: CreateProductSetForAdPostingPayload;
  createQuestionnaire?: Maybe<CreateQuestionnaireResponse>;
  createSavedSearch?: Maybe<CreateSavedSearchPayload>;
  /** Creates a Payment Intent and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntent: CreateStripePaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntentForInvoice: CreateStripePaymentIntentPayload;
  createTalentPool: TalentSearchPool;
  deleteApplicationAttachment: DeleteApplicationAttachmentPayload;
  deleteBrand?: Maybe<Brand>;
  deleteBrandingAsset?: Maybe<DeleteBrandingAssetPayload>;
  deleteDraftJob?: Maybe<DeleteDraftJobPayload>;
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  /** To delete a saved credit card using provided payment method id. */
  deleteStripeSavedCreditCard?: Maybe<DeleteStripeSavedCreditCardPayload>;
  deleteTalentPool: Scalars['String']['output'];
  editJob?: Maybe<EditJobResponse>;
  expireJob?: Maybe<ExpireJobResponse>;
  generateJobContent: GenerateJobContentResponsePayload;
  initiateAccessCv?: Maybe<InitiateConnectionPayload>;
  initiateExportProfile?: Maybe<InitiateConnectionPayload>;
  initiateSendJob?: Maybe<InitiateConnectionPayload>;
  initiateSendMessage?: Maybe<InitiateConnectionPayload>;
  markApplicationsRead: MarkApplicationsReadPayload;
  patchCompanyProfile?: Maybe<PatchCompanyProfileResponse>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCard?: Maybe<PayByStripeSavedCreditCardPayload>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCardForInvoice?: Maybe<PayByStripeSavedCreditCardPayload>;
  publishCPQAEvent?: Maybe<PublishCPQAEventPayload>;
  publishJobAdBookingEvent?: Maybe<PublishJobAdBookingEventPayload>;
  publishJobPostedEvent?: Maybe<PublishJobPostedEventPayload>;
  refreshJob?: Maybe<RefreshJobResponse>;
  registerAdditionalAdvertiser?: Maybe<RegisterPayload>;
  registerNewUser?: Maybe<RegisterPayload>;
  reinstate?: Maybe<ReinstateResponse>;
  removeApplicationNote: RemoveApplicationNotePayload;
  removeUserFromAccount?: Maybe<AccountDetailsUpdateResponse>;
  /** Creates relationships between a hirer and partner */
  requestIntegrationRelationships: RequestIntegrationRelationshipsPayload;
  resendAccountActivationEmail: ResendAccountActivationEmailResponse;
  sendAccessEvent: SendAccessEventPayload;
  sendApplicationEmail: ApplicationEmail;
  sendBulkApplicationEmail: SendBulkApplicationEmailPayload;
  /** Send a verification email to a hirer. */
  sendEmailVerificationEmail?: Maybe<SendEmailVerificationEmailPayload>;
  /** Send a enrolment to MFA email to a hirer. */
  sendEnrolmentEmail?: Maybe<SendEnrolmentEmailPayload>;
  /** Send email to hirer account users notifying they have access to a specific job */
  sendHirerAccountUsersJobAccessEmail?: Maybe<SendHirerAccountUsersJobAccessEmailPayload>;
  /** Track the details of a successful login which cannot be reliably tracked server-side. */
  sendLoginCallbackEvent?: Maybe<SendLoginCallbackEventPayload>;
  sendPasswordResetEmail: SendEmailResponse;
  sendRegistrationEmail: SendEmailResponse;
  setDefaultBrand?: Maybe<Brand>;
  /** Set default notification preference for current logged in user for a specific job */
  setDefaultNotificationPreference: NotificationPreference;
  /** Set job access for hirer account users for a specific job */
  setHirerAccountUsersJobAccess?: Maybe<SetHirerAccountUsersJobAccessPayload>;
  /** Set notification preferences for account users for a specific job */
  setNotificationPreferences: SetNotificationPreferencesPayload;
  shareTalentPool: Scalars['String']['output'];
  /**
   * Track they query text entered or chosen location id after a user has been presented with location suggestions.
   * By tracking the outcome of a location suggestion request the accuracy of location data in future can be improved.
   *
   * The sessionId should also be sent to the "locations" query so that tracking between the suggestions provided and the chosen
   * result can be completed.
   */
  trackLocationSuggestion: TrackLocationSuggestionPayload;
  triggerActivation: TriggerActivationPayload;
  updateAccountBillingAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountBillingEmailAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPostalAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPrimaryContact?: Maybe<AccountDetailsUpdateResponse>;
  updateApplicationRating: ApplicationRating;
  updateApplicationsStatus: UpdateApplicationsStatusPayload;
  updateBrand?: Maybe<UpdateBrandPayload>;
  updateDraftJob: UpdateDraftPayload;
  /** Update the status of one or more invoices. */
  updateInvoiceStatus: UpdateInvoiceStatusPayload;
  updateJobDetails: UpdateJobDetailsResponse;
  updateTalentPool: Scalars['String']['output'];
  updateUserDetails: UpdateUserDetailsResponse;
  updateUserPermissions?: Maybe<UpdateUserPermissionsResponse>;
  /** upsert review reply method means: if the review reply exists, update it, otherwise create it */
  upsertReviewReply: UpsertReviewReplyResponse;
}

export interface MutationaddOrRemoveProfileToTalentPoolsArgs {
  input: TalentSearchAddOrRemoveProfileTalentPoolsInput;
}

export interface MutationaddProfileToTalentPoolArgs {
  input?: InputMaybe<TalentSearchAddProfileToTalentPoolInput>;
}

export interface MutationaddUserToAccountArgs {
  input: AddUserToAccountInput;
}

export interface MutationapplyCoverToAllBrandsArgs {
  brandId: Scalars['String']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface MutationclearDefaultBrandArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface MutationcompleteAdditionalAdvertiserRegistrationArgs {
  input: SecureLinkInput;
}

export interface MutationcompleteUserRegistrationArgs {
  input: SecureLinkInput;
}

export interface MutationconfirmInvoiceOrderArgs {
  input: ConfirmInvoiceOrderInput;
}

export interface Mutationcreate2C2PPaymentIntentArgs {
  input: Create2C2PPaymentIntentInput;
}

export interface Mutationcreate2C2PPaymentIntentForInvoicesArgs {
  input: Create2C2PPaymentIntentForInvoiceInput;
}

export interface MutationcreateApplicationNoteArgs {
  input: CreateApplicationNoteInput;
}

export interface MutationcreateBrandArgs {
  input: CreateBrandInput;
}

export interface MutationcreateCustomQuestionArgs {
  input: CreateCustomQuestionInput;
}

export interface MutationcreateDraftJobArgs {
  input: CreateDraftJobInput;
}

export interface MutationcreateJobArgs {
  input?: InputMaybe<CreateJobInput>;
}

export interface MutationcreateJobRevisionArgs {
  input: CreateJobRevisionInput;
}

export interface MutationcreateOrderArgs {
  input: CreateOrderInput;
}

export interface MutationcreateOrderWithPromotionArgs {
  input: CreateOrderInput;
}

export interface MutationcreateProductSetForAdEditingArgs {
  input: CreateProductSetForAdEditingInput;
}

export interface MutationcreateProductSetForAdPostingArgs {
  input: CreateProductSetForAdPostingInput;
}

export interface MutationcreateProductSetForAdRefreshArgs {
  input: CreateProductSetForAdRefreshInput;
}

export interface MutationcreateProductSetForAdUpgradePurchaseArgs {
  input: CreateProductSetForAdUpgradePurchaseInput;
}

export interface MutationcreateProductSetForBudgetsPurchaseForVisitorArgs {
  input: CreateProductSetForBudgetsPurchaseForVisitorInput;
}

export interface MutationcreateProductSetForMultiLocationArgs {
  input: CreateProductSetForMultiLocationInput;
}

export interface MutationcreateProductSetForPriceCheckerPreviewArgs {
  input: CreateProductSetForPriceCheckerPreviewInput;
}

export interface MutationcreateQuestionnaireArgs {
  input: CreateQuestionnaireInput;
}

export interface MutationcreateSavedSearchArgs {
  input: CreateSavedSearchInput;
}

export interface MutationcreateStripePaymentIntentArgs {
  input: CreateStripePaymentIntentInput;
}

export interface MutationcreateStripePaymentIntentForInvoiceArgs {
  input: CreateStripePaymentIntentForInvoiceInput;
}

export interface MutationcreateTalentPoolArgs {
  input: TalentSearchCreateTalentPoolInput;
}

export interface MutationdeleteApplicationAttachmentArgs {
  input: DeleteApplicationAttachmentInput;
}

export interface MutationdeleteBrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface MutationdeleteBrandingAssetArgs {
  input: DeleteBrandingAssetInput;
}

export interface MutationdeleteDraftJobArgs {
  input: DeleteDraftJobInput;
}

export interface MutationdeleteSavedSearchArgs {
  criteriaHash: Scalars['String']['input'];
}

export interface MutationdeleteStripeSavedCreditCardArgs {
  input: DeleteStripeSavedCreditCardInput;
}

export interface MutationdeleteTalentPoolArgs {
  input: TalentSearchDeleteTalentPoolInput;
}

export interface MutationeditJobArgs {
  input?: InputMaybe<EditJobInput>;
}

export interface MutationexpireJobArgs {
  input?: InputMaybe<ExpireJobInput>;
}

export interface MutationgenerateJobContentArgs {
  input: GenerateJobContentInput;
}

export interface MutationinitiateAccessCvArgs {
  input: AccessCvInput;
}

export interface MutationinitiateExportProfileArgs {
  input: ExportProfileInput;
}

export interface MutationinitiateSendJobArgs {
  input: SendJobInput;
}

export interface MutationinitiateSendMessageArgs {
  input: SendMessageInput;
}

export interface MutationmarkApplicationsReadArgs {
  input: MarkApplicationsReadInput;
}

export interface MutationpatchCompanyProfileArgs {
  input: CompanyProfileInput;
}

export interface MutationpayByStripeSavedCreditCardArgs {
  input: PayByStripeSavedCreditCardInput;
}

export interface MutationpayByStripeSavedCreditCardForInvoiceArgs {
  input: PayByStripeSavedCreditCardForInvoiceInput;
}

export interface MutationpublishCPQAEventArgs {
  input: PublishCPQAEventInput;
}

export interface MutationpublishJobAdBookingEventArgs {
  input: PublishJobAdBookingEventInput;
}

export interface MutationpublishJobPostedEventArgs {
  input?: InputMaybe<PublishJobPostedEventInput>;
}

export interface MutationrefreshJobArgs {
  input?: InputMaybe<RefreshJobInput>;
}

export interface MutationregisterAdditionalAdvertiserArgs {
  input: RegisterAdditionalAdvertiserInput;
}

export interface MutationregisterNewUserArgs {
  input: RegisterNewUserInput;
}

export interface MutationreinstateArgs {
  input: ReinstateInput;
}

export interface MutationremoveApplicationNoteArgs {
  input: RemoveApplicationNoteInput;
}

export interface MutationremoveUserFromAccountArgs {
  input: RemoveUserFromAccountInput;
}

export interface MutationrequestIntegrationRelationshipsArgs {
  input: RequestIntegrationRelationshipsInput;
}

export interface MutationresendAccountActivationEmailArgs {
  input: ResendAccountActivationEmailInput;
}

export interface MutationsendApplicationEmailArgs {
  input: SendApplicationEmailInput;
}

export interface MutationsendBulkApplicationEmailArgs {
  input: SendBulkApplicationEmailInput;
}

export interface MutationsendHirerAccountUsersJobAccessEmailArgs {
  input: SendHirerAccountUsersJobAccessEmailInput;
}

export interface MutationsendPasswordResetEmailArgs {
  input: SendPasswordResetEmailInput;
}

export interface MutationsendRegistrationEmailArgs {
  input: SendRegistrationEmailInput;
}

export interface MutationsetDefaultBrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface MutationsetDefaultNotificationPreferenceArgs {
  input: SetDefaultNotificationPreferenceInput;
}

export interface MutationsetHirerAccountUsersJobAccessArgs {
  input: SetHirerAccountUsersJobAccessInput;
}

export interface MutationsetNotificationPreferencesArgs {
  input: SetNotificationPreferencesInput;
}

export interface MutationshareTalentPoolArgs {
  input: TalentSearchShareTalentPoolInput;
}

export interface MutationtrackLocationSuggestionArgs {
  input: TrackLocationSuggestionInput;
}

export interface MutationupdateAccountBillingAddressArgs {
  input?: InputMaybe<AccountBillingAddressInput>;
}

export interface MutationupdateAccountBillingEmailAddressArgs {
  input?: InputMaybe<AccountBillingEmailAddressInput>;
}

export interface MutationupdateAccountPostalAddressArgs {
  input?: InputMaybe<AccountPostalAddressInput>;
}

export interface MutationupdateAccountPrimaryContactArgs {
  input?: InputMaybe<AccountPrimaryContactInput>;
}

export interface MutationupdateApplicationRatingArgs {
  input: UpdateApplicationRatingInput;
}

export interface MutationupdateApplicationsStatusArgs {
  input: UpdateApplicationsStatusInput;
}

export interface MutationupdateBrandArgs {
  input: UpdateBrandInput;
}

export interface MutationupdateDraftJobArgs {
  input: UpdateDraftJobInput;
}

export interface MutationupdateInvoiceStatusArgs {
  input: UpdateInvoiceStatusInput;
}

export interface MutationupdateJobDetailsArgs {
  input: UpdateJobDetailsInput;
}

export interface MutationupdateTalentPoolArgs {
  input: TalentSearchUpdateTalentPoolInput;
}

export interface MutationupdateUserDetailsArgs {
  input: UserDetailsInput;
}

export interface MutationupdateUserPermissionsArgs {
  input: UpdateUserPermissionsInput;
}

export interface MutationupsertReviewReplyArgs {
  input: UpsertReviewReplyInput;
}

export interface MyJobInformationJobEdit {
  __typename?: 'MyJobInformationJobEdit';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timestampUTC: Scalars['DateTime']['output'];
}

export interface MyJobInformationResponse {
  __typename?: 'MyJobInformationResponse';
  advertiserName: Scalars['String']['output'];
  analyticsProductDisplayName: Scalars['String']['output'];
  applyUrl?: Maybe<Scalars['String']['output']>;
  classificationName: Scalars['String']['output'];
  createdByEmail: Scalars['String']['output'];
  createdByName: Scalars['String']['output'];
  isLinkOut: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  latestEdit?: Maybe<MyJobInformationJobEdit>;
  mirrorClassificationName?: Maybe<Scalars['String']['output']>;
  mirrorSubClassificationName?: Maybe<Scalars['String']['output']>;
  postTypeCategory: AnalyticsPostTypeCategory;
  productDisplayName: Scalars['String']['output'];
  salary: MyJobInformationSalary;
  subClassificationName: Scalars['String']['output'];
  workType: AnalyticsWorkType;
}

export interface MyJobInformationSalary {
  __typename?: 'MyJobInformationSalary';
  additionalText?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  maximum: Scalars['Float']['output'];
  minimum: Scalars['Float']['output'];
  type?: Maybe<AnalyticsSalaryType>;
}

export interface MyJobInformationWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface NewAdvertiser {
  /** Business name. */
  businessName: Scalars['String']['input'];
  /** Business phone number. */
  businessPhoneNumber: Scalars['String']['input'];
  /** Billing country. */
  country: Scalars['String']['input'];
  /** Indicates whether the employer is an agency account. */
  isAgency?: InputMaybe<Scalars['Boolean']['input']>;
  /** The zone where the user registered. */
  originZone?: InputMaybe<Scalars['String']['input']>;
}

export interface NewUser {
  /** Given name */
  firstName: Scalars['String']['input'];
  /** Family name */
  lastName: Scalars['String']['input'];
  /** The audience zone of the site that this user originally registered on */
  originZone?: InputMaybe<Scalars['String']['input']>;
}

export interface NotificationPreference {
  __typename?: 'NotificationPreference';
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: Maybe<Scalars['String']['output']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['output'];
}

export interface NotificationPreferenceInput {
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: InputMaybe<Scalars['String']['input']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID']['input'];
}

export interface Option {
  /** Option Order */
  order: Scalars['Int']['input'];
  /** Option Text */
  text: Scalars['String']['input'];
}

export interface OptionWithId {
  __typename?: 'OptionWithId';
  /** Option Id */
  id: Scalars['ID']['output'];
  /** Option Order */
  order: Scalars['Int']['output'];
  /** Option Text */
  text: Scalars['String']['output'];
}

export interface Order {
  __typename?: 'Order';
  orderId: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
}

/** Error for order that has past expiry date. */
export type OrderExpiredError = Error & {
  __typename?: 'OrderExpiredError';
  message: Scalars['String']['output'];
};

/** Error for order without expiryTime or paymentBreakdown, any status not in pending, or an incomplete priced order. */
export type OrderInvalidError = Error & {
  __typename?: 'OrderInvalidError';
  message: Scalars['String']['output'];
};

export interface OrderItem {
  productReference?: InputMaybe<Scalars['String']['input']>;
  productType: ProductType;
}

export interface OrderLineItem {
  __typename?: 'OrderLineItem';
  /** The full name of the country (eg. Australia, Hong Kong) that the order item is relevant to. Localised if locale is provided. */
  country?: Maybe<Scalars['String']['output']>;
  /** Drawdown items of the order item. */
  drawdownItems: DrawdownItem[];
  /** Drawdown type of the order item. */
  drawdownType: DrawdownType;
  /** Price of the order item. */
  price: Price;
  pricing?: Maybe<Pricing>;
  /** Reference to product. Eg JobAd, AdPack, AdBudget. */
  product: Product;
}

/** Error for order not found. */
export type OrderNotFoundError = Error & {
  __typename?: 'OrderNotFoundError';
  message: Scalars['String']['output'];
};

export interface OrderProductAttributesInput {
  /** Required input attributes if product selected is an Ad Budget. */
  adBudget?: InputMaybe<AdBudgetAttributes>;
  catalogueProduct?: InputMaybe<CatalogueProductAttributes>;
  /** Required input attributes if product selected is a Job Ad. */
  jobAd?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Edit. */
  jobAdEdit?: InputMaybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Extend. */
  jobAdExtend?: InputMaybe<JobAdRevisionAttributes>;
}

export interface OrderRequestInputItem {
  /** Required attributes associated to the product. Key name to attributes must match product type. Eg. jobAd for JOB_AD, adBudget for AD_BUDGET. */
  productAttributes: OrderProductAttributesInput;
  /** Type of product. Eg. Jod ad, Job ad edit, Ad budget */
  productType: ProductTypeInput;
}

export interface OrderSummary {
  __typename?: 'OrderSummary';
  /** All the line items of the order summary. */
  lineItems: OrderLineItem[];
  /** The ID of the entity the order summary is built on. */
  orderId: Scalars['ID']['output'];
  /** This is the sum of the line item prices. The subTotal does not include taxes. */
  subTotal: Money;
  /** Optional surcharges that can be applied to the order. The order total does not include surcharges. */
  surcharges: Surcharge[];
  /** The tax of the order summary. */
  tax?: Maybe<Tax>;
  /** Total price of the order summary. Includes taxes. */
  total: Money;
}

export interface OutputCustomQuestion {
  __typename?: 'OutputCustomQuestion';
  /** Candidate Answer Type e.g SingleSelect, FreeText */
  answerType: CandidateAnswerType;
  /** Question Id */
  id: Scalars['ID']['output'];
  /** Options */
  options: OptionWithId[];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Question Text */
  text: Scalars['String']['output'];
}

export interface PageInfo {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
}

export interface PageTitleActionsResponse {
  __typename?: 'PageTitleActionsResponse';
  adType: Scalars['String']['output'];
  advertiserName: Scalars['String']['output'];
  classificationName: Scalars['String']['output'];
  displayCurrency?: Maybe<Scalars['String']['output']>;
  isExpired: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobExpiryTimestampUTC?: Maybe<Scalars['DateTime']['output']>;
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobSummary?: Maybe<Scalars['String']['output']>;
  jobTitle: Scalars['String']['output'];
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastUpdatedTimestampUTC?: Maybe<Scalars['String']['output']>;
  latestJobs: AnalyticsLatestJobs[];
  postTypeCategory: AnalyticsPostTypeCategory;
  salaryAdditionalText?: Maybe<Scalars['String']['output']>;
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface PageTitleActionsWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface Parent {
  __typename?: 'Parent';
  advertiserId?: Maybe<Scalars['ID']['output']>;
  nation?: Maybe<Scalars['String']['output']>;
}

export interface PatchCompanyProfileResponse {
  __typename?: 'PatchCompanyProfileResponse';
  id: Scalars['String']['output'];
}

export interface PayByStripeSavedCreditCardForInvoiceInput {
  /** Nation of the advertiser. */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** The email of the advertiser. */
  email: Scalars['String']['input'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: InputMaybe<Scalars['Int']['input']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: InvoiceInput[];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: InputMaybe<Currency>;
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
  /** The reference of the invoice */
  prn: Scalars['String']['input'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String']['input'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent: Scalars['SafeInt']['input'];
}

export interface PayByStripeSavedCreditCardInput {
  /** Nation of the advertiser. */
  advertiserNation?: InputMaybe<Scalars['String']['input']>;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the order. */
  orderId: Scalars['ID']['input'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String']['input'];
}

export type PayByStripeSavedCreditCardPayload =
  | OrderExpiredError
  | OrderInvalidError
  | OrderNotFoundError
  | PromotionInvalidError
  | SavedCreditCardPaymentError
  | SavedCreditCardPaymentSuccessPayload;

export interface Payment2c2pInquiry {
  __typename?: 'Payment2c2pInquiry';
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /** Status of payment using 2c2p gateway */
  status?: Maybe<Payment2c2pInquiryStatus>;
}

/** Error for 2c2p payment inquiry */
export type Payment2c2pInquiryError = Error & {
  __typename?: 'Payment2c2pInquiryError';
  message: Scalars['String']['output'];
};

export type Payment2c2pInquiryPayload =
  | Payment2c2pInquiry
  | Payment2c2pInquiryError;

export enum Payment2c2pInquiryStatus {
  cancelled = 'cancelled',
  pending = 'pending',
  processing = 'processing',
  succeeded = 'succeeded',
}

export interface PaymentConfigPayload {
  __typename?: 'PaymentConfigPayload';
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
}

export interface PaymentIntent2C2PPayload {
  __typename?: 'PaymentIntent2C2PPayload';
  expiryTimer?: Maybe<Scalars['String']['output']>;
  paymentLink?: Maybe<Scalars['String']['output']>;
  paymentToken?: Maybe<Scalars['String']['output']>;
}

/** Error for payment intent failed to generate */
export type PaymentIntentChargeError = Error & {
  __typename?: 'PaymentIntentChargeError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export interface PaymentMediumType {
  __typename?: 'PaymentMediumType';
  disablePayment: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
}

export enum PaymentMethod {
  CONTRACT = 'CONTRACT',
  CREDIT_CARD = 'CREDIT_CARD',
  INVOICE = 'INVOICE',
}

/** Error for payment method not supported for the currency */
export type PaymentMethodError = Error & {
  __typename?: 'PaymentMethodError';
  message: Scalars['String']['output'];
};

export interface PaymentMethodPayload {
  __typename?: 'PaymentMethodPayload';
  maintenance: Maintenance;
  paymentMethods?: Maybe<Array<Maybe<Maintenance>>>;
}

export enum PaymentMethodType2C2P {
  BNPL = 'BNPL',
  DPAY = 'DPAY',
  QR = 'QR',
  WEBPAY = 'WEBPAY',
}

export interface PendingIntegrationRelationship {
  __typename?: 'PendingIntegrationRelationship';
  hirerAccountId: Scalars['ID']['output'];
  hirerOid: Scalars['String']['output'];
  relationship: IntegrationRelationshipType;
}

/** PerApplicantBilling feature to indicate CPQA product */
export type PerApplicantBillingFeature = CatalogueProductFeature & {
  __typename?: 'PerApplicantBillingFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** PerUnitBilling feature to indicate CPQA product */
export type PerUnitBillingFeature = CatalogueProductFeature & {
  __typename?: 'PerUnitBillingFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export interface PerformancePredictionChartEvent {
  __typename?: 'PerformancePredictionChartEvent';
  comparisonValue: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  day: Scalars['Int']['output'];
  editMade: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
}

export interface PerformancePredictionResponse {
  __typename?: 'PerformancePredictionResponse';
  events?: Maybe<Array<Maybe<PerformancePredictionChartEvent>>>;
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  predictionDate?: Maybe<Scalars['Date']['output']>;
  predictionDayOptions: PredictionDayOptions[];
  valueLabel?: Maybe<CandidateLabel>;
  xAxisLabels?: Maybe<DateAxisLabel[]>;
  xRange?: Maybe<Array<Scalars['Date']['output']>>;
  yRange?: Maybe<Array<Scalars['Int']['output']>>;
}

export interface PerformancePredictionWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export enum Permission {
  createJobs = 'createJobs',
  manageBrand = 'manageBrand',
  manageUsers = 'manageUsers',
  payInvoices = 'payInvoices',
  viewInvoiceHistory = 'viewInvoiceHistory',
  viewManagerReports = 'viewManagerReports',
}

export interface PermissionOptions {
  __typename?: 'PermissionOptions';
  canCreateJobs: Scalars['Boolean']['output'];
  canManageBrand: Scalars['Boolean']['output'];
  canManageCompanyProfile: Scalars['Boolean']['output'];
  canManageUsers: Scalars['Boolean']['output'];
  canPayInvoices: Scalars['Boolean']['output'];
  canViewInvoiceHistory: Scalars['Boolean']['output'];
  canViewManagerReports: Scalars['Boolean']['output'];
}

export interface PhysicalAddress {
  __typename?: 'PhysicalAddress';
  /** Address line 1 */
  addressLine1?: Maybe<Scalars['String']['output']>;
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** All address lines */
  addressLines?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Name of the Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Post code */
  postCode?: Maybe<Scalars['String']['output']>;
  /** State */
  state?: Maybe<Scalars['String']['output']>;
  /** Suburb */
  suburb?: Maybe<Scalars['String']['output']>;
}

export interface PredictionDayOptions {
  __typename?: 'PredictionDayOptions';
  prediction: Scalars['Int']['output'];
  text: Scalars['String']['output'];
}

export interface Price {
  __typename?: 'Price';
  /** Cost per applicant price for CPQA product. */
  costPerApplicant?: Maybe<Money>;
  discount?: Maybe<Discount>;
  isVariable?: Maybe<Scalars['Boolean']['output']>;
  netPrice: Money;
  retailPrice: Money;
  /** Tax data for the retail price. */
  retailTax?: Maybe<Tax>;
  /** Tax data for the net price. */
  tax?: Maybe<Tax>;
}

export interface PriceAttributes {
  locationId?: InputMaybe<Scalars['Float']['input']>;
  roleTitle?: InputMaybe<Scalars['String']['input']>;
  subclassificationId?: InputMaybe<Scalars['Float']['input']>;
}

export interface PriceType {
  __typename?: 'PriceType';
  type: Scalars['String']['output'];
}

export enum PriceVisibility {
  HIDE_PRICE = 'HIDE_PRICE',
  SHOW_DISCOUNTED_PRICE = 'SHOW_DISCOUNTED_PRICE',
  SHOW_RETAIL_PRICE = 'SHOW_RETAIL_PRICE',
}

export type Pricing = CappedUnitPrice | PriceType;

export enum PricingModel {
  FIXED = 'FIXED',
  HORIZON = 'HORIZON',
}

export interface PrimaryContact {
  __typename?: 'PrimaryContact';
  /** Email address of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the user */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the advertiser collected on registration form */
  phoneNumber?: Maybe<Scalars['String']['output']>;
}

export enum PrivacyTypes {
  limited = 'limited',
  standard = 'standard',
}

export interface Product {
  name: Scalars['String']['output'];
  productType: ProductType;
}

export interface ProductDisplayNameAverageCost {
  __typename?: 'ProductDisplayNameAverageCost';
  cost: Money;
  type: Scalars['String']['output'];
}

export interface ProductDisplayNameOption {
  __typename?: 'ProductDisplayNameOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
}

export interface ProductDisplayNamePostCount {
  __typename?: 'ProductDisplayNamePostCount';
  adsCount: Scalars['Int']['output'];
  type: Scalars['String']['output'];
}

export interface ProductFeature {
  __typename?: 'ProductFeature';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface ProductList {
  __typename?: 'ProductList';
  /** A list of all catalogue products in the product set */
  items: CatalogueProduct[];
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: CatalogueProduct[];
}

export interface ProductReference {
  __typename?: 'ProductReference';
  /** Unique ID of the product reference that references to an addon/upgrade */
  id: Scalars['ID']['output'];
}

export interface ProductSet {
  __typename?: 'ProductSet';
  /** Unique ID of the product set */
  id: Scalars['ID']['output'];
  /** A list of all catalogue products in the product set */
  items: Array<Maybe<CatalogueProduct>>;
}

export type ProductSetExpiredError = Error & {
  __typename?: 'ProductSetExpiredError';
  message: Scalars['String']['output'];
};

export type ProductSetNotFoundError = Error & {
  __typename?: 'ProductSetNotFoundError';
  message: Scalars['String']['output'];
};

export enum ProductType {
  AD_BUDGET = 'AD_BUDGET',
  CATALOGUE_PRODUCT = 'CATALOGUE_PRODUCT',
  JOB_AD = 'JOB_AD',
  JOB_AD_EDIT = 'JOB_AD_EDIT',
  JOB_AD_EXTEND = 'JOB_AD_EXTEND',
  JOB_AD_UPGRADE = 'JOB_AD_UPGRADE',
}

export enum ProductTypeInput {
  AD_BUDGET = 'AD_BUDGET',
  CATALOGUE_PRODUCT = 'CATALOGUE_PRODUCT',
  JOB_AD = 'JOB_AD',
  JOB_AD_EDIT = 'JOB_AD_EDIT',
  JOB_AD_EXTEND = 'JOB_AD_EXTEND',
}

export type ProfileEducation = Verification & {
  __typename?: 'ProfileEducation';
  completionMonth: Scalars['Int']['output'];
  completionYear: Scalars['Int']['output'];
  institutionName: Scalars['String']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  qualificationName: Scalars['String']['output'];
  verifiedFields?: Maybe<VerifiedField[]>;
};

export type ProfileLicence = Verification & {
  __typename?: 'ProfileLicence';
  expiryDate?: Maybe<Scalars['String']['output']>;
  isExpired: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  issuingOrganisationName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  verifiedFields?: Maybe<VerifiedField[]>;
};

export interface ProfileProperties {
  __typename?: 'ProfileProperties';
  ExpressCajaOptOut?: Maybe<Scalars['String']['output']>;
  HasLoggedInBefore?: Maybe<Scalars['String']['output']>;
  HasOnlyUsedExpressCaja?: Maybe<Scalars['String']['output']>;
  HasSeenGuidedCallOut?: Maybe<Scalars['String']['output']>;
  HasSeenJobDetailsTour?: Maybe<Scalars['String']['output']>;
  UserStatusBeforeDeactivation?: Maybe<Scalars['String']['output']>;
}

export interface ProfileResultHighlight {
  __typename?: 'ProfileResultHighlight';
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  skills?: Maybe<Array<Scalars['String']['output']>>;
}

export interface ProfileSalaryV2 {
  __typename?: 'ProfileSalaryV2';
  expected?: Maybe<ExpectedSalaryV2>;
}

export enum ProfileSource {
  combined = 'combined',
  seek = 'seek',
}

export enum ProfileTypes {
  combined = 'combined',
  partner = 'partner',
  seek = 'seek',
  shared = 'shared',
}

export interface ProfilesByEmailInput {
  /** @deprecated This is not required because we rely on advertiserId from the header */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}

export interface PromoCode {
  __typename?: 'PromoCode';
  /** The campaign that the promo code belongs to */
  campaignId: Scalars['ID']['output'];
  /** The promo code value that a hirer can use on a product in order to get a discount */
  code: Scalars['String']['output'];
  /** The date and time when the promo code was created */
  createdAt: Scalars['DateTime']['output'];
  /** The discount amount or rate on a product after the promo code has been applied. Currently only applicable to discount rate */
  discount: PromoCodeDiscount;
  /** The date and time when the promo code was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The date and time when the promo code is valid from */
  validFrom: Scalars['DateTime']['output'];
  /** The products that the promo code can be applied to */
  validProducts: PromoCodeProduct[];
  /** The date and time when the promo code is valid to */
  validTo: Scalars['DateTime']['output'];
}

export type PromoCodeDiscount = PromoCodeDiscountRate;

export interface PromoCodeDiscountRate {
  __typename?: 'PromoCodeDiscountRate';
  /** The promo code discount rate in percentage up to two decimal places eg. 99.99 is equivalent to 99.99% */
  discountRate: Scalars['Float']['output'];
}

export interface PromoCodeProduct {
  __typename?: 'PromoCodeProduct';
  /** The product display name */
  displayName: Scalars['String']['output'];
  /** The product id */
  id: Scalars['ID']['output'];
}

export interface Promotion {
  __typename?: 'Promotion';
  code?: Maybe<Scalars['String']['output']>;
}

/** Error for invalid, expired, or not found promotion code. */
export type PromotionInvalidError = Error & {
  __typename?: 'PromotionInvalidError';
  message: Scalars['String']['output'];
};

export interface PublishCPQAEventInput {
  /** Advertiser name */
  accountName: Scalars['String']['input'];
  /** The job title */
  roleTitle: Scalars['String']['input'];
}

export interface PublishCPQAEventPayload {
  __typename?: 'PublishCPQAEventPayload';
  published?: Maybe<Scalars['Boolean']['output']>;
}

export interface PublishJobAdBookingEventInput {
  /**
   * The Seek advertiser/account id
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the company Hirer recruiting for */
  companyName: Scalars['String']['input'];
  /** The contact by time in UTC */
  contactBy: Scalars['String']['input'];
  /** The contact name for this particular request */
  contactName: Scalars['String']['input'];
  /** The Job Category ID */
  jobCategorySubClassificationId: Scalars['String']['input'];
  /** Two digit ISO country for location of job ad. */
  jobCountryCode: JobAdBookingCountryCode;
  /** The job location */
  jobLocation?: InputMaybe<Scalars['String']['input']>;
  /** The Job Location ID */
  jobLocationId: Scalars['String']['input'];
  /** The job title */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** The phone number where CS can contact Hirer on */
  phoneNumber: Scalars['String']['input'];
  /** The product ID */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** The user email */
  userEmail: Scalars['String']['input'];
  /** Country code for the website where customer made the request. */
  websiteCountryCode: JobAdBookingCountryCode;
}

export interface PublishJobAdBookingEventPayload {
  __typename?: 'PublishJobAdBookingEventPayload';
  published?: Maybe<Scalars['Boolean']['output']>;
}

export enum PublishJobAdProductCode {
  /** The product we offer in AU, GUARANTEED_HIRE */
  AUSKGHRB = 'AUSKGHRB',
  /** The product we offer in NZ, CONCIERGE */
  CONCIERGE = 'CONCIERGE',
}

export interface PublishJobPostedEventInput {
  /**
   * The ID of the draft in Adcentre
   * @deprecated Use `draftId` instead
   */
  adcentreDraftId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the listing in Adcentre */
  adcentreListingId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the user in Adcentre */
  adcentreUserId: Scalars['Int']['input'];
  /**
   * The ID of the advertiser
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** The type of device used by the hirer to post their job */
  device: Scalars['String']['input'];
  /**
   * TODO: Make this below mandatory once frontend updated to post it
   * and remove the above adcentreDraftId
   */
  draftId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment being run while this job was posted */
  experimentId?: InputMaybe<Scalars['String']['input']>;
  /** The experiment variant being run while this job was posted */
  experimentVariantId?: InputMaybe<Scalars['String']['input']>;
  /** Flow used to create/update the job, e.g. refresh, edit, create */
  flowType?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the job */
  jobId: Scalars['String']['input'];
  /** The experience the hirer used to post their job */
  jobPostingExperience: Scalars['String']['input'];
  /** The job writing flow that hirer has followed (guided or standard) */
  jobWritingFlow: Scalars['String']['input'];
}

export interface PublishJobPostedEventPayload {
  __typename?: 'PublishJobPostedEventPayload';
  jobId: Scalars['String']['output'];
}

export type PurchasableProducts =
  | PurchasableProductsList
  | PurchasableProductsString;

export interface PurchasableProductsList {
  __typename?: 'PurchasableProductsList';
  products?: Maybe<Array<Scalars['String']['output']>>;
}

export interface PurchasableProductsString {
  __typename?: 'PurchasableProductsString';
  product: Scalars['String']['output'];
}

export enum PurchaseContext {
  DIRECT_AD_PRODUCT_EDIT = 'DIRECT_AD_PRODUCT_EDIT',
  DIRECT_AD_PRODUCT_UPGRADE_EDIT = 'DIRECT_AD_PRODUCT_UPGRADE_EDIT',
}

export interface Query {
  __typename?: 'Query';
  /** Retrieves a list of credit cards for a particular user against an advertiser account either in 'AU' or 'NZ' country codes. */
  SavedStripeCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
  _empty?: Maybe<Scalars['String']['output']>;
  accountSelectionV2: AccountSelectionV2Response;
  /** Query to get ad budget products for upselling given list of product ids */
  adBudgetProductsForUpsell: AdBudgetProductsForUpsellResponse;
  /** Request to get adProducts for Ad Posting */
  adProductsForAdPosting: AdProductSetResponse;
  /** Ad Performance Report query */
  analyticsAdPerformanceReport?: Maybe<AdPerformanceReportResponse>;
  /** Ad Rating query */
  analyticsAdRating?: Maybe<AdRatingResponse>;
  /** Ad Usage Trend ad type daily and monthly widget */
  analyticsAdTypeTrend?: Maybe<AdUsageAdTypeTrendResponse>;
  /** Ad Usage Conversion query */
  analyticsAdUsageConversion?: Maybe<AdUsageConversionResponse>;
  /** Ad Usage Filters query */
  analyticsAdUsageFilters?: Maybe<AdUsageFiltersResponse>;
  /** Ad Usage Last Updated query */
  analyticsAdUsageLastUpdated?: Maybe<LastUpdatedResponse>;
  /** Ad Usage Spend query */
  analyticsAdUsageSpend?: Maybe<AdUsageSpendResponse>;
  /** Ad Usage Tips widget */
  analyticsAdUsageTipsToImproveAds?: Maybe<TipsToImproveAdsResponse>;
  /** Application Performance query */
  analyticsApplicationPerformance?: Maybe<ApplicationPerformanceResponse>;
  /** Candidate Demand query */
  analyticsCandidateDemand?: Maybe<CandidateDemandResponse>;
  /** Candidate Supply query */
  analyticsCandidateSupply?: Maybe<CandidateSupplyResponse>;
  /** Ad Usage Child Account Banner query */
  analyticsChildAccountBanner?: Maybe<ChildAccountBannerResponse>;
  /** Ad Usage Trend classification and user widget */
  analyticsClassificationsAndUsersTrend?: Maybe<AdUsageTrendClassificationsAndUsersResponse>;
  /** Clicks on your ad query */
  analyticsClicksOnYourAd?: Maybe<ClicksOnYourAdResponse>;
  /** Job Ad List query */
  analyticsJobAdList?: Maybe<JobAdListResponse>;
  /** Market insights title query */
  analyticsMarketInsightsTitle?: Maybe<MarketInsightsTitleResponse>;
  /** Market Performance query */
  analyticsMarketPerformance?: Maybe<MarketPerformanceResponse>;
  /** Ad Usage Most Ads By Performance query */
  analyticsMostAdsByPerformance?: Maybe<MostAdsByPerformanceResponse>;
  /** Ad Details query */
  analyticsMyJobInformation?: Maybe<MyJobInformationResponse>;
  /** Page Title Actions query */
  analyticsPageTitleActions?: Maybe<PageTitleActionsResponse>;
  /** Performance Prediction query */
  analyticsPerformancePrediction?: Maybe<PerformancePredictionResponse>;
  /** Recommendations query */
  analyticsRecommendations?: Maybe<RecommendationsResponse>;
  /** Similar Ads query */
  analyticsSimilarAdsDetails?: Maybe<SimilarAdsResponse>;
  analyticsTalentSearchUsageConversion: AnalyticsTalentSearchUsageConversionResponse;
  analyticsTalentSearchUsageFilters?: Maybe<AnalyticsTalentSearchUsageFiltersResponse>;
  analyticsTalentSearchUsageLastUpdated?: Maybe<AnalyticsTalentSearchUsageLastUpdatedResponse>;
  analyticsTalentSearchUsageOverview: AnalyticsTalentSearchUsageOverviewResponse;
  analyticsTalentSearchUsageTrendsByAccount: AnalyticsTalentSearchUsageTrendsByAccountResponse;
  analyticsTalentSearchUsageTrendsByTime: AnalyticsTalentSearchUsageTrendsByTimeResponse;
  analyticsTalentSearchUsageUsersList?: Maybe<AnalyticsTalentSearchUsageUsersListResponse>;
  /** Views in Job Search query */
  analyticsViewsInJobSearch?: Maybe<ViewsInJobSearchResponse>;
  applicationAttachments: ApplicationAttachments;
  applicationEmails: ApplicationEmails;
  applicationNotes: ApplicationNotes;
  applicationsFacets: ApplicationsFacetsResponse;
  brand?: Maybe<Brand>;
  brandingAssets?: Maybe<Array<Maybe<BrandingAsset>>>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileIds?: Maybe<CompanyProfileIds>;
  contractBalances: ContractBalance[];
  countrySelectionOptions: CountrySelectionOptions;
  currentAdvertiser: CurrentAdvertiserResponse;
  currentUser: CurrentUserResponse;
  defaultBrand?: Maybe<Brand>;
  draftJob?: Maybe<DraftJobPayload>;
  draftJobs?: Maybe<DraftJobsPayload>;
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Email verification status of the user. */
  emailVerified: Scalars['Boolean']['output'];
  getJobDetails?: Maybe<Job>;
  getPaymentConfig?: Maybe<PaymentConfigPayload>;
  getPaymentMethodForMaintenance?: Maybe<PaymentMethodPayload>;
  /** Request to get product set */
  getProductSet: GetProductSetResponse;
  /** Get the surcharge amount for amex payment method */
  getSurchargeAmount: GetSurchargeAmountPayload;
  headerFooterContext?: Maybe<HeaderFooterContext>;
  /** Summary of account details from hirer accounts API */
  hirerAccount?: Maybe<HirerAccount>;
  /**
   * Summary of account details currently used by account details vertical
   * @deprecated Use Query.hirerAccount instead
   */
  hirerAccountDetails?: Maybe<HirerAccountDetails>;
  hirerAccountLoginUrl: Scalars['String']['output'];
  /**
   * Find the user associated with given User ID and AdCentre Advertiser ID
   * @deprecated Use hirerUser instead.
   */
  hirerAccountUser: UserDetails;
  /** List all active and pending users tied to the Hirer account with their access permission information to the job. */
  hirerAccountUsersWithJobPermissions: UserWithJobPermission[];
  /** List of hirer accounts associated with the user */
  hirerAccounts: HirerAccountsResult;
  /** Get hirer activities in pagination for a specific job order by most recent activity. Currently only add/delete notes on candidate */
  hirerActivitiesByJob: HirerActivityConnection;
  hirerDashboardBudgetUpsell: HirerDashboardBudgetUpsellResponse;
  hirerDashboardHiringAdviceList?: Maybe<HirerDashboardHiringAdviceList>;
  hirerDashboardJobs?: Maybe<HirerDashboardJobs>;
  hirerDashboardTalentSearch: HirerDashboardTalentSearch;
  /** Get a page-based paginated list of open and/or closed jobs. */
  hirerJobs: HirerJobConnection;
  hirerOriginZone?: Maybe<Scalars['String']['output']>;
  /** Finds the hirer user associated with the given id */
  hirerUser: HirerUserDetails;
  /** Finds the user associated with the given email */
  hirerUserEmailV2: HirerUserEmailPayloadV2;
  /**
   * Identity of the currently authenticated user.
   * @deprecated Use email and emailVerified queries
   */
  identity: Identity;
  /**
   * Fetches a list of invoices with load more option based on filters.
   * Pagination is not currently supported.
   */
  invoices: InvoiceData;
  isImagePublished?: Maybe<Scalars['Boolean']['output']>;
  /** Is the user enroled for multi-factor authentication. */
  isMfaEnroled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Transition to localisedContactInfoV2 */
  localisedContactInfo?: Maybe<LocalisedContactInfoContext>;
  localisedContactInfoV2: LocalisedContactInfo;
  location?: Maybe<Location>;
  locations: Array<Maybe<LocationSearchResult>>;
  locationsByIds: Array<Maybe<Location>>;
  /** Get notification preference for current logged in user for a specific job */
  notificationPreference: NotificationPreference;
  order?: Maybe<Order>;
  orderSummary?: Maybe<OrderSummary>;
  payment2c2pInquiry?: Maybe<Payment2c2pInquiryPayload>;
  payment2c2pInquiryForInvoices?: Maybe<Payment2c2pInquiryPayload>;
  promoCodes: PromoCode[];
  questionnaire: Questionnaire;
  /** @deprecated Transition to hirerAccountLoginUrl */
  redirectUrl: Scalars['String']['output'];
  registrationOptions: RegistrationOptions;
  registrationStatus: RegistrationStatusInfo;
  retrievePaymentIntent?: Maybe<RetrievePaymentIntentPayload>;
  /**
   * Searches for integration partners.
   *
   * The search can be filtered by a partners name, status, and release notes status.
   *
   * Valid values for status and releaseNotesStatus inputs are 'Published' and 'Draft'.
   */
  searchIntegrationPartners: IntegrationPartner[];
  /** Query to get state information based on country code */
  stateByCountry: State[];
  suggestedCategories: CategorySuggestion[];
  suggestedSalary?: Maybe<SuggestedSalary>;
  talentSearchATSJobs?: Maybe<TalentSearchATSJobsConnection>;
  talentSearchAccounts?: Maybe<TalentSearchAccounts>;
  talentSearchAssignedPools: TalentSearchAssignedPools[];
  /** Fetches available jobs that has not been sent to the candidate (Version 2). */
  talentSearchAvailableJobsV2: TalentSearchAvailableJobV2[];
  /** Fetches all jobs for an advertiser id. */
  talentSearchBudgetBalance: TalentSearchBudgetBalance;
  talentSearchConnectionStatus: ConnectionStatusPayload;
  talentSearchConnectionsAvailabilities: TalentSearchProfileConnectionsAvailability[];
  talentSearchCvUrl: Scalars['String']['output'];
  talentSearchInteractions?: Maybe<TalentSearchInteractionsConnection>;
  /** Fetches job by Id for an advertiser (Version 2) */
  talentSearchJobByIdV2?: Maybe<TalentSearchJobV2>;
  /** Fetches all jobs for an advertiser id (Version 2). */
  talentSearchJobsV2?: Maybe<TalentSearchJobsV2>;
  talentSearchLatestInteractions: TalentSearchLatestInteraction[];
  talentSearchMessageDetails: TalentSearchMessageDetails;
  talentSearchMetadataCompanySuggest: Array<Scalars['String']['output']>;
  talentSearchMetadataIndustries: TalentSearchMetadataIndustry[];
  talentSearchMetadataMarkets: TalentSearchMarket[];
  talentSearchMetadataQueryValidations?: Maybe<TalentSearchMetadataQueryValidations>;
  /** Get all SEEK, Combined or Partner profiles added to a pool */
  talentSearchPoolCandidatesV2: TalentSearchProfileResultsV2;
  talentSearchPools: TalentSearchPool[];
  /** Get profile resume for SEEK, Combined or Partner profile */
  talentSearchProfileResume: TalentSearchProfileResumeResult;
  /** Get details for SEEK, Combined or Partner profile V2 */
  talentSearchProfileV2: TalentSearchProfileResultV2;
  /** Get details for SEEK profile by email */
  talentSearchProfilesByEmail: TalentSearchProfileResultsV2;
  /** Get recommended SEEK candidates powered by CQs recommendation algorithm for a job */
  talentSearchRecommendedCandidatesV2?: Maybe<TalentSearchProfileResultsV2>;
  talentSearchSavedSearches: TalentSearchSavedSearch[];
  talentSearchSendJobStatus: TalentSearchProfileSendJobStatus[];
  /** Get matching similar candidates for SEEK profile */
  talentSearchSimilarCandidatesByIdV2?: Maybe<TalentSearchSimilarCandidatesResultV2>;
  /** Get profiles count for uncoupled search query V2 */
  talentSearchUncoupledCountForQueryV2: Scalars['Int']['output'];
  /** Get V2 search results facets for uncoupled search query */
  talentSearchUncoupledCountsV2: TalentSearchCountsResults;
  /** Get search results for uncoupled search query with unified requirement */
  talentSearchUncoupledSearchV2: TalentSearchProfileResultsV2;
  /**
   * Resolves user context data and relies on advertiserId in request headers
   * @deprecated Use talentSearchUserContextV2 instead
   */
  talentSearchUserContext?: Maybe<TalentSearchUserContext>;
  /** Resolves user context data for first available account or a specific account based on input parameters */
  talentSearchUserContextV2: TalentSearchUserContext;
  /** Fetches all feature flags for the current user */
  talentSearchUserFeatures: Array<Maybe<TalentSearchFeature>>;
  validatePromoCode: ValidatePromoCodeResponse;
  yourCandidates: YourCandidatesResponse;
}

export interface QuerySavedStripeCreditCardsArgs {
  countryCode: Scalars['String']['input'];
}

export interface QueryadBudgetProductsForUpsellArgs {
  input: AdBudgetProductsForUpsellInput;
}

export interface QueryadProductsForAdPostingArgs {
  input: AdProductsForAdPostingInput;
}

export interface QueryanalyticsAdPerformanceReportArgs {
  input: AdPerformanceReportWidgetInput;
}

export interface QueryanalyticsAdRatingArgs {
  input: AdRatingWidgetInput;
}

export interface QueryanalyticsAdTypeTrendArgs {
  input: AdUsageAdTypeTrendInput;
}

export interface QueryanalyticsAdUsageConversionArgs {
  input: AdUsageConversionWidgetInput;
}

export interface QueryanalyticsAdUsageFiltersArgs {
  input: AdUsageFiltersWidgetInput;
}

export interface QueryanalyticsAdUsageSpendArgs {
  input: AdUsageSpendWidgetInput;
}

export interface QueryanalyticsAdUsageTipsToImproveAdsArgs {
  input: TipsToImproveAdsWidgetInput;
}

export interface QueryanalyticsApplicationPerformanceArgs {
  input: ApplicationPerformanceWidgetInput;
}

export interface QueryanalyticsCandidateDemandArgs {
  input: CandidateDemandWidgetInput;
}

export interface QueryanalyticsCandidateSupplyArgs {
  input: CandidateSupplyWidgetInput;
}

export interface QueryanalyticsClassificationsAndUsersTrendArgs {
  input: AdUsageTrendClassificationsAndUsersInput;
}

export interface QueryanalyticsClicksOnYourAdArgs {
  input: ClicksOnYourAdWidgetInput;
}

export interface QueryanalyticsJobAdListArgs {
  input: JobAdListWidgetInput;
}

export interface QueryanalyticsMarketInsightsTitleArgs {
  input: MarketInsightsTitleWidgetInput;
}

export interface QueryanalyticsMarketPerformanceArgs {
  input: MarketPerformanceWidgetInput;
}

export interface QueryanalyticsMostAdsByPerformanceArgs {
  input: MostAdsByPerformanceWidgetInput;
}

export interface QueryanalyticsMyJobInformationArgs {
  input: MyJobInformationWidgetInput;
}

export interface QueryanalyticsPageTitleActionsArgs {
  input: PageTitleActionsWidgetInput;
}

export interface QueryanalyticsPerformancePredictionArgs {
  input: PerformancePredictionWidgetInput;
}

export interface QueryanalyticsRecommendationsArgs {
  input: RecommendationsWidgetInput;
}

export interface QueryanalyticsSimilarAdsDetailsArgs {
  input: SimilarAdsWidgetInput;
}

export interface QueryanalyticsTalentSearchUsageConversionArgs {
  input: AnalyticsTalentSearchUsageConversionInput;
}

export interface QueryanalyticsTalentSearchUsageFiltersArgs {
  input: AnalyticsTalentSearchUsageFiltersInput;
}

export interface QueryanalyticsTalentSearchUsageOverviewArgs {
  input: AnalyticsTalentSearchUsageOverviewInput;
}

export interface QueryanalyticsTalentSearchUsageTrendsByAccountArgs {
  input: AnalyticsTalentSearchUsageTrendsByAccountInput;
}

export interface QueryanalyticsTalentSearchUsageTrendsByTimeArgs {
  input: AnalyticsTalentSearchUsageTrendsByTimeInput;
}

export interface QueryanalyticsTalentSearchUsageUsersListArgs {
  input: AnalyticsTalentSearchUsageUsersListInput;
}

export interface QueryanalyticsViewsInJobSearchArgs {
  input: ViewsInJobSearchWidgetInput;
}

export interface QueryapplicationAttachmentsArgs {
  input: ApplicationAttachmentInput;
}

export interface QueryapplicationEmailsArgs {
  input: ApplicationEmailsInput;
}

export interface QueryapplicationNotesArgs {
  prospectId: Scalars['ID']['input'];
}

export interface QueryapplicationsFacetsArgs {
  input: ApplicationsFacetsInput;
}

export interface QuerybrandArgs {
  id: Scalars['ID']['input'];
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface QuerybrandingAssetsArgs {
  input: GetBrandingAssetsInput;
}

export interface QuerybrandsArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface QuerycompanyProfileArgs {
  id: Scalars['ID']['input'];
}

export interface QuerycontractBalancesArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  advertiserNation: Scalars['String']['input'];
}

export interface QuerydefaultBrandArgs {
  owner?: InputMaybe<BrandOwnerInput>;
}

export interface QuerydraftJobArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydraftJobsArgs {
  input: DraftJobsInput;
}

export interface QuerygetJobDetailsArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['String']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface QuerygetPaymentConfigArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
}

export interface QuerygetPaymentMethodForMaintenanceArgs {
  country: Scalars['String']['input'];
  site: Scalars['String']['input'];
}

export interface QuerygetProductSetArgs {
  input: GetProductSetInput;
}

export interface QuerygetSurchargeAmountArgs {
  input: GetSurchargeAmountInput;
}

export interface QueryheaderFooterContextArgs {
  input?: InputMaybe<HeaderFooterContextInput>;
}

export interface QueryhirerAccountArgs {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  usersParams?: InputMaybe<UsersParamsInput>;
}

export interface QueryhirerAccountDetailsArgs {
  input?: InputMaybe<HirerAccountDetailsQueryInput>;
}

export interface QueryhirerAccountLoginUrlArgs {
  input: HirerAccountLoginUrlInput;
}

export interface QueryhirerAccountUserArgs {
  input: HirerAccountUserQueryInput;
}

export interface QueryhirerAccountUsersWithJobPermissionsArgs {
  input: UsersWithJobPermissionInput;
}

export interface QueryhirerActivitiesByJobArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: HirerActivitiesByJobInput;
}

export interface QueryhirerDashboardBudgetUpsellArgs {
  input: HirerDashboardBudgetUpsellInput;
}

export interface QueryhirerDashboardJobsArgs {
  input?: InputMaybe<HirerDashboardJobsQueryInput>;
}

export interface QueryhirerJobsArgs {
  input: HirerJobsInput;
}

export interface QueryhirerUserArgs {
  input: HirerUserQueryInput;
}

export interface QueryhirerUserEmailV2Args {
  input: HirerUserEmailInputV2;
}

export interface QueryinvoicesArgs {
  filters?: InputMaybe<InvoiceFiltersInput>;
}

export interface QueryisImagePublishedArgs {
  id: Scalars['String']['input'];
}

export interface QuerylocalisedContactInfoArgs {
  locale: Scalars['String']['input'];
}

export interface QuerylocalisedContactInfoV2Args {
  input: LocalisedContactInfoInput;
}

export interface QuerylocationArgs {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface QuerylocationsArgs {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  context?: InputMaybe<LocationContext>;
  countries?: InputMaybe<CountriesInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  excludeLocations?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QuerylocationsByIdsArgs {
  contextualCountry?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface QuerynotificationPreferenceArgs {
  advertiserId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
}

export interface QueryorderArgs {
  id?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryorderSummaryArgs {
  advertiserId?: InputMaybe<Scalars['ID']['input']>;
  advertiserNation: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
}

export interface Querypayment2c2pInquiryArgs {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
}

export interface Querypayment2c2pInquiryForInvoicesArgs {
  currency: Scalars['String']['input'];
  paymentToken?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryquestionnaireArgs {
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}

export interface QueryredirectUrlArgs {
  input?: InputMaybe<RedirectUrlQueryInput>;
}

export interface QueryregistrationStatusArgs {
  input?: InputMaybe<RegistrationStatusInput>;
}

export interface QueryretrievePaymentIntentArgs {
  country: Scalars['String']['input'];
  id: Scalars['String']['input'];
  paymentMethodType?: InputMaybe<StripePaymentMethod>;
}

export interface QuerysearchIntegrationPartnersArgs {
  input?: InputMaybe<SearchIntegrationPartnersInput>;
}

export interface QuerystateByCountryArgs {
  countryCode: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
}

export interface QuerysuggestedCategoriesArgs {
  country: Scalars['String']['input'];
  jobOrDraftId?: InputMaybe<Scalars['Int']['input']>;
  jobTitle: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  maxResults?: InputMaybe<Scalars['Int']['input']>;
}

export interface QuerysuggestedSalaryArgs {
  countryCode: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['String']['input'];
  roleTitle: Scalars['String']['input'];
  salaryType?: InputMaybe<SalaryType>;
  workType?: InputMaybe<WorkType>;
}

export interface QuerytalentSearchATSJobsArgs {
  input: TalentSearchATSJobsInput;
}

export interface QuerytalentSearchAccountsArgs {
  input?: InputMaybe<TalentSearchAccountsInput>;
}

export interface QuerytalentSearchAssignedPoolsArgs {
  input?: InputMaybe<TalentSearchAssignedPoolsInput>;
}

export interface QuerytalentSearchAvailableJobsV2Args {
  input: TalentSearchAvailableJobsInput;
}

export interface QuerytalentSearchConnectionStatusArgs {
  connectionId: Scalars['String']['input'];
}

export interface QuerytalentSearchConnectionsAvailabilitiesArgs {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface QuerytalentSearchCvUrlArgs {
  profileId: Scalars['Int']['input'];
}

export interface QuerytalentSearchInteractionsArgs {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  profileId: Scalars['Int']['input'];
}

export interface QuerytalentSearchJobByIdV2Args {
  id: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface QuerytalentSearchJobsV2Args {
  input?: InputMaybe<TalentSearchJobsInput>;
}

export interface QuerytalentSearchLatestInteractionsArgs {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface QuerytalentSearchMessageDetailsArgs {
  connectionId: Scalars['String']['input'];
}

export interface QuerytalentSearchMetadataCompanySuggestArgs {
  input?: InputMaybe<TalentSearchMetadataCompanySuggestInput>;
}

export interface QuerytalentSearchMetadataIndustriesArgs {
  input?: InputMaybe<TalentSearchMetadataIndustriesInput>;
}

export interface QuerytalentSearchMetadataMarketsArgs {
  input?: InputMaybe<TalentSearchMetadataMarketsInput>;
}

export interface QuerytalentSearchMetadataQueryValidationsArgs {
  input?: InputMaybe<TalentSearchMetadataQueryValidationsInput>;
}

export interface QuerytalentSearchPoolCandidatesV2Args {
  input: TalentSearchPoolCandidatesInputV2;
}

export interface QuerytalentSearchProfileResumeArgs {
  input: TalentSearchProfileResumeInput;
}

export interface QuerytalentSearchProfileV2Args {
  input: TalentSearchProfileInput;
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface QuerytalentSearchProfilesByEmailArgs {
  input: ProfilesByEmailInput;
}

export interface QuerytalentSearchRecommendedCandidatesV2Args {
  input: TalentSearchRecommendedCandidatesInputV2;
}

export interface QuerytalentSearchSendJobStatusArgs {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface QuerytalentSearchSimilarCandidatesByIdV2Args {
  input: TalentSearchSimilarCandidatesByIdInputV2;
}

export interface QuerytalentSearchUncoupledCountForQueryV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface QuerytalentSearchUncoupledCountsV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface QuerytalentSearchUncoupledSearchV2Args {
  input: TalentSearchUncoupledSearchInputV2;
}

export interface QuerytalentSearchUserContextV2Args {
  input: TalentSearchUserContextInput;
}

export interface QueryvalidatePromoCodeArgs {
  input: ValidatePromoCodeInput;
}

export interface QueryyourCandidatesArgs {
  input: YourCandidatesInput;
}

export interface Questionnaire {
  __typename?: 'Questionnaire';
  id: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  questions?: Maybe<QuestionnaireQuestion[]>;
}

export interface QuestionnairequestionsArgs {
  input?: InputMaybe<QuestionnaireQuestionFilter>;
}

export interface QuestionnaireAnswer {
  __typename?: 'QuestionnaireAnswer';
  /** RR Answer Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * RR Answer Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** If true, represents that this answer is the inverse or 'negative' option to its siblings */
  isNegative: Scalars['Boolean']['output'];
  /** If true, represents that this answer is a preferred answer for the hirer */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of this answer in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** A statement that represents this answer */
  text: Scalars['String']['output'];
}

export interface QuestionnaireFilter {
  answerIds: Array<Scalars['ID']['input']>;
  operator: QuestionnaireFilterOperator;
  questionId: Scalars['ID']['input'];
}

export enum QuestionnaireFilterOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface QuestionnaireQuestion {
  __typename?: 'QuestionnaireQuestion';
  /** The answer's type. e.g MultiSelect, SingleSelect, FreeText, UrlSingleSelect */
  answerType: Scalars['String']['output'];
  /** The answers to a question */
  answers?: Maybe<QuestionnaireAnswer[]>;
  /** If true represents that this question is a must-have requirement */
  essential?: Maybe<Scalars['Boolean']['output']>;
  /** RR Question Global ID */
  globalId?: Maybe<Scalars['String']['output']>;
  hirerExperience?: Maybe<HirerExperience>;
  /**
   * RR Question Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']['output']>;
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int']['output'];
  /** Whether a question is library or custom */
  source: Scalars['String']['output'];
  /** A shorter, summarised version of the question being asked */
  statement?: Maybe<Scalars['String']['output']>;
  /** The question being asked */
  text: Scalars['String']['output'];
}

export enum QuestionnaireQuestionCategory {
  SALARY = 'SALARY',
}

export interface QuestionnaireQuestionFilter {
  category?: InputMaybe<QuestionnaireQuestionCategory>;
}

export interface Range {
  __typename?: 'Range';
  maximum: Scalars['Int']['output'];
  minimum: Scalars['Int']['output'];
}

export interface RatingItem {
  __typename?: 'RatingItem';
  description: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
}

export type Recommendation =
  | BrandedAd
  | GranularLocation
  | LowApply
  | LowClick
  | LowSalary
  | RevealSalary
  | RoleRequirements
  | TalentSearch;

export enum RecommendationType {
  BRANDED_AD = 'BRANDED_AD',
  GRANULAR_LOCATION = 'GRANULAR_LOCATION',
  LOW_APPLY = 'LOW_APPLY',
  LOW_CLICK = 'LOW_CLICK',
  LOW_SALARY = 'LOW_SALARY',
  REVEAL_SALARY = 'REVEAL_SALARY',
  ROLE_REQUIREMENTS = 'ROLE_REQUIREMENTS',
  TALENT_SEARCH = 'TALENT_SEARCH',
}

export interface RecommendationsResponse {
  __typename?: 'RecommendationsResponse';
  isExpired: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  postTypeCategory: AnalyticsPostTypeCategory;
  recommendations: Recommendation[];
}

export interface RecommendationsWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface RedirectUrlQueryInput {
  /** Hirer Account ID of the new advertiser */
  hirerAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Language param to apply additional localisation to auth related pages. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Return uri for successful account switching. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export interface RefreshJobInput {
  /** @deprecated use value from token */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface RefreshJobResponse {
  __typename?: 'RefreshJobResponse';
  /** The key used to refresh the job */
  idempotencyKey: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String']['output'];
}

export interface RegisterAdditionalAdvertiserInput {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
}

export interface RegisterNewUserInput {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Language param to apply additional localisation to auth related pages. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Redirection uri for after signing into new account. */
  returnUri?: InputMaybe<Scalars['String']['input']>;
  /** New user record to persist */
  user: NewUser;
}

export interface RegisterPayload {
  __typename?: 'RegisterPayload';
  /** Adcentre advertiser ID of new advertiser */
  advertiserId: Scalars['ID']['output'];
  /** Unified URL to sign into the advertiser account. Undefined for additional advertiser registrations. */
  redirectUrl?: Maybe<Scalars['String']['output']>;
  /** SEEK ID of new advertiser */
  seekId: Scalars['ID']['output'];
}

export interface RegistrationOptions {
  __typename?: 'RegistrationOptions';
  /** Allows customer service to register new businesses on clients behalf */
  allowAgencyRegistration: Scalars['Boolean']['output'];
}

export interface RegistrationStatusInfo {
  __typename?: 'RegistrationStatusInfo';
  /** Indicates whether the user exists in Adcentre DB. */
  userExists: Scalars['Boolean']['output'];
}

export interface RegistrationStatusInput {
  /** Filter to restrict returned results on user status */
  filter?: InputMaybe<Scalars['String']['input']>;
}

export interface ReinstateInput {
  /** The ID of the user to be reinstated */
  userId: Scalars['Int']['input'];
}

export interface ReinstateResponse {
  __typename?: 'ReinstateResponse';
  /** Response message we receive from requesting to reinstate a user status */
  message: Scalars['String']['output'];
}

export interface RemoveApplicationNoteInput {
  noteId: Scalars['ID']['input'];
  prospectId: Scalars['ID']['input'];
}

export interface RemoveApplicationNotePayload {
  __typename?: 'RemoveApplicationNotePayload';
  id: Scalars['ID']['output'];
}

export interface RemoveUserFromAccountInput {
  /** The ID of the user to be removed */
  userId: Scalars['Int']['input'];
}

export interface RepostOption {
  __typename?: 'RepostOption';
  count: Scalars['Int']['output'];
  key: AdRepost;
}

export interface RequestIntegrationRelationshipsFailure {
  __typename?: 'RequestIntegrationRelationshipsFailure';
  error: Scalars['String']['output'];
}

export interface RequestIntegrationRelationshipsFollowUpQuestionInput {
  answer: Scalars['String']['input'];
  followUpAnswer?: InputMaybe<Scalars['String']['input']>;
}

export interface RequestIntegrationRelationshipsInput {
  contactName: Scalars['String']['input'];
  futureApplications?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  futurePosting?: InputMaybe<RequestIntegrationRelationshipsFollowUpQuestionInput>;
  goLiveDate: Scalars['String']['input'];
  hirerAccountIds: Array<Scalars['ID']['input']>;
  partnerId: Scalars['ID']['input'];
  relationshipTypeCodes: IntegrationRelationshipTypeEnum[];
}

export type RequestIntegrationRelationshipsPayload =
  | RequestIntegrationRelationshipsFailure
  | RequestIntegrationRelationshipsSuccess;

export interface RequestIntegrationRelationshipsSuccess {
  __typename?: 'RequestIntegrationRelationshipsSuccess';
  partnerId: Scalars['ID']['output'];
  relationships: RequestedIntegrationRelationship[];
}

export type RequestedIntegrationRelationship =
  | ApprovedIntegrationRelationship
  | PendingIntegrationRelationship;

export interface ResendAccountActivationEmailInput {
  /** Hirer Account to be serviced by the activation */
  hirerAccountId: Scalars['String']['input'];
}

export type ResendAccountActivationEmailResponse =
  | ResendAccountActivationEmailResponseSuccess
  | ResponseError;

export interface ResendAccountActivationEmailResponseSuccess {
  __typename?: 'ResendAccountActivationEmailResponseSuccess';
  hirerAccountId: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  resent: Scalars['Boolean']['output'];
}

export interface ResponseError {
  __typename?: 'ResponseError';
  error: ResponseErrorReason;
}

export enum ResponseErrorReason {
  BAD_INPUT = 'BAD_INPUT',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export interface ResumeEducation {
  __typename?: 'ResumeEducation';
  displayDate?: Maybe<Scalars['String']['output']>;
  institutionName?: Maybe<Scalars['String']['output']>;
  qualificationName?: Maybe<Scalars['String']['output']>;
}

export interface ResumeWorkHistory {
  __typename?: 'ResumeWorkHistory';
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  displayDescription: ResumeWorkHistoryDisplayDescription[];
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
}

export interface ResumeWorkHistoryDisplayDescription {
  __typename?: 'ResumeWorkHistoryDisplayDescription';
  description: Scalars['String']['output'];
  isBullet: Scalars['Boolean']['output'];
}

export interface RetrievePaymentIntentPayload {
  __typename?: 'RetrievePaymentIntentPayload';
  amount?: Maybe<Scalars['SafeInt']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  virtualAccount?: Maybe<VirtualAccount>;
}

export type RevealAdSalary = TipToImproveAds & {
  __typename?: 'RevealAdSalary';
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
};

export interface RevealSalary {
  __typename?: 'RevealSalary';
  currency: Scalars['String']['output'];
  type: RecommendationType;
}

export interface Review {
  __typename?: 'Review';
  companyId: Scalars['String']['output'];
  cons: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  overallRating: Scalars['Float']['output'];
  pros: Scalars['String']['output'];
  ratings: RatingItem[];
  recommended: ReviewRecommended;
  reply?: Maybe<ReviewReply>;
  salarySummary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  upvotes?: Maybe<Upvotes>;
  workLocation?: Maybe<Scalars['String']['output']>;
}

export type ReviewAdDetails = TipToImproveAds & {
  __typename?: 'ReviewAdDetails';
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
};

export type ReviewAdSummary = TipToImproveAds & {
  __typename?: 'ReviewAdSummary';
  adCount: Scalars['Int']['output'];
  benchmarkConversionRate?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  type: TipsToImproveAdsType;
};

export interface ReviewRecommended {
  __typename?: 'ReviewRecommended';
  description: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface ReviewReply {
  __typename?: 'ReviewReply';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  user?: Maybe<ReviewReplyUser>;
}

export interface ReviewReplyUser {
  __typename?: 'ReviewReplyUser';
  userId?: Maybe<Scalars['String']['output']>;
}

export interface Reviews {
  __typename?: 'Reviews';
  data?: Maybe<Review[]>;
  paging?: Maybe<ReviewsPaging>;
}

export interface ReviewsPaging {
  __typename?: 'ReviewsPaging';
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
}

export type RightToWork = Verification & {
  __typename?: 'RightToWork';
  id?: Maybe<Scalars['String']['output']>;
  isVerified: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  verifiedFields?: Maybe<VerifiedField[]>;
};

export interface RightToWorkInSavedSearch {
  __typename?: 'RightToWorkInSavedSearch';
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface RightToWorkInSavedSearchlabelArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface RightToWorkInput {
  ids: Array<Scalars['String']['input']>;
  includeUnspecified: Scalars['Boolean']['input'];
}

export enum Role {
  /** An administrator. */
  ADMINISTRATOR = 'ADMINISTRATOR',
  /** A standard user. */
  USER = 'USER',
}

export interface RoleRequirements {
  __typename?: 'RoleRequirements';
  roleReqsQuestions?: Maybe<Array<Scalars['String']['output']>>;
  type: RecommendationType;
}

/** RoleRequirementsMandatoryQuestions feature to indicate CPQA product */
export type RoleRequirementsMandatoryQuestionsFeature =
  CatalogueProductFeature & {
    __typename?: 'RoleRequirementsMandatoryQuestionsFeature';
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    questionAnswerSet: Scalars['String']['output'];
    type: Scalars['String']['output'];
  };

export interface Rule {
  __typename?: 'Rule';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
}

export interface Salary {
  frequency: SalaryFrequency;
  includeUnspecified: Scalars['Boolean']['input'];
  range?: InputMaybe<SalaryRange>;
}

export interface SalaryAmountV2 {
  __typename?: 'SalaryAmountV2';
  frequency: SalaryFrequency;
  value: Scalars['SafeInt']['output'];
}

export enum SalaryFrequency {
  ANNUAL = 'ANNUAL',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
}

export interface SalaryInput {
  currency: JobSalaryCurrency;
  displayValue: Scalars['String']['input'];
  maximum: Scalars['SafeInt']['input'];
  minimum: Scalars['SafeInt']['input'];
  type: Scalars['String']['input'];
}

export interface SalaryRange {
  maximum?: InputMaybe<Scalars['SafeInt']['input']>;
  minimum?: InputMaybe<Scalars['SafeInt']['input']>;
}

export enum SalaryType {
  ANNUAL_COMMISSION = 'ANNUAL_COMMISSION',
  ANNUAL_PACKAGE = 'ANNUAL_PACKAGE',
  COMMISSION_ONLY = 'COMMISSION_ONLY',
  HOURLY_RATE = 'HOURLY_RATE',
  MONTHLY = 'MONTHLY',
}

export type SavedCreditCardPaymentError = Error & {
  __typename?: 'SavedCreditCardPaymentError';
  /** For card errors resulting from a card issuer decline, a short string indicating the card issuer’s reason for the decline if they provide one. */
  declineCode?: Maybe<Scalars['String']['output']>;
  /** Short string indicating the error code reported. */
  errorCode?: Maybe<Scalars['String']['output']>;
  /** A human-readable message providing more details about the error. */
  message: Scalars['String']['output'];
  /** If the error is parameter-specific, the parameter related to the error. For example, you can use this to display a message near the correct form field. */
  param?: Maybe<Scalars['String']['output']>;
  /** Id of the failed payment intent. */
  paymentIntentId: Scalars['String']['output'];
  /** The status of the payment intent. Examples: "requires_payment_method" "requires_confirmation" "requires_action" "processing" "requires_capture" "canceled". */
  paymentIntentStatus: Scalars['String']['output'];
};

export interface SavedCreditCardPaymentSuccessPayload {
  __typename?: 'SavedCreditCardPaymentSuccessPayload';
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Id of the successful payment intent. */
  paymentIntentId: Scalars['String']['output'];
}

export interface SearchIntegrationPartnersInput {
  releaseNotesStatus?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
}

export type SecureLinkExpiredError = Error & {
  __typename?: 'SecureLinkExpiredError';
  message: Scalars['String']['output'];
};

export interface SecureLinkInput {
  /** Temporary link GUID of SecurityLink entities. */
  link: Scalars['String']['input'];
}

export type SecureLinkInvalidError = Error & {
  __typename?: 'SecureLinkInvalidError';
  message: Scalars['String']['output'];
};

export type SecureLinkResponse =
  | SecureLinkExpiredError
  | SecureLinkInvalidError
  | SecureLinkSuccessResponse
  | SecureLinkUsedError;

export interface SecureLinkSuccessResponse {
  __typename?: 'SecureLinkSuccessResponse';
  redirectUri: Scalars['String']['output'];
}

export type SecureLinkUsedError = Error & {
  __typename?: 'SecureLinkUsedError';
  message: Scalars['String']['output'];
};

export interface SellingPoint {
  __typename?: 'SellingPoint';
  /** Additional information about a product */
  helpTip?: Maybe<HelpTip>;
  /** To determine whether to highlight the displayed selling point or not */
  isHighlighted: Scalars['Boolean']['output'];
  /** Description of a selling point */
  name: Scalars['String']['output'];
}

export interface SendAccessEventPayload {
  __typename?: 'SendAccessEventPayload';
  success: Scalars['Boolean']['output'];
}

export interface SendApplicationEmailInput {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectId: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
}

export interface SendBulkApplicationEmailInput {
  enableReplies: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  prospectIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
}

export interface SendBulkApplicationEmailPayload {
  __typename?: 'SendBulkApplicationEmailPayload';
  success: Scalars['Boolean']['output'];
}

export interface SendEmailResponse {
  __typename?: 'SendEmailResponse';
  /** Response message we receive from requesting the password and registration emails to be sent. */
  message?: Maybe<Scalars['String']['output']>;
}

export interface SendEmailVerificationEmailPayload {
  __typename?: 'SendEmailVerificationEmailPayload';
  /** Indicates whether a user has already verified their email address. */
  emailVerified: Scalars['Boolean']['output'];
  /** Message of resource api response. */
  msg: Scalars['String']['output'];
  /** Success status of sending email. */
  success: Scalars['Boolean']['output'];
}

export interface SendEnrolmentEmailPayload {
  __typename?: 'SendEnrolmentEmailPayload';
  /** success of sending Enrolment Email */
  isEnrolmentEmailSent: Scalars['Boolean']['output'];
}

export interface SendHirerAccountUsersJobAccessEmailInput {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /** Custom email message written by the sender */
  emailMessage?: InputMaybe<Scalars['String']['input']>;
  /** If set to true, sender will be included in the email cc list */
  includeSender?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job that users are to be notified that they have access to */
  jobId: Scalars['ID']['input'];
  /** A list of team members who are invited to access the job */
  receivers: AccessEmailReceiverInput[];
}

export interface SendHirerAccountUsersJobAccessEmailPayload {
  __typename?: 'SendHirerAccountUsersJobAccessEmailPayload';
  /** Success status of sending email. Returns true if send email operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SendJobInput {
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int']['input'];
}

export interface SendLoginCallbackEventPayload {
  __typename?: 'SendLoginCallbackEventPayload';
  /** Success status of processing callback event. */
  success: Scalars['Boolean']['output'];
}

export interface SendMessageInput {
  advertiserEmail: Scalars['String']['input'];
  advertiserFirstName: Scalars['String']['input'];
  advertiserLastName: Scalars['String']['input'];
  advertiserPhone?: InputMaybe<Scalars['String']['input']>;
  advertiserTitle: Scalars['String']['input'];
  body: Scalars['String']['input'];
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int']['input'];
  subject: Scalars['String']['input'];
}

export interface SendPasswordResetEmailInput {
  /** Email address to send password reset email to. */
  email: Scalars['String']['input'];
}

export interface SendRegistrationEmailInput {
  /** Is the user in Customer Service view. */
  isInCSView: Scalars['Boolean']['input'];
  /** ID of user to send registration email to. */
  userId: Scalars['ID']['input'];
}

export interface SetDefaultNotificationPreferenceInput {
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
}

export interface SetHirerAccountUsersJobAccessInput {
  /** List of user job access settings */
  accessList: UserJobAccessInput[];
  /** The advertiser id that users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update job access immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** The job id to set users access to */
  jobId: Scalars['ID']['input'];
}

export interface SetHirerAccountUsersJobAccessPayload {
  __typename?: 'SetHirerAccountUsersJobAccessPayload';
  /** Success status of set user job access operation. Returns true if set job access operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SetNotificationPreferencesInput {
  /** The job advertiser id */
  advertiserId: Scalars['ID']['input'];
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update notification preferences immediately
   */
  delayUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID']['input'];
  /** Notification preferences to be set for account users. */
  preferences: NotificationPreferenceInput[];
}

export interface SetNotificationPreferencesPayload {
  __typename?: 'SetNotificationPreferencesPayload';
  /** Success status of setting notification preferences operation. Returns true if set notification preferences operation is accepted. */
  success: Scalars['Boolean']['output'];
}

export interface SimilarAdsDetails {
  __typename?: 'SimilarAdsDetails';
  adType: Scalars['String']['output'];
  advertiserName: Scalars['String']['output'];
  hasKeySellingPoints: Scalars['Boolean']['output'];
  isPrivateAdvertiser: Scalars['Boolean']['output'];
  isSalaryHidden: Scalars['Boolean']['output'];
  jobCreatedTimestampUTC: Scalars['DateTime']['output'];
  jobId: Scalars['String']['output'];
  jobLocationLabel: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  productDisplayName: Scalars['String']['output'];
  serpLogoUrl?: Maybe<Scalars['String']['output']>;
}

export interface SimilarAdsResponse {
  __typename?: 'SimilarAdsResponse';
  ads: SimilarAdsDetails[];
  benchmarkLocationCountry?: Maybe<Scalars['String']['output']>;
  benchmarkLocationLabel?: Maybe<Scalars['String']['output']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  normalisedRoleTitle?: Maybe<Scalars['String']['output']>;
  subClassificationName: Scalars['String']['output'];
}

export interface SimilarAdsWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}

export interface SimplifiedAnswers {
  __typename?: 'SimplifiedAnswers';
  /** The global ids of the granular answers that the given simplified answer maps to */
  answerGlobalIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The local ids of the granular answers that the given simplified answer maps to */
  answerIds?: Maybe<Array<Scalars['String']['output']>>;
  /** The simplified answer global id */
  globalId?: Maybe<Scalars['String']['output']>;
  /**
   * The simplified answer local id
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs.
   */
  id?: Maybe<Scalars['String']['output']>;
  /** True if the hirer selected the answer during CAJA */
  isPreferred: Scalars['Boolean']['output'];
  /** The display order of the answer, low to high */
  order: Scalars['Int']['output'];
  /** The simplified answer text */
  text: Scalars['String']['output'];
}

/** Product feature that indicates Sponsored Slot feature properties available for ad product */
export type SponsoredSlotFeature = CatalogueProductFeature & {
  __typename?: 'SponsoredSlotFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface State {
  __typename?: 'State';
  /** Country of the state */
  country?: Maybe<CountryType>;
  /** Full name of the state */
  defaultName: Scalars['String']['output'];
  /** Whether the state information is deprecated or not */
  deprecated: Scalars['Boolean']['output'];
  /** Type of the location, eg. "State" / "Country" */
  kind: Scalars['String']['output'];
}

export interface StripePaymentIntentPayload {
  __typename?: 'StripePaymentIntentPayload';
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String']['output'];
  /** Unique identifier for payment intent object */
  id: Scalars['String']['output'];
  /** A url that the customer can use to complete payment. If a url has not been set up for that market, this field will be omitted. */
  paymentLink?: Maybe<Scalars['String']['output']>;
  /** The base64 image data for the QR code */
  qrCodeUrl?: Maybe<Scalars['String']['output']>;
  /** The payment reference for the payment intent. To be used only on invoices. */
  reference?: Maybe<Scalars['String']['output']>;
}

export enum StripePaymentMethod {
  card = 'card',
  fpx = 'fpx',
  id_bank_transfer = 'id_bank_transfer',
  paynow = 'paynow',
  promptpay = 'promptpay',
}

/** Product feature that indicates border available for ad product */
export type StyleEnhancementFeature = CatalogueProductFeature & {
  __typename?: 'StyleEnhancementFeature';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  style: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export interface SubTitle {
  __typename?: 'SubTitle';
  style: Scalars['String']['output'];
  text: Scalars['String']['output'];
}

export interface Subject {
  __typename?: 'Subject';
  /**
   * Email address of the subject.
   * @deprecated Use email query
   */
  email: Scalars['String']['output'];
  /**
   * Indicates whether a subject has verified their email address.
   * @deprecated Use emailVerified query
   */
  emailVerified: Scalars['Boolean']['output'];
  /** The Auth0 user id of the subject, eg 'auth0|12eb841a02c489d1'. */
  id: Scalars['String']['output'];
  /**
   * The associated adcentre user id. This may be null if a corresponding adcentre user has not yet been
   * created, or accounts are not associated correctly.
   */
  userId?: Maybe<Scalars['String']['output']>;
}

export interface SuggestedSalary {
  __typename?: 'SuggestedSalary';
  averageRange?: Maybe<SuggestedSalaryAverageRange>;
  countryCode?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['Int']['output']>;
  range?: Maybe<SuggestedSalaryRange>;
  roleTitle?: Maybe<Scalars['String']['output']>;
  salaryType?: Maybe<SalaryType>;
  workType?: Maybe<WorkType>;
}

export interface SuggestedSalaryAverageRange {
  __typename?: 'SuggestedSalaryAverageRange';
  max?: Maybe<Scalars['SafeInt']['output']>;
  median?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
}

export interface SuggestedSalaryRange {
  __typename?: 'SuggestedSalaryRange';
  max?: Maybe<Scalars['SafeInt']['output']>;
  min?: Maybe<Scalars['SafeInt']['output']>;
}

export interface Surcharge {
  __typename?: 'Surcharge';
  /** The surcharge amount. */
  amount: Money;
  /** The type of surcharge. Ex, AmexSurcharge */
  surchargeType: Scalars['String']['output'];
}

export interface TalentSearch {
  __typename?: 'TalentSearch';
  hirerId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  type: RecommendationType;
}

export interface TalentSearchATSJob {
  __typename?: 'TalentSearchATSJob';
  externalReferenceId: Scalars['String']['output'];
  jobId: Scalars['String']['output'];
  jobLocations?: Maybe<Array<Scalars['String']['output']>>;
  jobOwners?: Maybe<TalentSearchJobOwner[]>;
  jobTitle: Scalars['String']['output'];
  positionProfileName?: Maybe<Scalars['String']['output']>;
  seekHirerJobReference?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchATSJobEdge {
  __typename?: 'TalentSearchATSJobEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchATSJob;
}

export interface TalentSearchATSJobs {
  __typename?: 'TalentSearchATSJobs';
  count: Scalars['Int']['output'];
  items?: Maybe<TalentSearchATSJob[]>;
}

export interface TalentSearchATSJobsConnection {
  __typename?: 'TalentSearchATSJobsConnection';
  edges: TalentSearchATSJobEdge[];
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface TalentSearchATSJobsInput {
  after?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  jobOwnerName?: InputMaybe<Scalars['String']['input']>;
  jobTitle: Scalars['String']['input'];
}

export enum TalentSearchAccess {
  DISABLED = 'DISABLED',
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD',
  SYSTEM_DOWN = 'SYSTEM_DOWN',
}

export interface TalentSearchAccount {
  __typename?: 'TalentSearchAccount';
  advertiserId: Scalars['Int']['output'];
  advertiserName: Scalars['String']['output'];
  companyAddress?: Maybe<CompanyAddress>;
  hasConnectedTalentSearchAccess: Scalars['Boolean']['output'];
  hasPremiumTalentSearchAccess: Scalars['Boolean']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  seekBillingAccountId: Scalars['String']['output'];
}

export interface TalentSearchAccounts {
  __typename?: 'TalentSearchAccounts';
  count: Scalars['Int']['output'];
  items: TalentSearchAccount[];
}

export interface TalentSearchAccountsInput {
  orderBy?: InputMaybe<TalentSearchAccountsOrderBy>;
  query?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchAccountsOrderBy {
  direction: TalentSearchAccountsOrderByDirection;
  field: TalentSearchAccountsOrderByField;
}

export enum TalentSearchAccountsOrderByDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum TalentSearchAccountsOrderByField {
  advertiserId = 'advertiserId',
  advertiserName = 'advertiserName',
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsInput {
  profileId: Scalars['String']['input'];
  values: Array<
    InputMaybe<TalentSearchAddOrRemoveProfileTalentPoolsValuesInput>
  >;
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsValuesInput {
  addToPool: Scalars['Boolean']['input'];
  poolId: Scalars['String']['input'];
  searchId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchAddProfileToTalentPoolInput {
  poolId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
  searchId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchAdvertiser {
  __typename?: 'TalentSearchAdvertiser';
  accountNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isApproved: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentAdvertiserId?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<TalentSearchHirerAccountTags>;
}

export interface TalentSearchAssignedPool {
  __typename?: 'TalentSearchAssignedPool';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchAssignedPools {
  __typename?: 'TalentSearchAssignedPools';
  assignedPools: TalentSearchAssignedPool[];
  profileId: Scalars['ID']['output'];
}

export interface TalentSearchAssignedPoolsInput {
  profileIds: Array<Scalars['Int']['input']>;
}

export interface TalentSearchAtsHirerRelationship {
  __typename?: 'TalentSearchAtsHirerRelationship';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchAvailableJobV2 {
  __typename?: 'TalentSearchAvailableJobV2';
  expiry: Scalars['String']['output'];
  id: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface TalentSearchAvailableJobsInput {
  /** Limits the number of available jobs in the response. Default is set to 10. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** The profile id to retrieve sent jobs with. */
  profileId: Scalars['Int']['input'];
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchBudgetBalance {
  __typename?: 'TalentSearchBudgetBalance';
  /** Current budget balance for an advertiser or job. */
  currentBalance: Scalars['Int']['output'];
  /** Initial budget balance for an advertiser or job. */
  initialBalance: Scalars['Int']['output'];
}

export enum TalentSearchClaim {
  CONNECTED_TALENT_SEARCH = 'CONNECTED_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH = 'PREMIUM_TALENT_SEARCH',
  STANDARD_TALENT_SEARCH = 'STANDARD_TALENT_SEARCH',
}

export interface TalentSearchConnectionAvailability {
  __typename?: 'TalentSearchConnectionAvailability';
  connectionId?: Maybe<Scalars['String']['output']>;
  connectionType: Scalars['Int']['output'];
  connectionTypeName: Scalars['String']['output'];
  createdDateUtc?: Maybe<Scalars['String']['output']>;
  isUsed: Scalars['Boolean']['output'];
  nextAvailableDateUtc?: Maybe<Scalars['String']['output']>;
}

export enum TalentSearchConnectionStatus {
  pending = 'pending',
  redeemed = 'redeemed',
  rejected = 'rejected',
}

export enum TalentSearchConnectionType {
  ATS_MSG = 'ATS_MSG',
  ITA = 'ITA',
  JOB = 'JOB',
  JSP = 'JSP',
  MSG = 'MSG',
  PROFILE_EXPORT_ATS = 'PROFILE_EXPORT_ATS',
  UNKNOWN = 'UNKNOWN',
}

export interface TalentSearchContract {
  __typename?: 'TalentSearchContract';
  endDate: Scalars['String']['output'];
  productType: TalentSearchContractType;
  startDate: Scalars['String']['output'];
}

export enum TalentSearchContractType {
  CONNECTED_TALENT_SEARCH = 'CONNECTED_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH = 'PREMIUM_TALENT_SEARCH',
  PREMIUM_TALENT_SEARCH_TRIAL = 'PREMIUM_TALENT_SEARCH_TRIAL',
}

export interface TalentSearchCountResult {
  __typename?: 'TalentSearchCountResult';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
}

export interface TalentSearchCountResultWithLabel {
  __typename?: 'TalentSearchCountResultWithLabel';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchCountsResults {
  __typename?: 'TalentSearchCountsResults';
  approachabilitysignal?: Maybe<TalentSearchCountResult[]>;
  company?: Maybe<TalentSearchCountResult[]>;
  currentLocation?: Maybe<TalentSearchCountResult[]>;
  industry?: Maybe<TalentSearchCountResult[]>;
  rightToWork?: Maybe<TalentSearchCountResultWithLabel[]>;
  salary?: Maybe<TalentSearchCountResult[]>;
}

export interface TalentSearchCountsResultsrightToWorkArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchCreateTalentPoolInput {
  name: Scalars['String']['input'];
}

export interface TalentSearchCriteria {
  __typename?: 'TalentSearchCriteria';
  approachable?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<Array<Scalars['String']['output']>>;
  countryCode?: Maybe<Scalars['String']['output']>;
  cv?: Maybe<Scalars['Boolean']['output']>;
  industry?: Maybe<Array<Scalars['String']['output']>>;
  keywords?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['String']['output']>;
  locationDisplayTexts: Array<Scalars['String']['output']>;
  locationList: Array<Scalars['Int']['output']>;
  maxSalary?: Maybe<Scalars['Int']['output']>;
  minSalary?: Maybe<Scalars['Int']['output']>;
  nation?: Maybe<Scalars['Int']['output']>;
  profileTypes?: Maybe<ProfileSource[]>;
  rightToWorkList?: Maybe<RightToWorkInSavedSearch[]>;
  rightToWorkUnspecified?: Maybe<Scalars['Boolean']['output']>;
  salaryNation?: Maybe<Scalars['Int']['output']>;
  salaryType?: Maybe<Scalars['String']['output']>;
  salaryUnspecified?: Maybe<Scalars['Boolean']['output']>;
  uncoupledFreeText: Scalars['String']['output'];
  /** @deprecated Will be removed once granular right to work is implemented */
  visa?: Maybe<Array<Scalars['String']['output']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']['output']>;
  workType?: Maybe<Array<Scalars['String']['output']>>;
}

export interface TalentSearchCriterialocationDisplayTextsArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchCriteriaInput {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Array<Scalars['String']['input']>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['String']['input']>;
  locationList: Array<Scalars['Int']['input']>;
  maxSalary?: InputMaybe<Scalars['Int']['input']>;
  minSalary?: InputMaybe<Scalars['Int']['input']>;
  nation?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<ProfileSource[]>;
  rightToWorkIds?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWorkUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  salaryNation?: InputMaybe<Scalars['Int']['input']>;
  salaryType?: InputMaybe<Scalars['String']['input']>;
  salaryUnspecified?: InputMaybe<Scalars['Boolean']['input']>;
  uncoupledFreeText: Scalars['String']['input'];
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchDeleteTalentPoolInput {
  poolId: Scalars['String']['input'];
}

export interface TalentSearchFeature {
  __typename?: 'TalentSearchFeature';
  /** Whether or not the feature flag is active */
  active: Scalars['Boolean']['output'];
  /** Unique identifier of feature flag */
  key: Scalars['String']['output'];
  /** Name of feature flag */
  name: Scalars['String']['output'];
}

export interface TalentSearchHirerAccountTags {
  __typename?: 'TalentSearchHirerAccountTags';
  recordExpiry?: Maybe<Scalars['String']['output']>;
  testBehaviours?: Maybe<Scalars['String']['output']>;
  testRecord: Scalars['String']['output'];
  testScope?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchHirerContactDetails {
  __typename?: 'TalentSearchHirerContactDetails';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
}

export interface TalentSearchInteraction {
  __typename?: 'TalentSearchInteraction';
  associatedAction: AssociatedAction;
  associatedParties?: Maybe<Array<Maybe<AssociatedParty>>>;
  id: Scalars['String']['output'];
  interactionType: Scalars['String']['output'];
  positionProfileId?: Maybe<Scalars['String']['output']>;
  seekSourceName?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchInteractionEdge {
  __typename?: 'TalentSearchInteractionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TalentSearchInteraction;
}

export interface TalentSearchInteractionsConnection {
  __typename?: 'TalentSearchInteractionsConnection';
  edges: TalentSearchInteractionEdge[];
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
}

export interface TalentSearchJobLocationV2 {
  __typename?: 'TalentSearchJobLocationV2';
  countryCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
}

export interface TalentSearchJobOwner {
  __typename?: 'TalentSearchJobOwner';
  firstName?: Maybe<Scalars['String']['output']>;
  formattedName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchJobSalaryV2 {
  __typename?: 'TalentSearchJobSalaryV2';
  frequency: SalaryFrequency;
}

export interface TalentSearchJobV2 {
  __typename?: 'TalentSearchJobV2';
  /** The associated advertiser id for the job. */
  advertiserId: Scalars['String']['output'];
  /** Current balance for this job retrieved from budgets API */
  balance: TalentSearchBudgetBalance;
  /** Date in ISO format when job was originally posted */
  createdDate: Scalars['String']['output'];
  /** Date in ISO format when job will expire */
  expiryDate: Scalars['String']['output'];
  /** The projected job id. */
  id: Scalars['String']['output'];
  /** Whether job is upgradable */
  isUpgradable?: Maybe<Scalars['Boolean']['output']>;
  /** Location of the job */
  location: TalentSearchJobLocationV2;
  /** An optional reference provided for the job. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Salary details of the job */
  salary: TalentSearchJobSalaryV2;
  /** Whether the job is Active or Inactive. */
  status: Scalars['String']['output'];
  /** The title of the job. */
  title: Scalars['String']['output'];
}

export interface TalentSearchJobV2locationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchJobsInput {
  /** Returns jobs with both "Active" and "Expired" status. */
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specify langugage for translated location */
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  /** Limits the number of jobs in the response. */
  maxJobs?: InputMaybe<Scalars['Int']['input']>;
  /** Order jobs by field and direction (asc, desc) */
  orderBy?: InputMaybe<TalentSearchJobsOrderBy>;
  /** Filters jobs based on the title and reference fields. */
  query?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchJobsOrderBy {
  direction: TalentSearchJobsOrderByDirection;
  field: TalentSearchJobsOrderByField;
}

export enum TalentSearchJobsOrderByDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum TalentSearchJobsOrderByField {
  listed = 'listed',
}

export interface TalentSearchJobsV2 {
  __typename?: 'TalentSearchJobsV2';
  jobs: TalentSearchJobV2[];
  totalJobs: Scalars['Int']['output'];
}

export interface TalentSearchLatestInteraction {
  __typename?: 'TalentSearchLatestInteraction';
  count: Scalars['Int']['output'];
  interaction?: Maybe<TalentSearchInteraction>;
  profileId: Scalars['Int']['output'];
}

export interface TalentSearchMarket {
  __typename?: 'TalentSearchMarket';
  countryCode: Scalars['String']['output'];
  countryName: Scalars['String']['output'];
}

export interface TalentSearchMessageDetails {
  __typename?: 'TalentSearchMessageDetails';
  body: Scalars['String']['output'];
  hirer: TalentSearchHirerContactDetails;
  subject: Scalars['String']['output'];
}

export interface TalentSearchMetadataCompanySuggestInput {
  query: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
}

export enum TalentSearchMetadataCulture {
  au = 'au',
  nz = 'nz',
}

export interface TalentSearchMetadataIndustriesInput {
  locale?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchMetadataIndustry {
  __typename?: 'TalentSearchMetadataIndustry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  subIndustries?: Maybe<TalentSearchMetadataSubIndustry[]>;
}

export interface TalentSearchMetadataMarketsInput {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchMetadataQueryValidations {
  __typename?: 'TalentSearchMetadataQueryValidations';
  stopwords: Array<Scalars['String']['output']>;
  whitelistRegexPattern: Scalars['String']['output'];
}

export interface TalentSearchMetadataQueryValidationsInput {
  region?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchMetadataSubIndustry {
  __typename?: 'TalentSearchMetadataSubIndustry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface TalentSearchPool {
  __typename?: 'TalentSearchPool';
  id: Scalars['String']['output'];
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  policy?: Maybe<Scalars['String']['output']>;
  profileCount: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
}

export interface TalentSearchPoolCandidatesInputV2 {
  countryCode: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  poolId: Scalars['String']['input'];
}

export interface TalentSearchPoolProfile {
  __typename?: 'TalentSearchPoolProfile';
  lastUpdated: Scalars['String']['output'];
  poolId: Scalars['String']['output'];
  profileId: Scalars['String']['output'];
  searchId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
}

export interface TalentSearchProfileConnectionsAvailability {
  __typename?: 'TalentSearchProfileConnectionsAvailability';
  connectionsAvailability: TalentSearchConnectionAvailability[];
  profileId: Scalars['Int']['output'];
}

export interface TalentSearchProfileInput {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchProfileResultV2 {
  __typename?: 'TalentSearchProfileResultV2';
  actionLinks?: Maybe<ActionLink>;
  ahpraRegistrations?: Maybe<AhpraRegistration[]>;
  approachabilitySignal?: Maybe<Scalars['Int']['output']>;
  atsLastUpdatedDateUtc?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  currentIndustry?: Maybe<Scalars['String']['output']>;
  currentIndustryId?: Maybe<Scalars['String']['output']>;
  currentInferredIndustry?: Maybe<Scalars['String']['output']>;
  currentJobTitle?: Maybe<Scalars['String']['output']>;
  currentLocation?: Maybe<Scalars['String']['output']>;
  currentSubindustry?: Maybe<Scalars['String']['output']>;
  currentSubindustryId?: Maybe<Scalars['String']['output']>;
  driversLicences?: Maybe<DriversLicence[]>;
  firstName: Scalars['String']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasVerifiedCredentials: Scalars['Boolean']['output'];
  highlight?: Maybe<ProfileResultHighlight>;
  homeLocationId?: Maybe<Scalars['String']['output']>;
  jobSeekerId: Scalars['Int']['output'];
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  licences?: Maybe<ProfileLicence[]>;
  noticePeriodDays?: Maybe<Scalars['Int']['output']>;
  partnerDoNotContact: Scalars['Boolean']['output'];
  pastJobTitles?: Maybe<Array<Scalars['String']['output']>>;
  profileEducation?: Maybe<ProfileEducation[]>;
  profileId: Scalars['Int']['output'];
  profilePrivacy: PrivacyTypes;
  profileType: ProfileTypes;
  resumeCoreDataAvailable?: Maybe<Scalars['Boolean']['output']>;
  resumeSnippet?: Maybe<Scalars['String']['output']>;
  rightToWork?: Maybe<RightToWork>;
  salary?: Maybe<ProfileSalaryV2>;
  sensitiveSearchTermsInResumeSnippet: Scalars['Boolean']['output'];
  sensitiveWordsFoundInResumeSnippet: Scalars['Boolean']['output'];
  skills?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
  workHistories: WorkHistory[];
  workTypes: Array<Scalars['String']['output']>;
}

export interface TalentSearchProfileResultV2currentLocationArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchProfileResultV2rightToWorkArgs {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}

export interface TalentSearchProfileResultsV2 {
  __typename?: 'TalentSearchProfileResultsV2';
  count: Scalars['Int']['output'];
  items: TalentSearchProfileResultV2[];
  searchIdentifier?: Maybe<Scalars['String']['output']>;
}

export interface TalentSearchProfileResumeInput {
  keywords?: InputMaybe<Scalars['String']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
}

export interface TalentSearchProfileResumeResult {
  __typename?: 'TalentSearchProfileResumeResult';
  resumeEducation: ResumeEducation[];
  resumeSkills: Array<Scalars['String']['output']>;
  resumeWorkHistories: ResumeWorkHistory[];
}

export interface TalentSearchProfileSendJobStatus {
  __typename?: 'TalentSearchProfileSendJobStatus';
  profileId: Scalars['Int']['output'];
  status: TalentSearchSendJobStatus;
}

export enum TalentSearchQuerySalaryType {
  ANNUAL = 'ANNUAL',
  HOURLY_RATE = 'HOURLY_RATE',
  MONTHLY = 'MONTHLY',
}

export enum TalentSearchQuerySortBy {
  CREATED_DATE = 'CREATED_DATE',
  FRESHNESS = 'FRESHNESS',
  MODIFIED_DATE = 'MODIFIED_DATE',
  RELEVANCE = 'RELEVANCE',
}

export interface TalentSearchRecommendedCandidatesInputV2 {
  countryCode: Scalars['String']['input'];
  jobId: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  userSessionId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchSavedSearch {
  __typename?: 'TalentSearchSavedSearch';
  createdDate: Scalars['String']['output'];
  criteria: TalentSearchCriteria;
  criteriaHash: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export enum TalentSearchSendJobStatus {
  ALL_JOBS_SENT = 'ALL_JOBS_SENT',
  HAS_AVAILABLE_JOBS = 'HAS_AVAILABLE_JOBS',
  NO_ACTIVE_JOBS = 'NO_ACTIVE_JOBS',
}

export interface TalentSearchShareTalentPoolInput {
  policy: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
}

export interface TalentSearchSimilarCandidatesByIdInputV2 {
  countryCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isSeedProfileLocationOnly?: InputMaybe<Scalars['Boolean']['input']>;
  profileId?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
}

export interface TalentSearchSimilarCandidatesResultV2 {
  __typename?: 'TalentSearchSimilarCandidatesResultV2';
  profiles: TalentSearchProfileResultsV2;
  seedProfile?: Maybe<TalentSearchProfileResultV2>;
}

export interface TalentSearchUncoupledCountForQueryInputV2 {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchUncoupledSearchInputV2 {
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  cv?: InputMaybe<Scalars['Boolean']['input']>;
  industry?: InputMaybe<Array<Scalars['String']['input']>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
  locationList?: InputMaybe<Array<Scalars['String']['input']>>;
  locations?: InputMaybe<Array<Scalars['String']['input']>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  profileTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  rightToWork?: InputMaybe<RightToWorkInput>;
  salary?: InputMaybe<Salary>;
  searchExperiment?: InputMaybe<Scalars['String']['input']>;
  searchId?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<TalentSearchQuerySortBy>;
  uncoupledFreeText: Scalars['String']['input'];
  userSessionId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated use rightToWork instead */
  visa?: InputMaybe<Array<Scalars['String']['input']>>;
  willingToRelocate?: InputMaybe<Scalars['Boolean']['input']>;
  workType?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface TalentSearchUpdateTalentPoolInput {
  name: Scalars['String']['input'];
  poolId: Scalars['String']['input'];
}

export interface TalentSearchUser {
  __typename?: 'TalentSearchUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
}

export interface TalentSearchUserContext {
  __typename?: 'TalentSearchUserContext';
  advertiser: TalentSearchAdvertiser;
  ats?: Maybe<TalentSearchAtsHirerRelationship>;
  claims: TalentSearchClaim[];
  contracts: TalentSearchContract[];
  productType?: Maybe<TalentSearchClaim>;
  user: TalentSearchUser;
}

export interface TalentSearchUserContextInput {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  preferredAdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
}

export interface Tax {
  __typename?: 'Tax';
  amount?: Maybe<Money>;
  code: TaxCode;
  description?: Maybe<Scalars['String']['output']>;
  displayRecommendation?: Maybe<DisplayRecommendation>;
  rate?: Maybe<Scalars['Int']['output']>;
}

export enum TaxCode {
  GST = 'GST',
  PPN = 'PPN',
  SST = 'SST',
  VAT = 'VAT',
}

export interface TipToImproveAds {
  adCount: Scalars['Int']['output'];
  type: TipsToImproveAdsType;
}

export interface TipsToImproveAdsResponse {
  __typename?: 'TipsToImproveAdsResponse';
  numOfAds: Scalars['Int']['output'];
  tips?: Maybe<Array<Maybe<TipToImproveAds>>>;
}

export enum TipsToImproveAdsType {
  ADD_QUESTIONS = 'ADD_QUESTIONS',
  ADD_SPECIFIC_LOCATION = 'ADD_SPECIFIC_LOCATION',
  MAXIMISE_BRANDING = 'MAXIMISE_BRANDING',
  REVEAL_SALARY = 'REVEAL_SALARY',
  REVIEW_AD_DETAILS = 'REVIEW_AD_DETAILS',
  REVIEW_AD_SUMMARY = 'REVIEW_AD_SUMMARY',
}

export interface TipsToImproveAdsWidgetInput {
  filters: AdUsageQueryFilters;
  locale: Scalars['String']['input'];
  nonSpecificLocationShortId: Scalars['Int']['input'];
  specificLocationShortId: Scalars['Int']['input'];
}

export interface TrackLocationSuggestionInput {
  /** Location ID (ie, "18579") */
  id?: InputMaybe<Scalars['ID']['input']>;
  queryText?: InputMaybe<Scalars['String']['input']>;
  /** Same Session ID as the one used during 'locations()' Query */
  sessionId: Scalars['ID']['input'];
}

export interface TrackLocationSuggestionInvalidInputPayload {
  __typename?: 'TrackLocationSuggestionInvalidInputPayload';
  reason: Scalars['String']['output'];
}

export type TrackLocationSuggestionPayload =
  | TrackLocationSuggestionInvalidInputPayload
  | TrackLocationSuggestionSuccessPayload;

export interface TrackLocationSuggestionSuccessPayload {
  __typename?: 'TrackLocationSuggestionSuccessPayload';
  responseStatus: Scalars['String']['output'];
}

export interface TrendsAdTypeOption {
  __typename?: 'TrendsAdTypeOption';
  count?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
}

export interface TriggerActivationPayload {
  __typename?: 'TriggerActivationPayload';
  success: Scalars['Boolean']['output'];
}

export interface UpdateApplicationRatingInput {
  prospectId: Scalars['ID']['input'];
  rating: Scalars['Int']['input'];
}

export interface UpdateApplicationsStatusInput {
  bucket: ApplicationBucket;
  prospectData: ApplicationData[];
  /** The location the status was updated. Should be either CandidateList or CandidateDetail or null */
  statusChangeLocation?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateApplicationsStatusPayload {
  __typename?: 'UpdateApplicationsStatusPayload';
  success: Scalars['Boolean']['output'];
}

export interface UpdateBrandInput {
  assets?: InputMaybe<BrandAssetsInput>;
  id: Scalars['String']['input'];
  legacyId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Internally resolved via auth */
  owner?: InputMaybe<BrandOwnerInput>;
}

export type UpdateBrandPayload =
  | InvalidInputPayload
  | UpdateBrandSuccessPayload;

export interface UpdateBrandSuccessPayload {
  __typename?: 'UpdateBrandSuccessPayload';
  assets: BrandAssets;
  createdOn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owner: BrandOwner;
  state?: Maybe<BrandState>;
}

export interface UpdateDraftJobExpressPayload {
  __typename?: 'UpdateDraftJobExpressPayload';
  /** The updated draft job id from the express api */
  draftId: Scalars['Int']['output'];
}

export interface UpdateDraftJobInput {
  /**
   * Adcentre advertiserId
   * @deprecated No longer required. Will be retired once the front-end is updated.
   */
  adcentreAdvertiserId?: InputMaybe<Scalars['String']['input']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: InputMaybe<Scalars['String']['input']>;
  /** Selected branding info */
  branding?: InputMaybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: InputMaybe<DraftJobCategoryInput[]>;
  /** The Job Description, aka "Write my own" */
  details?: InputMaybe<Scalars['String']['input']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   */
  draftProgress?: InputMaybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType: DraftJobFlowType;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: InputMaybe<DraftJobGuidedDetailsInput[]>;
  /** Flag to indicate if AI assistance has been used to generate job description and summary */
  hasAIAssistanceBeenUsed?: InputMaybe<Scalars['Boolean']['input']>;
  /** the id of the draft to be update */
  id: Scalars['String']['input'];
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: InputMaybe<Scalars['String']['input']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: InputMaybe<DraftJobWritingMode>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The job location info */
  locations?: InputMaybe<DraftJobLocationInput[]>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: InputMaybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected products info */
  products?: InputMaybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: InputMaybe<Scalars['String']['input']>;
  /** Min/Max salaries info */
  salary?: InputMaybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The job title(s) */
  titles?: InputMaybe<DraftJobRoleTitleInput[]>;
  /** The video info, e.g. video url */
  video?: InputMaybe<DraftJobVideoInput>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: InputMaybe<WorkType[]>;
}

export interface UpdateDraftJobPayload {
  __typename?: 'UpdateDraftJobPayload';
  /** The updated draft job id from the new drafts api */
  draftId: Scalars['String']['output'];
}

export type UpdateDraftPayload =
  | UpdateDraftJobExpressPayload
  | UpdateDraftJobPayload;

export interface UpdateInvoiceStatusInput {
  invoices: Invoice[];
  seekBillingAccountId: Scalars['ID']['input'];
}

export interface UpdateInvoiceStatusPayload {
  __typename?: 'UpdateInvoiceStatusPayload';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
}

export interface UpdateJobDetailsInput {
  /** Key used to update the job */
  idempotencyKey: Scalars['String']['input'];
  /** An object containing values to update the job with */
  jobDetails: JobDetailsInput;
  /** The id of the job to update the details of */
  jobId: Scalars['String']['input'];
  /** An id used to identify the lifecycle of the job update */
  opportunityId: Scalars['String']['input'];
  /** The context in which the job is being updated (eg. updating or editing the job)) */
  purchaseContext: PurchaseContext;
}

export type UpdateJobDetailsResponse = UpdateJobDetailsSuccessResponse;

export interface UpdateJobDetailsSuccessResponse {
  __typename?: 'UpdateJobDetailsSuccessResponse';
  revisionId: Scalars['String']['output'];
}

export interface UpdateUserDetailsResponse {
  __typename?: 'UpdateUserDetailsResponse';
  message?: Maybe<Scalars['String']['output']>;
}

export interface UpdateUserPermissionsInput {
  /** The info of the user in AdCentre */
  userInfo: UserInfoInput;
}

export interface UpdateUserPermissionsResponse {
  __typename?: 'UpdateUserPermissionsResponse';
  permissions: UserPermissions;
  userId: Scalars['String']['output'];
}

export interface UpgradePath {
  __typename?: 'UpgradePath';
  /**
   * Target ad product selected when upgrade product is selected
   * @deprecated Use targetProduct2
   */
  targetProduct: AdProduct;
  /** Target product selected when upgrade product is selected */
  targetProduct2: CatalogueProduct;
  /** Type of upgrade path */
  type: UpgradePathType;
  /** Upgrade product available for AdProduct to which upgrade path is associated */
  upgradeProduct: AdProductUpgrade;
}

export enum UpgradePathType {
  UpgradeOnly = 'UpgradeOnly',
}

export interface UpsertReviewReplyInput {
  companyId: Scalars['String']['input'];
  from: Scalars['String']['input'];
  reviewId: Scalars['String']['input'];
  text: Scalars['String']['input'];
}

export interface UpsertReviewReplyResponse {
  __typename?: 'UpsertReviewReplyResponse';
  success: Scalars['Boolean']['output'];
}

export interface Upvotes {
  __typename?: 'Upvotes';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
}

export interface User {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  permissions: UserPermissions;
  status: Scalars['String']['output'];
}

export interface UserDetails {
  __typename?: 'UserDetails';
  /** Email address of the user */
  email: Scalars['String']['output'];
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** User ID of the user */
  id: Scalars['String']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
  /** Permissions of the user on the advertiser account */
  permissions?: Maybe<UserPermissions>;
  /** Status of the user */
  status: UserStatus;
}

export interface UserDetailsInput {
  /**
   * Advertiser ID which the user is currently signed in as
   * @deprecated use value from token
   */
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  /** First name of the user */
  firstName: Scalars['String']['input'];
  /** Last name of the user */
  lastName: Scalars['String']['input'];
  /** AdCentre user ID of the user that is currently signed in */
  userId: Scalars['ID']['input'];
}

export interface UserHeaderContext {
  __typename?: 'UserHeaderContext';
  /** First name of user. Used for header dropdown text when advertiser context unavailable. */
  firstName: Scalars['String']['output'];
  /** Adcentre User ID */
  id: Scalars['ID']['output'];
  /** Full name of user. */
  name: Scalars['String']['output'];
}

export interface UserInfoInput {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions: UserPermissionsInput;
}

export enum UserIntentType {
  PREVIEW = 'PREVIEW',
  PURCHASE = 'PURCHASE',
}

export interface UserJobAccessInput {
  /** User access to job */
  jobAccess: Scalars['Boolean']['input'];
  /** Id of the user */
  userId: Scalars['ID']['input'];
}

export interface UserListResponse {
  __typename?: 'UserListResponse';
  users: User[];
}

export interface UserOption {
  __typename?: 'UserOption';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface UserPermissions {
  __typename?: 'UserPermissions';
  canCreatePosition: Scalars['Boolean']['output'];
  hasAllPositionAccess: Scalars['Boolean']['output'];
  hasAnalytics: Scalars['Boolean']['output'];
  hasPremiumTalentSearch: Scalars['Boolean']['output'];
  role: Scalars['String']['output'];
}

export interface UserPermissionsInput {
  canCreatePosition: Scalars['Boolean']['input'];
  hasAllPositionAccess: Scalars['Boolean']['input'];
  hasAnalytics: Scalars['Boolean']['input'];
  hasPremiumTalentSearch: Scalars['Boolean']['input'];
  role: Role;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  FRAUDULENT = 'FRAUDULENT',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  UNVERIFIED = 'UNVERIFIED',
}

export interface UserWithJobPermission {
  __typename?: 'UserWithJobPermission';
  /** The users email address. */
  email: Scalars['String']['output'];
  /** The users first name. */
  firstName: Scalars['String']['output'];
  /** A flag denoting whether the user has access to all jobs created within the advertiser account. */
  hasAccessToAllJobs: Scalars['Boolean']['output'];
  /** A flag denoting whether the user has access to querying job. */
  hasAccessToCurrentJob: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is an Administrator. */
  isAdmin: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is job creator. */
  isJobCreator: Scalars['Boolean']['output'];
  /** A flag denoting whether the user is still required to activate their user account. */
  isPending: Scalars['Boolean']['output'];
  /** The users last name. */
  lastName: Scalars['String']['output'];
  /** Id of the user on the advertiser account. */
  userId: Scalars['ID']['output'];
}

export enum UsersParamsFilter {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  UNVERIFIED = 'UNVERIFIED',
}

export enum UsersParamsInclude {
  ACCESS_DETAILS = 'ACCESS_DETAILS',
}

export interface UsersParamsInput {
  /** Filter array to narrow down the returned list of users based on access right. Can be include [ACTIVE | PENDING | DEACTIVATED | ADMIN_ROLE | USER_ROLE] */
  accessRightFilter?: InputMaybe<Array<InputMaybe<AccessRightFilter>>>;
  /** Add on properties to returned users. Can be "ACCESS_DETAILS" */
  include?: InputMaybe<UsersParamsInclude>;
  /** Filter array to narrow down the returned list of users based on user status. Can be include [ACTIVE | PENDING | DEACTIVATED] */
  usersFilter?: InputMaybe<Array<InputMaybe<UsersParamsFilter>>>;
}

export interface UsersWithJobPermissionInput {
  /** The advertiser id to fetch users tied to the account. */
  advertiserId: Scalars['ID']['input'];
  /** The job id to check users access permission */
  jobId: Scalars['ID']['input'];
}

export interface ValidatePromoCodeInput {
  /** The locale of the user. The locale is used to determine the currency and language of the user */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** The product id to verify the campaign is applicable to. ProductId must be a valid product id from a product set */
  productId: Scalars['String']['input'];
  /** The promo code to be verified */
  promoCode: Scalars['String']['input'];
}

export interface ValidatePromoCodeResponse {
  __typename?: 'ValidatePromoCodeResponse';
  /** Promo code is valid or is not valid */
  isValid: Scalars['Boolean']['output'];
  /** The message with detail as to why the promo code is not valid e.g. The promo code has expired */
  message: Scalars['String']['output'];
}

export interface Verification {
  isVerified: Scalars['Boolean']['output'];
  verifiedFields?: Maybe<VerifiedField[]>;
}

export interface VerifiedField {
  __typename?: 'VerifiedField';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface ViewsInJobSearchResponse {
  __typename?: 'ViewsInJobSearchResponse';
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  clickConversionInPercentage?: Maybe<Scalars['Float']['output']>;
  hasAdRating: Scalars['Boolean']['output'];
  lastUpdatedTimestampUTC: Scalars['String']['output'];
  mobileViewsInPercentage: Scalars['Float']['output'];
  similarAdsAverage?: Maybe<Scalars['Int']['output']>;
  similarAdsInPercentage?: Maybe<Scalars['Float']['output']>;
  views: Scalars['Int']['output'];
}

export interface ViewsInJobSearchWidgetInput {
  hirerOriginZone: Scalars['String']['input'];
  jobId: Scalars['String']['input'];
}

export interface VirtualAccount {
  __typename?: 'VirtualAccount';
  accountNo?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
}

export interface WindowLocation {
  /** The current URLs path */
  pathname: Scalars['String']['input'];
  /** The current URLs query (includes leading “?” if non-empty) */
  search: Scalars['String']['input'];
}

export interface WorkHistory {
  __typename?: 'WorkHistory';
  companyName: Scalars['String']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  foundInCV: Scalars['Boolean']['output'];
  highlight?: Maybe<WorkHistoryHighlight>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
}

export interface WorkHistoryHighlight {
  __typename?: 'WorkHistoryHighlight';
  companyName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
}

export enum WorkType {
  CASUAL = 'CASUAL',
  CONTRACT_TEMP = 'CONTRACT_TEMP',
  FULLTIME = 'FULLTIME',
  PARTTIME = 'PARTTIME',
}

export interface YourCandidate {
  __typename?: 'YourCandidate';
  adcentreCandidateId: Scalars['Int']['output'];
  applicationTime: Scalars['String']['output'];
  candidateId: Scalars['Int']['output'];
  channel: YourCandidateChannel;
  emailAddress: Scalars['String']['output'];
  hasCoverLetter: Scalars['Boolean']['output'];
  hasResume: Scalars['Boolean']['output'];
  hasSelectionCriteria: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  lastRoleCompanyName?: Maybe<Scalars['String']['output']>;
  lastRoleJobTitle?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  prospectId: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  status: YourCandidateStatus;
  statusText: Scalars['String']['output'];
  totalApplications: Scalars['Int']['output'];
}

export enum YourCandidateChannel {
  NATIVE = 'NATIVE',
  UPLOADED = 'UPLOADED',
}

export interface YourCandidateEdge {
  __typename?: 'YourCandidateEdge';
  node: YourCandidate;
}

export enum YourCandidateStatus {
  ACCEPT = 'ACCEPT',
  INTERVIEW = 'INTERVIEW',
  NEW = 'NEW',
  OFFER = 'OFFER',
  PRESCREEN = 'PRESCREEN',
  REJECTED = 'REJECTED',
  SHORTLIST = 'SHORTLIST',
  VIEWED = 'VIEWED',
}

export interface YourCandidatesInput {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}

export interface YourCandidatesPayloadError {
  __typename?: 'YourCandidatesPayloadError';
  error: YourCandidatesPayloadErrorType;
}

export enum YourCandidatesPayloadErrorType {
  FORBIDDEN_ERROR = 'FORBIDDEN_ERROR',
  INPUT_VALIDATION_ERROR = 'INPUT_VALIDATION_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type YourCandidatesResponse =
  | YourCandidatesPayloadError
  | YourCandidatesResults;

export interface YourCandidatesResults {
  __typename?: 'YourCandidatesResults';
  edges: YourCandidateEdge[];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
}

export type CompleteAdditionalAdvertiserRegistrationMutationVariables = Exact<{
  input: SecureLinkInput;
}>;

export interface CompleteAdditionalAdvertiserRegistrationMutation {
  __typename?: 'Mutation';
  completeAdditionalAdvertiserRegistration:
    | { __typename: 'SecureLinkExpiredError'; message: string }
    | { __typename: 'SecureLinkInvalidError'; message: string }
    | { __typename: 'SecureLinkSuccessResponse'; redirectUri: string }
    | { __typename: 'SecureLinkUsedError'; message: string };
}

export type CompleteUserRegistrationMutationVariables = Exact<{
  input: SecureLinkInput;
}>;

export interface CompleteUserRegistrationMutation {
  __typename?: 'Mutation';
  completeUserRegistration:
    | { __typename: 'SecureLinkExpiredError'; message: string }
    | { __typename: 'SecureLinkInvalidError'; message: string }
    | { __typename: 'SecureLinkSuccessResponse'; redirectUri: string }
    | { __typename: 'SecureLinkUsedError'; message: string };
}

export type RegisterAdditionalAdvertiserMutationVariables = Exact<{
  input: RegisterAdditionalAdvertiserInput;
}>;

export interface RegisterAdditionalAdvertiserMutation {
  __typename?: 'Mutation';
  registerAdditionalAdvertiser?: {
    __typename?: 'RegisterPayload';
    seekId: string;
  } | null;
}

export type RegisterNewUserMutationVariables = Exact<{
  input: RegisterNewUserInput;
}>;

export interface RegisterNewUserMutation {
  __typename?: 'Mutation';
  registerNewUser?: {
    __typename?: 'RegisterPayload';
    seekId: string;
    redirectUrl?: string | null;
  } | null;
}

export type ResendActivationEmailMutationVariables = Exact<{
  input: ResendAccountActivationEmailInput;
}>;

export interface ResendActivationEmailMutation {
  __typename?: 'Mutation';
  resendAccountActivationEmail:
    | {
        __typename: 'ResendAccountActivationEmailResponseSuccess';
        message?: string | null;
        resent: boolean;
        hirerAccountId: string;
      }
    | { __typename: 'ResponseError'; error: ResponseErrorReason };
}

export type CheckRegistrationStatusQueryVariables = Exact<
  Record<string, never>
>;

export interface CheckRegistrationStatusQuery {
  __typename?: 'Query';
  registrationStatus: {
    __typename?: 'RegistrationStatusInfo';
    userExists: boolean;
  };
}

export interface accountPartsFragment {
  __typename?: 'AdvertiserAccountOption';
  name: string;
  seekId: string;
  billingId?: string | null;
  accessRightStatus: AccessRightStatus;
  hirerAccountStatus: AdvertiserStatus;
  role: Role;
}

export type GetAccountSelectionQueryVariables = Exact<Record<string, never>>;

export interface GetAccountSelectionQuery {
  __typename?: 'Query';
  accountSelectionV2:
    | {
        __typename: 'AccountSelectionResults';
        totalCount: number;
        defaultAccount?: {
          __typename?: 'AdvertiserAccountOption';
          name: string;
          seekId: string;
          billingId?: string | null;
          accessRightStatus: AccessRightStatus;
          hirerAccountStatus: AdvertiserStatus;
          role: Role;
        } | null;
        edges: Array<{
          __typename?: 'AccountSelectionEdge';
          node: {
            __typename?: 'AdvertiserAccountOption';
            name: string;
            seekId: string;
            billingId?: string | null;
            accessRightStatus: AccessRightStatus;
            hirerAccountStatus: AdvertiserStatus;
            role: Role;
            childAccounts: Array<{
              __typename?: 'AdvertiserAccountOption';
              name: string;
              seekId: string;
              billingId?: string | null;
              accessRightStatus: AccessRightStatus;
              hirerAccountStatus: AdvertiserStatus;
              role: Role;
            }>;
          };
        }>;
      }
    | { __typename: 'ResponseError'; error: ResponseErrorReason };
}

export type GetCurrentUserQueryVariables = Exact<Record<string, never>>;

export interface GetCurrentUserQuery {
  __typename?: 'Query';
  currentUser:
    | { __typename: 'CurrentUserContext'; id: string }
    | { __typename: 'MissingUserIdClaimError'; message: string };
  currentAdvertiser:
    | {
        __typename: 'CurrentAdvertiserContext';
        seekId: string;
        advertiserId: string;
      }
    | { __typename: 'MissingAdvertiserIdClaimError'; message: string };
}

export type GetCountriesQueryVariables = Exact<Record<string, never>>;

export interface GetCountriesQuery {
  __typename?: 'Query';
  countrySelectionOptions: {
    __typename?: 'CountrySelectionOptions';
    options: Array<{
      __typename?: 'Country';
      name: string;
      countryCode: string;
    }>;
  };
}

export type GetHeaderFooterContextQueryVariables = Exact<{
  input: HeaderFooterContextInput;
}>;

export interface GetHeaderFooterContextQuery {
  __typename?: 'Query';
  headerFooterContext?: {
    __typename?: 'HeaderFooterContext';
    identityContext?: {
      __typename?: 'IdentityHeaderContext';
      email: string;
      act?: {
        __typename?: 'ActorContext';
        operatorEmail: string;
        isCsView: boolean;
      } | null;
    } | null;
    advertiser?: {
      __typename?: 'AdvertiserHeaderContext';
      billingId: string;
      hasMultipleAccounts: boolean;
      isActivationPending: boolean;
      isAgency: boolean;
      name: string;
      showOnCreditHoldMessage: boolean;
      switchAccountsHref?: string | null;
      permissionOptions: {
        __typename?: 'PermissionOptions';
        canCreateJobs: boolean;
        canManageBrand: boolean;
        canManageUsers: boolean;
        canPayInvoices: boolean;
        canViewInvoiceHistory: boolean;
        canViewManagerReports: boolean;
        canManageCompanyProfile: boolean;
      };
    } | null;
    user?: {
      __typename?: 'UserHeaderContext';
      name: string;
      firstName: string;
      id: string;
    } | null;
  } | null;
}

export type GetIdentityQueryVariables = Exact<Record<string, never>>;

export interface GetIdentityQuery {
  __typename?: 'Query';
  identity: {
    __typename?: 'Identity';
    subject: { __typename?: 'Subject'; email: string };
  };
}

export type GetRedirectUrlQueryVariables = Exact<{
  input: RedirectUrlQueryInput;
}>;

export interface GetRedirectUrlQuery {
  __typename?: 'Query';
  redirectUrl: string;
}

export type GetRegistrationOptionsQueryVariables = Exact<Record<string, never>>;

export interface GetRegistrationOptionsQuery {
  __typename?: 'Query';
  registrationOptions: {
    __typename?: 'RegistrationOptions';
    allowAgencyRegistration: boolean;
  };
}

export const accountPartsFragmentDoc = gql`
  fragment accountParts on AdvertiserAccountOption {
    name
    seekId
    billingId
    accessRightStatus
    hirerAccountStatus
    role
  }
`;
export const CompleteAdditionalAdvertiserRegistrationDocument = gql`
  mutation CompleteAdditionalAdvertiserRegistration($input: SecureLinkInput!) {
    completeAdditionalAdvertiserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
export type CompleteAdditionalAdvertiserRegistrationMutationFn =
  Apollo.MutationFunction<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >;

/**
 * __useCompleteAdditionalAdvertiserRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteAdditionalAdvertiserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAdditionalAdvertiserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAdditionalAdvertiserRegistrationMutation, { data, loading, error }] = useCompleteAdditionalAdvertiserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAdditionalAdvertiserRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >(CompleteAdditionalAdvertiserRegistrationDocument, options);
}
export type CompleteAdditionalAdvertiserRegistrationMutationHookResult =
  ReturnType<typeof useCompleteAdditionalAdvertiserRegistrationMutation>;
export type CompleteAdditionalAdvertiserRegistrationMutationResult =
  Apollo.MutationResult<CompleteAdditionalAdvertiserRegistrationMutation>;
export type CompleteAdditionalAdvertiserRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteAdditionalAdvertiserRegistrationMutation,
    CompleteAdditionalAdvertiserRegistrationMutationVariables
  >;
export const CompleteUserRegistrationDocument = gql`
  mutation CompleteUserRegistration($input: SecureLinkInput!) {
    completeUserRegistration(input: $input) {
      ... on SecureLinkSuccessResponse {
        __typename
        redirectUri
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
export type CompleteUserRegistrationMutationFn = Apollo.MutationFunction<
  CompleteUserRegistrationMutation,
  CompleteUserRegistrationMutationVariables
>;

/**
 * __useCompleteUserRegistrationMutation__
 *
 * To run a mutation, you first call `useCompleteUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUserRegistrationMutation, { data, loading, error }] = useCompleteUserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUserRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >(CompleteUserRegistrationDocument, options);
}
export type CompleteUserRegistrationMutationHookResult = ReturnType<
  typeof useCompleteUserRegistrationMutation
>;
export type CompleteUserRegistrationMutationResult =
  Apollo.MutationResult<CompleteUserRegistrationMutation>;
export type CompleteUserRegistrationMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteUserRegistrationMutation,
    CompleteUserRegistrationMutationVariables
  >;
export const RegisterAdditionalAdvertiserDocument = gql`
  mutation RegisterAdditionalAdvertiser(
    $input: RegisterAdditionalAdvertiserInput!
  ) {
    registerAdditionalAdvertiser(input: $input) {
      seekId
    }
  }
`;
export type RegisterAdditionalAdvertiserMutationFn = Apollo.MutationFunction<
  RegisterAdditionalAdvertiserMutation,
  RegisterAdditionalAdvertiserMutationVariables
>;

/**
 * __useRegisterAdditionalAdvertiserMutation__
 *
 * To run a mutation, you first call `useRegisterAdditionalAdvertiserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAdditionalAdvertiserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAdditionalAdvertiserMutation, { data, loading, error }] = useRegisterAdditionalAdvertiserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAdditionalAdvertiserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >(RegisterAdditionalAdvertiserDocument, options);
}
export type RegisterAdditionalAdvertiserMutationHookResult = ReturnType<
  typeof useRegisterAdditionalAdvertiserMutation
>;
export type RegisterAdditionalAdvertiserMutationResult =
  Apollo.MutationResult<RegisterAdditionalAdvertiserMutation>;
export type RegisterAdditionalAdvertiserMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterAdditionalAdvertiserMutation,
    RegisterAdditionalAdvertiserMutationVariables
  >;
export const RegisterNewUserDocument = gql`
  mutation RegisterNewUser($input: RegisterNewUserInput!) {
    registerNewUser(input: $input) {
      seekId
      redirectUrl
    }
  }
`;
export type RegisterNewUserMutationFn = Apollo.MutationFunction<
  RegisterNewUserMutation,
  RegisterNewUserMutationVariables
>;

/**
 * __useRegisterNewUserMutation__
 *
 * To run a mutation, you first call `useRegisterNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewUserMutation, { data, loading, error }] = useRegisterNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterNewUserMutation,
    RegisterNewUserMutationVariables
  >(RegisterNewUserDocument, options);
}
export type RegisterNewUserMutationHookResult = ReturnType<
  typeof useRegisterNewUserMutation
>;
export type RegisterNewUserMutationResult =
  Apollo.MutationResult<RegisterNewUserMutation>;
export type RegisterNewUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterNewUserMutation,
  RegisterNewUserMutationVariables
>;
export const ResendActivationEmailDocument = gql`
  mutation ResendActivationEmail($input: ResendAccountActivationEmailInput!) {
    resendAccountActivationEmail(input: $input) {
      ... on ResendAccountActivationEmailResponseSuccess {
        __typename
        message
        resent
        hirerAccountId
      }
      ... on ResponseError {
        __typename
        error
      }
    }
  }
`;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >(ResendActivationEmailDocument, options);
}
export type ResendActivationEmailMutationHookResult = ReturnType<
  typeof useResendActivationEmailMutation
>;
export type ResendActivationEmailMutationResult =
  Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export const CheckRegistrationStatusDocument = gql`
  query CheckRegistrationStatus {
    registrationStatus(input: { filter: "includeDeactivated" }) {
      userExists
    }
  }
`;

/**
 * __useCheckRegistrationStatusQuery__
 *
 * To run a query within a React component, call `useCheckRegistrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckRegistrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckRegistrationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckRegistrationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export function useCheckRegistrationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export function useCheckRegistrationStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckRegistrationStatusQuery,
    CheckRegistrationStatusQueryVariables
  >(CheckRegistrationStatusDocument, options);
}
export type CheckRegistrationStatusQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusQuery
>;
export type CheckRegistrationStatusLazyQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusLazyQuery
>;
export type CheckRegistrationStatusSuspenseQueryHookResult = ReturnType<
  typeof useCheckRegistrationStatusSuspenseQuery
>;
export type CheckRegistrationStatusQueryResult = Apollo.QueryResult<
  CheckRegistrationStatusQuery,
  CheckRegistrationStatusQueryVariables
>;
export const GetAccountSelectionDocument = gql`
  query GetAccountSelection {
    accountSelectionV2 {
      ... on AccountSelectionResults {
        __typename
        totalCount
        defaultAccount {
          ...accountParts
        }
        edges {
          node {
            ...accountParts
            childAccounts {
              ...accountParts
            }
          }
        }
      }
      ... on ResponseError {
        __typename
        error
      }
    }
  }
  ${accountPartsFragmentDoc}
`;

/**
 * __useGetAccountSelectionQuery__
 *
 * To run a query within a React component, call `useGetAccountSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountSelectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export function useGetAccountSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export function useGetAccountSelectionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAccountSelectionQuery,
    GetAccountSelectionQueryVariables
  >(GetAccountSelectionDocument, options);
}
export type GetAccountSelectionQueryHookResult = ReturnType<
  typeof useGetAccountSelectionQuery
>;
export type GetAccountSelectionLazyQueryHookResult = ReturnType<
  typeof useGetAccountSelectionLazyQuery
>;
export type GetAccountSelectionSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountSelectionSuspenseQuery
>;
export type GetAccountSelectionQueryResult = Apollo.QueryResult<
  GetAccountSelectionQuery,
  GetAccountSelectionQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      __typename
      ... on CurrentUserContext {
        id
      }
      ... on Error {
        message
      }
    }
    currentAdvertiser {
      ... on CurrentAdvertiserContext {
        __typename
        seekId
        advertiserId
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    countrySelectionOptions {
      options {
        name
        countryCode
      }
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<
  typeof useGetCountriesSuspenseQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetHeaderFooterContextDocument = gql`
  query GetHeaderFooterContext($input: HeaderFooterContextInput!) {
    headerFooterContext {
      identityContext {
        email
        act(input: $input) {
          operatorEmail
          isCsView
        }
      }
      advertiser {
        billingId
        hasMultipleAccounts
        isActivationPending
        isAgency
        name
        permissionOptions {
          canCreateJobs
          canManageBrand
          canManageUsers
          canPayInvoices
          canViewInvoiceHistory
          canViewManagerReports
          canManageCompanyProfile
        }
        showOnCreditHoldMessage
        switchAccountsHref
      }
      user {
        name
        firstName
        id
      }
    }
  }
`;

/**
 * __useGetHeaderFooterContextQuery__
 *
 * To run a query within a React component, call `useGetHeaderFooterContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeaderFooterContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeaderFooterContextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHeaderFooterContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  > &
    (
      | { variables: GetHeaderFooterContextQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export function useGetHeaderFooterContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export function useGetHeaderFooterContextSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHeaderFooterContextQuery,
    GetHeaderFooterContextQueryVariables
  >(GetHeaderFooterContextDocument, options);
}
export type GetHeaderFooterContextQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextQuery
>;
export type GetHeaderFooterContextLazyQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextLazyQuery
>;
export type GetHeaderFooterContextSuspenseQueryHookResult = ReturnType<
  typeof useGetHeaderFooterContextSuspenseQuery
>;
export type GetHeaderFooterContextQueryResult = Apollo.QueryResult<
  GetHeaderFooterContextQuery,
  GetHeaderFooterContextQueryVariables
>;
export const GetIdentityDocument = gql`
  query GetIdentity {
    identity {
      subject {
        email
      }
    }
  }
`;

/**
 * __useGetIdentityQuery__
 *
 * To run a query within a React component, call `useGetIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIdentityQuery,
    GetIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export function useGetIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityQuery,
    GetIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export function useGetIdentitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentityQuery,
    GetIdentityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export type GetIdentityQueryHookResult = ReturnType<typeof useGetIdentityQuery>;
export type GetIdentityLazyQueryHookResult = ReturnType<
  typeof useGetIdentityLazyQuery
>;
export type GetIdentitySuspenseQueryHookResult = ReturnType<
  typeof useGetIdentitySuspenseQuery
>;
export type GetIdentityQueryResult = Apollo.QueryResult<
  GetIdentityQuery,
  GetIdentityQueryVariables
>;
export const GetRedirectUrlDocument = gql`
  query GetRedirectUrl($input: RedirectUrlQueryInput!) {
    redirectUrl(input: $input)
  }
`;

/**
 * __useGetRedirectUrlQuery__
 *
 * To run a query within a React component, call `useGetRedirectUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedirectUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedirectUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRedirectUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRedirectUrlQuery,
    GetRedirectUrlQueryVariables
  > &
    (
      | { variables: GetRedirectUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedirectUrlQuery, GetRedirectUrlQueryVariables>(
    GetRedirectUrlDocument,
    options,
  );
}
export function useGetRedirectUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRedirectUrlQuery,
    GetRedirectUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedirectUrlQuery, GetRedirectUrlQueryVariables>(
    GetRedirectUrlDocument,
    options,
  );
}
export function useGetRedirectUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRedirectUrlQuery,
    GetRedirectUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRedirectUrlQuery,
    GetRedirectUrlQueryVariables
  >(GetRedirectUrlDocument, options);
}
export type GetRedirectUrlQueryHookResult = ReturnType<
  typeof useGetRedirectUrlQuery
>;
export type GetRedirectUrlLazyQueryHookResult = ReturnType<
  typeof useGetRedirectUrlLazyQuery
>;
export type GetRedirectUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetRedirectUrlSuspenseQuery
>;
export type GetRedirectUrlQueryResult = Apollo.QueryResult<
  GetRedirectUrlQuery,
  GetRedirectUrlQueryVariables
>;
export const GetRegistrationOptionsDocument = gql`
  query GetRegistrationOptions {
    registrationOptions {
      allowAgencyRegistration
    }
  }
`;

/**
 * __useGetRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useGetRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistrationOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export function useGetRegistrationOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export function useGetRegistrationOptionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRegistrationOptionsQuery,
    GetRegistrationOptionsQueryVariables
  >(GetRegistrationOptionsDocument, options);
}
export type GetRegistrationOptionsQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsQuery
>;
export type GetRegistrationOptionsLazyQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsLazyQuery
>;
export type GetRegistrationOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetRegistrationOptionsSuspenseQuery
>;
export type GetRegistrationOptionsQueryResult = Apollo.QueryResult<
  GetRegistrationOptionsQuery,
  GetRegistrationOptionsQueryVariables
>;

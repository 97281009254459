import { URL_ACCOUNT_SELECT } from '@seek/adv-constants';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useTranslations } from '@vocab/react';
import { Alert, Stack, Strong, Text, TextLink } from 'braid-design-system';

import { REGISTER_ACCOUNT_SWITCH_ACCOUNT } from 'src/constants/pageViewUniqueKeys';
import { useConfig } from 'src/hooks/context';
import { useAuthenticatedViewTracking } from 'src/modules/AnalyticsProvider/useAuthenticatedViewTracking';
import translations from 'src/views/pages/RegistrationPage/.vocab';

import { useRegistrationPageContext } from './RegistrationPageContext';

const RegistrationAlert = () => {
  const { absoluteEmployerUrlResolver } = useConfig();
  const context = useRegistrationPageContext();
  const { t } = useTranslations(translations);

  const [userInfo] = useAuthenticatedViewTracking({
    triggerViewTracking: false,
  });

  const trackSwitchAccount = useTrackLink(REGISTER_ACCOUNT_SWITCH_ACCOUNT, {
    ...userInfo,
    actionOrigin: 'account registration form',
  });

  if (context.loading) {
    return null;
  }

  const { userExists, numberOfAccounts } = context;

  if (!userExists) {
    return null;
  }

  const hasAnotherAccount = numberOfAccounts > 0;

  return (
    <Alert tone="caution">
      {hasAnotherAccount ? (
        <Stack space="medium">
          <Text>
            {t('You have other accounts', {
              count: numberOfAccounts,
              Strong: (children) => <Strong>{children}</Strong>,
            })}
          </Text>
          <Text>
            <TextLink
              onClick={() => (trackSwitchAccount ? trackSwitchAccount() : null)}
              href={absoluteEmployerUrlResolver(URL_ACCOUNT_SELECT)}
            >
              {t('View accounts')}
            </TextLink>
          </Text>
        </Stack>
      ) : (
        <Text>{t('No linked employer account message')}</Text>
      )}
    </Alert>
  );
};

export default RegistrationAlert;

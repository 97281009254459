import { URL_ACCOUNT_HIRER_REGISTRATION } from '@seek/adv-constants';
import { useTranslations } from '@vocab/react';
import { PageBlock } from 'braid-design-system';

import {
  META_DESCRIPTION_REGISTER,
  getMetaDataBySite,
} from 'src/constants/pageMetaData';
import { useConfig } from 'src/hooks/context';
import { PageLayout } from 'src/views/layouts/PageLayout/PageLayout';
import RegistrationForm from 'src/views/shared/forms/RegistrationForm/RegistrationForm';

import translations from './.vocab';
import RegistrationAlert from './RegistrationAlert';
import RegistrationHeader from './RegistrationHeader';
import { RegistrationPageProvider } from './RegistrationPageContext';

const RegistrationPage = () => {
  const { site, absoluteEmployerUrlResolver } = useConfig();
  const { t } = useTranslations(translations);

  const fullUrl = absoluteEmployerUrlResolver(URL_ACCOUNT_HIRER_REGISTRATION);
  const pageWidth = 'small';

  return (
    <RegistrationPageProvider>
      <PageLayout
        bannerComponent={<RegistrationAlert />}
        seoMeta={{
          title: t('Browser tab title'),
          description: getMetaDataBySite(site, META_DESCRIPTION_REGISTER),
          url: fullUrl,
        }}
        heading={<RegistrationHeader />}
        width={pageWidth}
      >
        <PageBlock width={pageWidth}>
          <RegistrationForm />
        </PageBlock>
      </PageLayout>
    </RegistrationPageProvider>
  );
};

export default RegistrationPage;

import { PageBlock, Box, Stack, Heading, Card } from 'braid-design-system';

import type { BasePageLayoutProps } from 'src/types';
import SeoMeta from 'src/views/shared/SeoMeta/SeoMeta';

interface Props extends BasePageLayoutProps {
  heading: string;
  image?: JSX.Element;
}

export const EmptyPageLayout = ({
  children,
  image,
  heading,
  seoMeta,
}: Props) => (
  <Stack space="xlarge">
    <SeoMeta
      title={seoMeta.title}
      description={seoMeta.description ?? ''}
      url={seoMeta.url}
      additionalMeta={seoMeta.addtionalMeta}
    />

    <PageBlock width="medium">
      <Card>
        <Stack align="center" space={{ mobile: 'medium', desktop: 'large' }}>
          {image ?? null}
          <Stack space="medium" align="center">
            <Heading level="2" align="center">
              {heading}
            </Heading>
            <Box textAlign="center">{children}</Box>
          </Stack>
        </Stack>
      </Card>
    </PageBlock>
  </Stack>
);

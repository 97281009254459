
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t+C5iOC4reC4nOC4ueC5ieC5g+C4iuC5ieC4leC5ieC4reC4h+C4oeC4teC4iOC4s+C4meC4p+C4meC4leC4seC4p+C4reC4seC4geC4qeC4o+C4meC5ieC4reC4ouC4geC4p+C5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t+C5iOC4reC4nOC4ueC5ieC5g+C4iuC5ieC4leC5ieC4reC4h+C4oeC4teC4iOC4s+C4meC4p+C4meC4leC4seC4p+C4reC4seC4geC4qeC4o+C4meC5ieC4reC4ouC4geC4p+C5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t+C5iOC4reC4nOC4ueC5ieC5g+C4iuC5ieC4leC5ieC4reC4h+C4oeC4teC4iOC4s+C4meC4p+C4meC4leC4seC4p+C4reC4seC4geC4qeC4o+C4meC5ieC4reC4ouC4geC4p+C5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoi4Lit4Li14LmA4Lih4Lil4LmE4Lih4LmI4LiW4Li54LiB4LiV4LmJ4Lit4LiHIOC4iuC4t+C5iOC4reC4nOC4ueC5ieC5g+C4iuC5ieC4leC5ieC4reC4h+C4oeC4teC4iOC4s+C4meC4p+C4meC4leC4seC4p+C4reC4seC4geC4qeC4o+C4meC5ieC4reC4ouC4geC4p+C5iOC4siA2NSDguJXguLHguKciLCJuby1zdGFydGluZy1zcGFjZS1lcnJvciI6IntmaWVsZExhYmVsfSDguYTguKHguYjguKrguLLguKHguLLguKPguJbguYDguKPguLTguYjguKHguJXguYnguJnguJTguYnguKfguKLguIrguYjguK3guIfguKfguYjguLLguIcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiQWxhbWF0IGVtYWlsIHRpZGFrIHZhbGlkLiBKYW5nYW4gbWVsZWJpaGkgNjUga2FyYWt0ZXIuIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJ7ZmllbGRMYWJlbH0gdGlkYWsgZGFwYXQgZGltdWxhaSBkZW5nYW4gc3Bhc2kuIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiSW52YWxpZCBlbWFpbCBhZGRyZXNzLiBUaGUgdXNlciBwb3J0aW9uIG11c3QgYmUgZmV3ZXIgdGhhbiA2NSBjaGFyYWN0ZXJzLiIsIm5vLXN0YXJ0aW5nLXNwYWNlLWVycm9yIjoie2ZpZWxkTGFiZWx9IGNhbm5vdCBzdGFydCB3aXRoIGEgc3BhY2UifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLiDhua7huKnhur3hur3hur0gx5rHmseaxaHhur3hur3hur3FmSDGpcO2w7bDtsWZ4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIG3Mgseax5rHmsWh4bmvIMOf4bq94bq94bq9IMaS4bq94bq94bq9xbXhur3hur3hur3FmSDhua/huKnEg8SDxIPguIHguLXguYkgNjUgw6fhuKnEg8SDxIPFmcSDxIPEg8On4bmv4bq94bq94bq9xZnFoS5dIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJbe2ZpZWxkTGFiZWx9IMOnxIPEg8SD4LiB4Li14LmJ4LiB4Li14LmJw7bDtsO24bmvIMWh4bmvxIPEg8SDxZnhua8gxbXDrMOsw6zhua/huKkgxIPEg8SDIMWhxqXEg8SDxIPDp+G6veG6veG6vV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJpbnZhbGlkLXNzby1lbWFpbC1hZGRyZXNzLWVycm9yIjoiW8OP4LiB4Li14LmJ4bm9xIPEg8SDxprDrMOsw6zGjCDhur3hur3hur1tzILEg8SDxIPDrMOsw6zGmiDEg8SDxIPGjMaMxZnhur3hur3hur3FocWhLiDhua7huKnhur3hur3hur0gx5rHmseaxaHhur3hur3hur3FmSDGpcO2w7bDtsWZ4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIG3Mgseax5rHmsWh4bmvIMOf4bq94bq94bq9IMaS4bq94bq94bq9xbXhur3hur3hur3FmSDhua/huKnEg8SDxIPguIHguLXguYkgNjUgw6fhuKnEg8SDxIPFmcSDxIPEg8On4bmv4bq94bq94bq9xZnFoS5dIiwibm8tc3RhcnRpbmctc3BhY2UtZXJyb3IiOiJbe2ZpZWxkTGFiZWx9IMOnxIPEg8SD4LiB4Li14LmJ4LiB4Li14LmJw7bDtsO24bmvIMWh4bmvxIPEg8SDxZnhua8gxbXDrMOsw6zhua/huKkgxIPEg8SDIMWhxqXEg8SDxIPDp+G6veG6veG6vV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    
import { required, rules } from '@seek/validators-js';
import { useTranslations } from '@vocab/react';

import translations from 'src/views/shared/forms/RegistrationForm/.vocab';
import { useField } from 'src/views/shared/forms/RegistrationForm/RegistrationFormContext';
import { noStartingSpace } from 'src/views/shared/forms/utils/customValidationHelpers';

export default () => {
  const { t } = useTranslations(translations);

  const businessNameValidationRules = rules.hirerBusinessName({
    requiredLabel: t('Business name required'),
  });

  const phoneValidationRules = rules.hirerPhoneNumber();

  const businessName = useField({
    id: 'businessName',
    validators: [
      required,
      noStartingSpace,
      ...businessNameValidationRules.validators,
    ],
    validatorProps: {
      ...businessNameValidationRules.validatorProps,
      fieldLabel: t('Business name'),
    },
  });

  const phoneNumber = useField({
    id: 'phoneNumber',
    validators: [required, noStartingSpace] as any,
    validatorProps: {
      ...phoneValidationRules.validatorProps,
      fieldLabel: t('Phone number'),
    },
  });

  const isAgency = useField({
    id: 'isAgency',
    validators: [],
    validatorProps: {},
  });

  return {
    businessName,
    phoneNumber,
    isAgency,
  };
};

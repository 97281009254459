export const analyticsEvents = {
  TRACK_REGISTRATION: 'registration-link-clicked',
  TRACK_CTA: 'call-to-action-clicked-btn',
  TRACK_SIGN_IN: 'sign-in-clicked-lnk',
  TRACK_CANDIDATE_SITE: 'candidate-site-link-clicked',
  TRACK_PRIVACY: 'privacy-policy-clicked',
  TRACK_TERMS: 'terms-conditions-clicked',
  TRACK_FAST_RESULT: 'fast-results-clicked-lnk',
  TRACK_QUALITY_CANDIDATES: 'quality-candidates-clicked-lnk',
  TRACK_EASY_PROCESS: 'easy-process-clicked-lnk',
  TRACK_POWERFUL_TOOLS: 'powerful-tools-clicked-lnk',
  TRACK_HIRING_ADVICE: 'advice-clicked-lnk',
  TRACK_CREATE_NEW_ACCOUNT: 'create_new_account_pressed',
};

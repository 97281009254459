
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiLguYTguKHguYjguKrguLLguKHguLLguKPguJbguJTguLbguIfguILguYnguK3guKHguLnguKXguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYTguJTguYkg4LiB4Lij4Li44LiT4Liy4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC4q+C4o+C4t+C4reC4hOC4uOC4k+C4quC4suC4oeC4suC4ljxMaW5rPuC4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC5g+C4q+C4oeC5iDwvTGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiLguYTguKHguYjguKrguLLguKHguLLguKPguJbguJTguLbguIfguILguYnguK3guKHguLnguKXguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYTguJTguYkg4LiB4Lij4Li44LiT4Liy4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC4q+C4o+C4t+C4reC4hOC4uOC4k+C4quC4suC4oeC4suC4ljxMaW5rPuC4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC5g+C4q+C4oeC5iDwvTGluaz4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJUaWRhayBkYXBhdCBtZW5nYWtzZXMgYWt1biBBbmRhLiBDb2JhIGxhZ2kgbmFudGksIGF0YXUgPExpbms+YnVhdCBha3VuIGJhcnU8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJUaWRhayBkYXBhdCBtZW5nYWtzZXMgYWt1biBBbmRhLiBDb2JhIGxhZ2kgbmFudGksIGF0YXUgPExpbms+YnVhdCBha3VuIGJhcnU8L0xpbms+LiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiLguYTguKHguYjguKrguLLguKHguLLguKPguJbguJTguLbguIfguILguYnguK3guKHguLnguKXguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYTguJTguYkg4LiB4Lij4Li44LiT4Liy4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC4q+C4o+C4t+C4reC4hOC4uOC4k+C4quC4suC4oeC4suC4ljxMaW5rPuC4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC5g+C4q+C4oeC5iDwvTGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiLguYTguKHguYjguKrguLLguKHguLLguKPguJbguJTguLbguIfguILguYnguK3guKHguLnguKXguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguILguK3guIfguITguLjguJPguYTguJTguYkg4LiB4Lij4Li44LiT4Liy4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC4q+C4o+C4t+C4reC4hOC4uOC4k+C4quC4suC4oeC4suC4ljxMaW5rPuC4quC4o+C5ieC4suC4h+C4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC5g+C4q+C4oeC5iDwvTGluaz4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJUaWRhayBkYXBhdCBtZW5nYWtzZXMgYWt1biBBbmRhLiBDb2JhIGxhZ2kgbmFudGksIGF0YXUgPExpbms+YnVhdCBha3VuIGJhcnU8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJUaWRhayBkYXBhdCBtZW5nYWtzZXMgYWt1biBBbmRhLiBDb2JhIGxhZ2kgbmFudGksIGF0YXUgPExpbms+YnVhdCBha3VuIGJhcnU8L0xpbms+LiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJDb3VsZCBub3QgcmV0cmlldmUgeW91ciBhY2NvdW50cy4gUGxlYXNlIHRyeSBhZ2FpbiBsYXRlciwgb3RoZXJ3aXNlIHlvdSBjYW4gPExpbms+Y3JlYXRlIGEgbmV3IGFjY291bnQ8L0xpbms+LiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJbw4fDtsO2w7bHmseax5rGmsaMIOC4geC4teC5icO2w7bDtuG5ryDFmeG6veG6veG6veG5r8WZw6zDrMOs4bq94bq94bq94bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua/FoS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IOG5r8WZw73DvcO9IMSDxIPEg8SjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMaaxIPEg8SD4bmv4bq94bq94bq9xZksIMO2w7bDtuG5r+G4qeG6veG6veG6vcWZxbXDrMOsw6zFoeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkgPExpbms+w6fFmeG6veG6vcSDxIPhua/hur3hur0gxIPEgyDguIHguLXguYnhur3hur3FtSDEg8SDw6fDp8O2w7bHmsea4LiB4Li14LmJ4bmvPC9MaW5rPi5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDb3VsZCBub3QgcmV0cmlldmUgYWNjb3VudHMiOiJbw4fDtsO2w7bHmseax5rGmsaMIOC4geC4teC5icO2w7bDtuG5ryDFmeG6veG6veG6veG5r8WZw6zDrMOs4bq94bq94bq94bm94bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMSDxIPEg8Onw6fDtsO2w7bHmseax5rguIHguLXguYnhua/FoS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IOG5r8WZw73DvcO9IMSDxIPEg8SjxIPEg8SDw6zDrMOs4LiB4Li14LmJIMaaxIPEg8SD4bmv4bq94bq94bq9xZksIMO2w7bDtuG5r+G4qeG6veG6veG6vcWZxbXDrMOsw6zFoeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5ogw6fEg8SDxIPguIHguLXguYkgPExpbms+w6fFmeG6veG6vcSDxIPhua/hur3hur0gxIPEgyDguIHguLXguYnhur3hur3FtSDEg8SDw6fDp8O2w7bHmsea4LiB4Li14LmJ4bmvPC9MaW5rPi5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    